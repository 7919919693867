<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Informationen</h1>

    <div class="flex flex-col">
      <mat-form-field>
        <mat-label>Name der Schule/Schuleinheit</mat-label>
        <input type="text" matInput formControlName="name" />
        <mat-error>Bitte trage einen gültigen Namen ein.</mat-error>
      </mat-form-field>

      @if (school.isRoot) {
        <mat-form-field appearance="fill">
          <mat-label>Schulart</mat-label>
          <mat-select formControlName="type">
            @for (type of schoolTypes; track $index) {
              <mat-option [value]="type">
                {{ type }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }

      <mat-form-field appearance="fill">
        <mat-label>Schulstufen</mat-label>
        <mat-select formControlName="levels" multiple>
          @for (level of schoolLevels; track $index) {
            <mat-option [value]="level">
              {{ level }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      @if (showJobLevelDescriptionsField) {
        <mat-form-field>
          <mat-label>Stufenspezifikation</mat-label>
          <mat-select formControlName="levelDescriptions" multiple>
            @for (description of schoolLevelDescriptions; track $index) {
              <mat-option [value]="description">
                {{ description }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/schools', id]">
        Abbrechen
      </a>
      <app-action-button
        [disabled]="!form.valid"
        text="Speichern"
        (onClick)="save()"></app-action-button>
    </div>
  </div>
</form>
