import { Component } from '@angular/core';
import { PublicToolbarService } from '../../../services/public-toolbar.service';

@Component({
  selector: 'app-signup-secure-password',
  templateUrl: './signup-secure-password.component.html',
  styleUrls: ['./signup-secure-password.component.scss'],
})
export class SignupSecurePasswordComponent {
  constructor(toolbarService: PublicToolbarService) {
    toolbarService.set({ showLogin: true });
  }
}
