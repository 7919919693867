@if (school$ | async; as school) {
  <div class="grid grid-cols-12">
    <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
      <h1 class="font-bold text-4xl">Schule</h1>
      <div class="flex flex-col">
        @if (school?.isRoot) {
          <mat-form-field appearance="fill">
            <mat-label>Schulart</mat-label>
            <mat-select [formControl]="schoolTypeControl">
              @for (type of schoolTypes; track $index) {
                <mat-option [value]="type">
                  {{ type }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
        @if (isCountLocationsEnabled) {
          <mat-form-field>
            <mat-label>Anzahl Standorte</mat-label>
            <input
              type="number"
              matInput
              [value]="countLocations"
              (input)="setCountLocations($event)" />
            <mat-error>Name des Schulhauses ist erforderlich</mat-error>
          </mat-form-field>
        }
        @if (countLocations && countLocations > 0) {
          <div class="flex flex-col">
            <mat-form-field>
              @if (countLocations === 1) {
                <mat-label>Name der Schule</mat-label>
              }
              @if (countLocations > 1) {
                <mat-label> Name der Schuleinheit </mat-label>
              }
              <input type="string" [formControl]="schoolName" matInput />
              @if (countLocations === 1) {
                <mat-error> Name der Schule ist erforderlich </mat-error>
              }
              @if (countLocations > 1) {
                <mat-error> Name der Schuleinheitist erforderlich </mat-error>
              }
            </mat-form-field>
            @if (countLocations > 1) {
              <div class="flex flex-col">
                @for (field of locationFields; track field; let i = $index) {
                  <mat-form-field>
                    <mat-label>Name Standort {{ i + 1 }}</mat-label>
                    <input type="string" matInput [formControl]="field" />
                    <mat-error>Name des Schulhauses ist erforderlich</mat-error>
                  </mat-form-field>
                }
              </div>
            }
          </div>
        }
      </div>
      <div class="flex justify-end gap-8">
        <app-action-button
          [disabled]="isLoading || !isValid()"
          [loading]="isLoading"
          text="Speichern"
          (onClick)="save(school)" />
      </div>
    </div>
  </div>
}
