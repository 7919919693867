<div
  class="flex flex-col border-solid rounded-2xl p-4 md:p-6 xl:p-4 2xl:p-6 gap-4"
  [ngClass]="{ 'text-white': benefit.textColor === 'white' }"
  [style.background-color]="benefit.bgColor">
  <div class="flex flex-col gap-4">
    <strong
      class="text-2xl md:text-3xl xl:text-2xl 2xl:text-3xl 3xl:text-3xl font-bold">
      {{ benefit.title }}
    </strong>
    <strong class="text-base font-bold">
      {{ benefit.subtitle }}
    </strong>
  </div>
  <div class="flex flex-col gap-1">
    @for (listEntry of benefit.list; track listEntry) {
      <div class="flex">
        <div class="flex items-top gap-1">
          <div>
            <mat-icon class="material-symbols-rounded font-bold">
              check
            </mat-icon>
          </div>
          <div class="self-center text-base">{{ listEntry }}</div>
        </div>
      </div>
    }
  </div>
</div>
