<div class="flex flex-col gap-10 md:gap-8">
  <div class="flex flex-col gap-2 md:gap-4">
    <h1 class="text-4xl md:text-6xl font-bold">Preisübersicht</h1>
    <span class="text-base md:text-lg text-bn-dark-grey">
      Erstelle ein aussagekräftiges Schulprofil, um die Passung zwischen Schule
      und Fachkräften zu erhöhen. Passung ist die Grundlage für Gesundheit und
      Zufriedenheit im Beruf. Somit lassen sich hohe Kosten für Kurz- und
      Langzeitausfälle reduzieren. Kleiner Preis, grosser Nutzen.
    </span>
  </div>
  <div class="flex flex-col xl:flex-row gap-4">
    @for (offer of offers; track offer) {
      <div
        class="flex grow flex-col border-[#B5B6C1] border rounded-xl p-4 md:p-8 xl:p-4 2xl:p-8 gap-4"
        [ngClass]="{
          'text-white': offer.type === 'SCHOOL_CUSTOM',
          'bg-bn-primary-blue': offer.type === 'SCHOOL_CUSTOM'
        }">
        <div class="flex flex-col gap-2 md:gap-4 xl:gap-2 2xl:gap-4">
          <span
            class="text-2xl md:text-4xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl font-bold">
            {{ offer.title }}
          </span>
          @if (offer.type !== 'SCHOOL_CUSTOM') {
            <span
              class="text-base md:text-xl xl:text-base 2xl:text-lg 3xl:text-xl">
              {{ offer.priceTotal }} Fr. jährlich pro Schuleinheit
            </span>
          }
          <div class="flex flex-col gap-4 md:gap-8 xl:gap-4 2xl:gap-8">
            <div
              [ngClass]="{ 'fix-height': offer.type === 'SCHOOL_CUSTOM' }"
              class="text-base md:text-xl xl:text-base 2xl:text-lg 3xl:text-xl">
              @if (offer.type !== 'SCHOOL_CUSTOM') {
                <span class="text-bn-dark-grey">
                  Inklusive Mehrwertsteuer
                </span>
              }
              @if (offer.type === 'SCHOOL_CUSTOM') {
                <span> Unverbindliche Spezialangebote </span>
              }
            </div>
            <app-secondary-button (onClick)="select(offer.type)" class="w-full">
              {{
                offer.type === 'SCHOOL_CUSTOM'
                  ? 'Angebot anfordern'
                  : 'Loslegen'
              }}
            </app-secondary-button>
            <mat-divider
              [ngClass]="{ 'divider-white': offer.type === 'SCHOOL_CUSTOM' }" />
            <span class="font-bold text-base md:xl">LEISTUNGEN</span>
          </div>
        </div>
        @if (offer.type === 'SCHOOL_CUSTOM') {
          <span class="text-base md:text-lg">
            Passungsbasierte Personalrekrutierung - unterstützt eure Schulen.
          </span>
        }
        <div class="flex flex-col gap-4">
          @for (benefit of getBenefits(); track benefit.title) {
            <div class="flex flex-row">
              <div class="flex gap-2 md:gap-4">
                <div>
                  <mat-icon class="material-symbols-rounded font-bold">
                    check
                  </mat-icon>
                </div>
                <div class="flex flex-col gap-1 md:gap-2 text-base md:text-lg">
                  <span>
                    {{ benefit.title }}
                  </span>
                  <span
                    class="text-sm md:text-base"
                    [ngClass]="{
                      'text-bn-dark-grey': offer.type !== 'SCHOOL_CUSTOM',
                      'text-white': offer.type === 'SCHOOL_CUSTOM'
                    }">
                    {{ benefit.description }}
                  </span>
                  @if (offer.type !== 'SCHOOL_CUSTOM') {
                    <span
                      class="text-sm md:text-base font-bold text-bn-dark-grey">
                      {{ benefit.priceText }}
                    </span>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
