import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubscriptionType } from 'src/app/types/subscription';

@Component({
  selector: 'app-ordering-confirmation',
  templateUrl: './ordering-confirmation.component.html',
  styleUrls: ['./ordering-confirmation.component.scss'],
})
export class OrderingConfirmationComponent {
  @Input() subscriptionType: SubscriptionType = 'SCHOOL_S';
  @Input() emailAddress = '';
  @Output() closeDialog = new EventEmitter<void>();

  onClose() {
    this.closeDialog.emit();
  }
}
