import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { JobService } from '../../../../services/job.service';
import { Job } from '../../../../types/job';
import { convertRichTextToPlainText } from 'src/app/core/richText';

@Component({
  selector: 'app-job-skills-form',
  templateUrl: './job-skills-form.component.html',
  styleUrls: ['./job-skills-form.component.scss'],
})
export class JobSkillsFormComponent implements OnInit {
  id: string;
  job$: Observable<Job>;
  loading = false;

  form = this.fb.group({
    yourSkills: ['', []],
  });

  constructor(
    private fb: FormBuilder,
    private jobService: JobService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.job$ = jobService.getById(this.id);
  }

  ngOnInit(): void {
    this.job$.subscribe(job => {
      this.form.setValue({
        yourSkills: job.yourSkills_html || '',
      } as any);
    });
  }

  save() {
    this.loading = true;

    const { yourSkills } = this.form.getRawValue();
    const payload = {
      yourSkills: convertRichTextToPlainText(yourSkills) || '',
      yourSkills_html: yourSkills || '',
    };

    this.jobService.update(this.id, payload).subscribe(async () => {
      this.loading = false;
      await this.router.navigate(['jobs', this.id]);
    });
  }
}
