<div class="grid grid-cols-12 gap-x-4 pt-16 gap-y-4 lg:gap-y-12 pb-16">
  <div class="col-span-12 lg:col-span-3">
    <img alt="Logo" class="h-auto w-[116px]" src="assets/logo-black.svg" />
  </div>
  <div
    class="col-span-12 lg:col-span-7 flex flex-col lg:flex-row leading-loose font-bold lg:gap-16 pt-4 lg:pt-0 text-bn-dark-grey">
    <a class="hover:underline" target="_blank" [href]="baseUrl + '/team'">
      Über uns
    </a>
    <a class="hover:underline" target="_blank" [href]="baseUrl + '/kontakt'">
      Kontakt & Support
    </a>
    <a class="hover:underline" target="_blank" [href]="baseUrl + '/faq'">FAQ</a>
  </div>
  <div
    class="col-span-12 lg:col-span-2 flex lg:justify-end gap-6 text-lg pt-2 lg:pt-0">
    <a href="https://www.linkedin.com/company/bildigsnetz/" target="_blank">
      <mat-icon svgIcon="custom_linkedin"></mat-icon>
    </a>
    <a
      href="https://instagram.com/bildigsnetz/?igshid=OGQ5ZDc2ODk2ZA=="
      target="_blank">
      <mat-icon svgIcon="custom_instagram"></mat-icon>
    </a>
  </div>

  <div
    class="col-span-12 lg:col-span-3 text-bn-dark-grey text-sm flex-col lg:flex-row pt-8 lg:pt-0">
    <span>Mensch & Bildung GmbH © {{ year }}</span>
  </div>
  <div
    class="col-span-12 lg:col-span-9 flex gap-x-8 text-bn-dark-grey text-sm flex-col lg:flex-row gap-y-2">
    <a class="hover:underline" target="_blank" [href]="baseUrl + '/impressum'">
      Impressum
    </a>
    <a class="hover:underline" target="_blank" [href]="baseUrl + '/agb'">AGB</a>
    <a
      class="hover:underline"
      target="_blank"
      [href]="baseUrl + '/datenschutz'">
      Datenschutzerklärung
    </a>
  </div>
</div>
