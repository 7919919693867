<div class="flex sm:justify-center justify-between sm:gap-x-8">
  <div class="flex flex-col gap-4 pt-7">
    <span class="light-color text-sm">Vormittag</span>
    <span class="light-color text-sm pt-1">Nachmittag</span>
  </div>
  @for (day of days; track day; let i = $index) {
    <div class="flex">
      <div class="flex flex-col items-center">
        <span class="hidden sm:block">{{ getDay(i) }}</span>
        <span class="block sm:hidden">{{ getShortDay(i) }}</span>
        <mat-checkbox
          color="primary"
          [disabled]="disabled"
          (click)="select($event, day, 'am')"
          [checked]="!!day.am"></mat-checkbox>
        <mat-checkbox
          color="primary"
          [disabled]="disabled"
          (click)="select($event, day, 'pm')"
          [checked]="!!day.pm"></mat-checkbox>
      </div>
    </div>
  }
</div>
