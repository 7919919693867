<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Berufsbezeichnung</h1>

    <mat-form-field appearance="fill">
      <mat-label>Berufsbezeichnung</mat-label>
      <input
        type="text"
        matInput
        formControlName="function"
        [matAutocomplete]="auto" />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <p class="pl-4 pt-4 font-bold">Vorschläge:</p>
        @for (function of filteredJobFunctions$ | async; track function) {
          <mat-option [value]="function">
            {{ function }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/jobs', id]">Abbrechen</a>
      <app-action-button
        [loading]="loading"
        (onClick)="save()"
        [disabled]="!form.valid"
        text="Speichern"></app-action-button>
    </div>
  </div>
</form>
