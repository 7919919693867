@if (school$ | async; as school) {
  <form [formGroup]="form" class="grid grid-cols-12">
    <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
      <h1 class="font-bold text-4xl">Steckbrief</h1>
      @if (school.isRoot) {
        <div class="flex flex-col">
          <strong class="pb-4">Kooperationsschule</strong>
          <div class="text-sm text-[#B5B6C1] pb-2">Nur falls zutreffend</div>
          <mat-form-field>
            <mat-label>Pädagogische Hochschule</mat-label>
            <input
              type="text"
              aria-label="Pädagogische Hochschule"
              matInput
              formControlName="cooperatingCollegeOfEducation"
              [matAutocomplete]="auto" />
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="getDisplayNameOfCollege">
              @for (
                college of filteredCollegesOfEducations$ | async;
                track college
              ) {
                <mat-option [value]="college">
                  {{ getDisplayNameOfCollege(college) }}
                </mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>
      }
      <div class="flex flex-col">
        <strong class="pb-4">Mitarbeitende & Lernende</strong>
        <mat-form-field>
          <mat-label>Ungefähre Anzahl Mitarbeitende</mat-label>
          <input
            type="number"
            formControlName="countEmployees"
            matInput
            placeholder="z.B. 10" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ungefähre Anzahl Lernende</mat-label>
          <input
            type="number"
            formControlName="countStudents"
            matInput
            placeholder="z.B. 10" />
        </mat-form-field>
      </div>
      <div class="flex flex-col">
        <strong class="pb-4">Adresse</strong>
        <mat-form-field>
          <mat-label>Strasse und Hausnummer</mat-label>
          <input
            type="text"
            matInput
            formControlName="street"
            placeholder="z.B. Haupstrasse 7" />
          <mat-error>Gib eine Strasse ein.</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Postleitzahl</mat-label>
          <input
            type="text"
            maxlength="4"
            matInput
            formControlName="zipcode"
            placeholder="z.B. 8045" />
          <mat-error>Gib eine Postleitzahl ein.</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ort</mat-label>
          <input
            type="text"
            matInput
            formControlName="city"
            placeholder="z.B. Zürich" />
          <mat-error>Gib einen Ort ein.</mat-error>
        </mat-form-field>
      </div>
      @if (school.isRoot) {
        <div class="flex flex-col">
          <strong class="pb-4">Webseite der Schule</strong>
          <mat-form-field>
            <mat-label>Webseite</mat-label>
            <input
              type="url"
              matInput
              formControlName="website"
              placeholder="z.B. https://deineschule.ch" />
            <mat-error>Gib eine gültige Webseite ein.</mat-error>
          </mat-form-field>
        </div>
      }
      <div class="flex justify-end gap-8">
        <a mat-button color="primary" [routerLink]="['/schools', id]">
          Abbrechen
        </a>
        <app-action-button
          [disabled]="!form.valid"
          text="Speichern"
          (onClick)="save()"></app-action-button>
      </div>
    </div>
  </form>
}
