<div class="grid grid-cols-12 pt-16 min-h-screen">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="font-bold text-4xl">Passwort vergessen?</h1>
      <span class="text-[#B5B6C1] text-base">
        Kein Problem: Gib hier die E-Mail-Adresse ein, mit der du dich bei
        Bildigsnetz einloggst.
      </span>
    </div>

    <div class="flex flex-col">
      <mat-form-field>
        <mat-label>E-Mail Adresse</mat-label>
        <input
          type="email"
          matInput
          [formControl]="emailControl"
          placeholder="Bsp.: pat@example.com" />
        @if (emailControl.hasError('email')) {
          <mat-error> E-Mail Adresse ist ungültig. </mat-error>
        }
        @if (emailControl.hasError('required')) {
          <mat-error> E-Mail ist erforderlich. </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button routerLink="/login">Abbrechen</a>
      <app-action-button
        [loading]="loading"
        (onClick)="resetPassword()"
        [disabled]="!emailControl.valid"
        text="Zurücksetzen"></app-action-button>
    </div>
  </div>
</div>
