<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Benachrichtigungen per E-Mail</h1>
  </div>

  <div
    class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4 pt-8">
    <div class="flex flex-col">
      <div class="flex justify-between">
        <span class="font-bold text-base">
          Gerne möchte ich wichtige Informationen im Newsletter erhalten
        </span>
        <mat-slide-toggle
          color="primary"
          formControlName="newsletterEmailOptIn"></mat-slide-toggle>
      </div>
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" routerLink="/settings">Zurück</a>
      <app-action-button
        [disabled]="!form.valid"
        (onClick)="save()"
        text="Speichern"></app-action-button>
    </div>
  </div>
</form>
