import { NgxEditorConfig, Toolbar } from 'ngx-editor';
import { Plugin, PluginKey } from 'prosemirror-state';
import { convert } from 'html-to-text';
import { convertRichTextToPlainText } from '../core/richText';

// GENERAL CONFIGURATION

export const ngxEditorConfiguration = {
  locals: {
    bold: 'Fett',
    italic: 'Kursiv',
    bullet_list: 'Bullet-Liste',
    ordered_list: 'Nummerierte Liste',
    heading: 'Überschriften',
    h2: 'Überschrift 1',
    h3: 'Überschrift 2',
    insertLink: 'Link einfügen',
    removeLink: 'Link entfernen',
    undo: 'Rückgängig',
    redo: 'Wiederholen',
  },
} as NgxEditorConfig;

// TOOLBARS

export const ngxEditorMinimalToolbar = [
  [{ heading: ['h2', 'h3'] }],
  ['bold', 'italic'],
  ['ordered_list', 'bullet_list'],
  ['link'],
  ['undo', 'redo'],
] as Toolbar;

// PLUGINS

export const ngxEditorSanitizePastedHtmlPlugin = new Plugin({
  key: new PluginKey('PastePlugin'),
  props: {
    transformPastedHTML(inputHtml: string): string {
      return convertRichTextToPlainText(inputHtml);
    },
  },
});
