<div
  class="h-full overflow-hidden pb-4"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="fetchNext()"
  [fromRoot]="true"
  infiniteScrollContainer="#scroll-area">
  <div class="grid grid-cols-12 gap-x-4">
    <mat-form-field class="col-span-12 lg:col-span-6">
      <input
        [formControl]="controls.search"
        matInput
        [placeholder]="placeholderOptions[placeholderIndex]" />
      <mat-icon class="material-symbols-rounded" matPrefix>search</mat-icon>
    </mat-form-field>

    <div class="flex justify-end col-span-12 lg:col-span-6 pb-4">
      @if (isAuthorized$ | async) {
        <button
          class="mt-2.5 mr-3"
          color="primary"
          mat-stroked-button
          (click)="openOrderDialog()">
          Schulprofil erstellen
        </button>
      }
      <button
        class="mt-2.5 lg:w-24"
        color="primary"
        mat-stroked-button
        (click)="toggleFilters()">
        @if (!showFilters) {
          <mat-icon
            class="material-symbols-rounded ml-0 mr-0 md:mr-auto md:ml-auto">
            tune
          </mat-icon>
        }
        @if (showFilters) {
          <mat-icon
            class="material-symbols-rounded ml-0 mr-0 md:mr-auto md:ml-auto">
            close
          </mat-icon>
        }
        <span class="hidden lg:inline-block">Filter</span>
      </button>
    </div>

    @if (showFilters) {
      <div class="grid grid-cols-12 gap-x-4 col-span-12">
        <div class="col-span-12 lg:col-span-6 3xl:col-span-4">
          <div class="flex flex-col sm:flex-row">
            <mat-form-field
              appearance="fill"
              class="flex grow basis-0 bn-combined-field-left">
              <mat-label>Schulstufen</mat-label>
              <mat-select [formControl]="controls.levels" multiple>
                @for (level of schoolLevels; track $index) {
                  <mat-option [value]="level">
                    {{ level }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
            <div class="bn-combined-field-divider"></div>
            <mat-form-field
              appearance="fill"
              class="flex grow basis-0 bn-combined-field-right">
              <mat-label>Stufenspezifikationen</mat-label>
              <mat-select [formControl]="controls.levelDescriptions" multiple>
                @for (description of schoolLevelDescriptions; track $index) {
                  <mat-option [value]="description">
                    {{ description }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6 3xl:col-span-4">
          <div class="flex flex-col sm:flex-row">
            <mat-form-field
              appearance="fill"
              class="flex grow basis-0 bn-combined-field-left">
              <mat-label>Ort</mat-label>
              <input
                type="text"
                matInput
                [formControl]="controls.location"
                [matAutocomplete]="auto" />
              <mat-icon class="material-symbols-rounded" matPrefix>
                location_on
              </mat-icon>
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayLocationFn">
                @for (location of locations$ | async; track location.place_id) {
                  <mat-option
                    [value]="location"
                    (onSelectionChange)="setLocation(location)">
                    {{ location.formatted_address }}
                  </mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
            <div class="bn-combined-field-divider"></div>
            <mat-form-field
              appearance="fill"
              class="flex grow basis-0 bn-combined-field-right">
              <mat-label>Umkreis</mat-label>
              <mat-select [formControl]="controls.radius">
                <mat-option [value]="undefined">—</mat-option>
                @for (radius of possibleRadius; track $index) {
                  <mat-option [value]="radius * 1000">
                    {{ radius }} km
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field
          appearance="fill"
          class="col-span-12 lg:col-span-6 3xl:col-span-4">
          <mat-label>Schularten</mat-label>
          <mat-select [formControl]="controls.types" multiple>
            @for (type of schoolTypes; track $index) {
              <mat-option [value]="type">
                {{ type }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }

    @if (!showFilters) {
      <div class="col-span-12 flex flex-wrap gap-4 pb-4">
        @if (controls.location.value) {
          <mat-chip>
            {{ selectedLocation?.formatted_address }}
            <button
              matChipRemove
              (click)="
                removeFilter(
                  controls.location,
                  controls.location.value,
                  'location'
                )
              ">
              <mat-icon class="material-symbols-rounded">cancel</mat-icon>
            </button>
          </mat-chip>
        }
        @if (controls.radius.value) {
          <mat-chip>
            {{ controls.radius.value / 1000 }} km
            <button
              matChipRemove
              (click)="
                removeFilter(controls.radius, controls.radius.value, 'radius')
              ">
              <mat-icon class="material-symbols-rounded">cancel</mat-icon>
            </button>
          </mat-chip>
        }
        @for (function of controls.types.value; track function) {
          <mat-chip>
            {{ function }}
            <button
              matChipRemove
              (click)="removeFilter(controls.types, function, 'types')">
              <mat-icon class="material-symbols-rounded">cancel</mat-icon>
            </button>
          </mat-chip>
        }
        @for (function of controls.levels.value; track function) {
          <mat-chip>
            {{ function }}
            <button
              matChipRemove
              (click)="removeFilter(controls.levels, function, 'levels')">
              <mat-icon class="material-symbols-rounded">cancel</mat-icon>
            </button>
          </mat-chip>
        }
        @for (function of controls.levelDescriptions.value; track function) {
          <mat-chip>
            {{ function }}
            <button
              matChipRemove
              (click)="
                removeFilter(
                  controls.levelDescriptions,
                  function,
                  'levelDescriptions'
                )
              ">
              <mat-icon class="material-symbols-rounded">cancel</mat-icon>
            </button>
          </mat-chip>
        }
      </div>
    }
  </div>

  <div class="grid grid-cols-12 gap-4">
    @for (school of schools; track school.objectID; let s = $index) {
      <a
        class="col-span-12 lg:col-span-4 xl:col-span-3"
        [routerLink]="[school.objectID]"
        queryParamsHandling="merge"
        [queryParams]="{ s: s }"
        (click)="navigate($event, school, s)">
        <app-school-card
          [school]="school"
          [id]="school.objectID"></app-school-card>
      </a>
    }
  </div>
</div>
