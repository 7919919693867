import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-password-fields',
  templateUrl: './password-fields.component.html',
  styleUrls: ['./password-fields.component.scss'],
})
export class PasswordFieldsComponent implements OnInit {
  @Input({ required: true }) parentForm: FormGroup = new FormGroup({});
  @Input({ required: true })
  helpPageUrl = '';
  @Input({ required: true }) type: 'signup' | 'change-pw' = 'signup';

  form = this.fb.group({
    password: ['', [Validators.required, this.passwordValidator]],
    passwordRepetition: ['', [Validators.required]],
  });

  constructor(private fb: FormBuilder) {
    // Check if passwords match
    this.form.valueChanges
      .pipe(
        filter(value => !!value.password),
        map(value => value.password !== value.passwordRepetition)
      )
      .subscribe(pwsDontMatch => {
        if (pwsDontMatch) {
          this.form.controls.passwordRepetition.setErrors({
            passwordMatch: 'error',
          });
        } else {
          this.form.controls.passwordRepetition.clearValidators();
        }
      });
  }

  ngOnInit() {
    this.parentForm.addControl('passwordFields', this.form);
  }

  private passwordValidator(pwControl: FormControl) {
    const value = pwControl.value;
    if (!value) {
      // If no value is provided, return null (other validators will check for required)
      return null;
    }

    // Regular expression to test the password strength
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumeric = /[0-9]/.test(value);
    const isLengthValid = value.length >= 8;

    if (!hasUpperCase || !hasLowerCase || !hasNumeric || !isLengthValid) {
      return { passwordStrengthError: true };
    }

    // If the password meets all the requirements, return null
    return null;
  }
}
