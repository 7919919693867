<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Über uns</h1>

    <app-rich-text-field
      [placeholderText]="placeholder"
      formControlName="about"></app-rich-text-field>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/schools', id]">
        Abbrechen
      </a>
      <app-action-button
        [disabled]="!form.valid"
        text="Speichern"
        (onClick)="save()"></app-action-button>
    </div>
  </div>
</form>
