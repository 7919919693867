<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <span class="text-base">
      Leg hier das neue Passwort fest, mit dem du dich bei Bildigsnetz
      einloggst.
    </span>
    <h1 class="font-bold text-4xl">Passwort ändern</h1>

    <div class="flex flex-col">
      <mat-form-field class="pb-12">
        <mat-label>Aktuelles Passwort</mat-label>
        <input type="password" matInput formControlName="password" />
        <mat-hint (click)="resetPassword()" class="cursor-pointer">
          Passwort vergessen?
        </mat-hint>
      </mat-form-field>

      <app-password-fields
        [parentForm]="form"
        helpPageUrl="/settings/change-password/secure-password"
        type="change-pw">
      </app-password-fields>

      @if (changePwError) {
        <mat-error>
          {{ changePwError }}
        </mat-error>
      }
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" routerLink="/settings/account">Zurück</a>
      <app-action-button
        [loading]="loading"
        [disabled]="!form.valid"
        (onClick)="change()"
        text="Ändern"></app-action-button>
    </div>
  </div>
</form>
