<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Unterrichtsfächer</h1>

    <div class="flex flex-col">
      <mat-form-field appearance="fill">
        <mat-label>Fächer</mat-label>
        <mat-select formControlName="subjects" multiple>
          @for (subject of subjects; track $index) {
            <mat-option [value]="subject">
              {{ subject }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/jobs', id]">Abbrechen</a>
      <app-action-button
        [loading]="loading"
        (onClick)="save()"
        [disabled]="!form.valid"
        text="Speichern"></app-action-button>
    </div>
  </div>
</form>
