import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements OnInit {
  @Input({ required: true }) text!: string;

  content = '';
  chip?: string;

  ngOnInit(): void {
    if (this.text?.includes(') ')) {
      const parts = this.text.split(') ');
      this.chip = parts[0].replace('(', '');
      this.content = parts[1];
    } else {
      this.content = this.text;
    }
  }
}
