<div class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="font-bold text-4xl">Hintergrundbild</h1>
      <span class="text-base text-[#B5B6C1]">
        Mit einem guten Hintergrundbild hebst du dich von anderen ab. Das
        Hinzufügen eines Fotos zu deinem Profil macht deine professionelle
        Geschichte optisch ansprechender.
      </span>
    </div>
    <div class="flex flex-col gap-8">
      <input
        type="file"
        class="hidden"
        [accept]="acceptTypes"
        (change)="fileChangeEvent($event)"
        #fileUpload />
      <div class="flex justify-end w-full">
        <image-cropper
          alignImage="left"
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="355 / 118"
          format="jpeg"
          (imageCropped)="imageCropped($event)"
          [resizeToWidth]="1420"
          [imageQuality]="100"
          [resizeToHeight]="612"
          [onlyScaleDown]="true"></image-cropper>
      </div>
      @if (!imageChangedEvent) {
        <img alt="Vorschau" class="rounded-2xl" [src]="getImageUrl()" />
      }
      <div class="flex gap-4">
        <button mat-stroked-button color="primary" (click)="fileUpload.click()">
          <mat-icon class="material-symbols-rounded">upload</mat-icon>
          Foto wählen
        </button>
        <button mat-stroked-button color="primary" (click)="clear()">
          <mat-icon class="material-symbols-rounded">delete</mat-icon>
          Löschen
        </button>
      </div>

      <div class="flex items-center">
        <mat-icon class="text-[#FFB81C] material-symbols-rounded pr-8">
          info
        </mat-icon>
        <div class="text-base text-[#B5B6C1]">
          Wähle ein Foto, das deine Persönlichkeit darstellt. Es kann ein Bild
          sein, das einen besonderen Meilenstein in deiner Karriere hervorhebt
          oder deine Interessen betont.
        </div>
      </div>
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/me', id]">Abbrechen</a>
      <app-action-button
        [disabled]="!imageChangedEvent && !deleted"
        (onClick)="save()"
        text="Speichern" />
    </div>
  </div>
</div>
