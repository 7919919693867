<input
  type="file"
  hidden
  [accept]="acceptTypes"
  (change)="onSelectFile($event)"
  #fileUpload />

<mat-form-field appearance="fill">
  <mat-label>{{ title }}</mat-label>
  <input
    readonly
    type="text"
    matInput
    (click)="fileUpload.click()"
    [value]="file?.description"
    [required]="required" />
  @if (file) {
    <button matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  }
  @if (!file) {
    <button
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="fileUpload.click()">
      <mat-icon class="material-symbols-rounded">upload</mat-icon>
    </button>
  }
</mat-form-field>
