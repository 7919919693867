import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-highlight-bubble',
  templateUrl: './highlight-bubble.component.html',
  styleUrl: './highlight-bubble.component.scss',
})
export class HighlightBubbleComponent {
  @Input() text = '';
}
