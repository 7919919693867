<div class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="font-bold text-4xl">Schulentwicklung & -betrieb</h1>
      <span class="text-base">
        Lade hier bspw. das Schulprogramm, die aktuelle Schulevaluation,
        Jahresplanung oder sonst etwas hoch, was für Fachkräfte interessant ist.
        Die Dokumente erscheinen in der Reihenfolge, in der du sie hochlädst.
      </span>
    </div>

    <div class="flex items-center">
      <mat-icon class="text-[#FFB81C] material-symbols-rounded pr-8">
        info
      </mat-icon>
      <div class="text-base text-[#B5B6C1]">
        Deine Anhänge dürfen nicht grösser als 5 MB sein. Du kannst PDF, DOC
        oder DOCX Dateien hochladen.
      </div>
    </div>

    <div class="flex flex-col">
      @for (document of currentDocumentList; track document; let i = $index) {
        <div class="flex flex-col">
          <div class="pb-4 flex justify-end w-full">
            <button
              class="self-end"
              mat-icon-button
              (click)="removeDocument(i)">
              <mat-icon class="material-symbols-rounded">delete</mat-icon>
            </button>
          </div>
          <mat-form-field class="w-full cursor-pointer">
            <mat-label>Datei</mat-label>
            <input [value]="document.originName" matInput readonly />
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>Titel</mat-label>
            <input
              rows="5"
              type="text"
              matInput
              [formControl]="document.title" />
            <mat-error>Füge einen Titel hinzu.</mat-error>
          </mat-form-field>
        </div>
      }
    </div>

    <div class="flex flex-col">
      @for (document of newDocumentList; track document; let i = $index) {
        <div class="flex flex-col last:pt-16">
          <input
            type="file"
            class="hidden"
            [accept]="acceptTypes"
            (change)="onSelectFile($event, document)"
            #fileUpload />
          @if (document.document?.file) {
            <div class="pb-4 flex justify-end w-full">
              <button
                class="self-end"
                mat-icon-button
                (click)="removeNewDocument(i)">
                <mat-icon class="material-symbols-rounded">delete</mat-icon>
              </button>
            </div>
          }
          <mat-form-field
            class="w-full cursor-pointer"
            (click)="fileUpload.click()">
            <mat-label>Datei</mat-label>
            <input [value]="document.document?.filename" matInput readonly />
            <button matSuffix mat-icon-button>
              <mat-icon class="material-symbols-rounded">upload</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>Titel</mat-label>
            <input
              rows="5"
              type="text"
              matInput
              [formControl]="document.title" />
            <mat-error>Füge einen Titel hinzu.</mat-error>
          </mat-form-field>
        </div>
      }
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button [routerLink]="['/schools', id]" [disabled]="loading">
        Abbrechen
      </a>
      <app-action-button
        [disabled]="!isFormValid()"
        (onClick)="save()"
        [loading]="loading"
        text="Speichern"></app-action-button>
    </div>
  </div>
</div>
