import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rich-text-view',
  templateUrl: './rich-text-view.component.html',
  styleUrl: './rich-text-view.component.scss',
})
export class RichTextViewComponent {
  @Input({ required: true }) html!: string | undefined;
  @Input({ required: true }) tailwindCssLineClamp!: string;

  expanded = false;

  getLineClampClass(): string {
    return this.expanded ? '' : this.tailwindCssLineClamp;
  }

  toggleText() {
    this.expanded = !this.expanded;
  }
}
