@if (!showEmptyCard) {
  <div class="grid grid-cols-12 md:gap-4">
    <div class="flex flex-col col-span-12 lg:col-span-8 lg:col-start-3">
      <div>
        <strong class="font-bold text-4xl md:text-5xl">Über mich</strong>
        @if (isOwner) {
          <app-edit-button
            class="pl-4"
            routerLink="edit/about"></app-edit-button>
        }
      </div>
      <app-rich-text-view
        class="pt-4"
        [html]="profile.about_html"
        tailwindCssLineClamp="line-clamp-6"></app-rich-text-view>
    </div>
  </div>
}
@if (showEmptyCard && isOwner) {
  <app-empty-card
    title="Über mich"
    subtitle="Was macht dich aus?"
    description="Stell dich hier kurz vor – sag wer du bist und heb hervor, was dich besonders macht."
    link="edit/about"></app-empty-card>
}
