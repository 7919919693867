import { Component, Input } from '@angular/core';
import { getPublicFileUrl } from '../../../../core/helpers';
import {
  Profile,
  ProfileContact,
  ProfileJobFunction,
} from '../../../../types/profile';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { ProfileService } from '../../../../services/profile.service';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss'],
})
export class ProfileHeaderComponent {
  @Input({ required: true }) profile!: Profile;
  @Input({ required: true }) isOwner!: boolean;
  @Input() contact?: ProfileContact | null;

  constructor(
    private analytics: Analytics,
    private profileServie: ProfileService
  ) {}

  getAvatarUrl(profile: Profile) {
    if (profile.avatar && profile.id) {
      return getPublicFileUrl('profiles', profile.id, 'avatar');
    }

    return 'assets/defaults/avatar.svg';
  }

  getBackgroundUrl(profile: Profile) {
    if (profile.backgroundImage && profile.id) {
      return getPublicFileUrl('profiles', profile.id, 'backgroundImage');
    }

    return 'assets/defaults/background.jpeg';
  }

  getJobTitles(jobFunctions: ProfileJobFunction[]) {
    return this.profileServie.getJobTitles(jobFunctions);
  }

  showContactButton() {
    return this.contact?.email && !this.isOwner;
  }

  track() {
    logEvent(this.analytics, 'contact_user');
  }
}
