@if (flat) {
  <button
    class="flex w-full"
    [ngStyle]="{
      'background-color': !!customColor && !disabled ? customColor : ''
    }"
    mat-flat-button
    [color]="color"
    [disabled]="loading || disabled"
    (click)="triggerAction()"
    [class.spinner]="loading">
    @if (!loading) {
      <span>{{ text }}</span>
    }
  </button>
}
@if (!flat) {
  <button
    class="flex w-full"
    mat-button
    [ngStyle]="{
      'background-color': !!customColor && !disabled ? customColor : ''
    }"
    [color]="color"
    [disabled]="loading || disabled"
    (click)="triggerAction()"
    [class.spinner]="loading">
    @if (!loading) {
      <span>{{ text }}</span>
    }
  </button>
}
