@if (isOwner || !showEmptyCertifications || !showEmptySubjects) {
  <div class="grid grid-cols-12 md:gap-4">
    <div class="flex flex-col col-span-12 lg:col-span-8 lg:col-start-3">
      <strong class="font-bold text-4xl md:text-5xl">Qualifikationen</strong>
      <div class="py-4">
        @if (isOwner && showEmptyCertifications && showEmptySubjects) {
          <span class="text-base font-very-bright-color">
            Dieses Detail ist zurzeit noch leer und wird anderen daher nicht
            angezeigt.
          </span>
        }
      </div>
      <div class="flex flex-col gap-4">
        @if (isOwner || !showEmptySubjects) {
          <div class="border border-solid border-color rounded-2xl p-8">
            @if (showEmptySubjects) {
              <div class="flex gap-8">
                <button
                  mat-icon-button
                  class="scale-[0.85] fab-button-color self-center"
                  routerLink="edit/subjects">
                  <mat-icon class="material-symbols-rounded scale-110"
                    >add</mat-icon
                  >
                </button>
                <div class="flex flex-col">
                  <span class="font-bold text-base">
                    Lernbereiche und Unterrichtsfächer
                  </span>
                  <span class="text-base">
                    Hier kannst du alle Fächer auflisten, die du unterrichten
                    kannst.
                  </span>
                </div>
              </div>
            }
            @if (!showEmptySubjects) {
              <div class="flex flex-col">
                <div class="flex">
                  <strong class="self-center text-base font-bold">
                    Unterrichtsfächer
                  </strong>
                  @if (isOwner) {
                    <app-edit-button
                      class="pl-4 pb-2"
                      routerLink="edit/subjects"></app-edit-button>
                  }
                </div>
                <div class="flex flex-col gap-4">
                  @for (subjects of subjectGroups; track subjects) {
                    <div class="flex flex-col">
                      <div class="flex flex-col gap-2">
                        <span class="text-sm font-very-bright-color">
                          {{ getJobLevels(subjects[0].schoolLevels) }}
                        </span>
                        <div class="flex gap-4 flex-wrap">
                          @for (subject of subjects; track subject) {
                            <mat-chip class="font-normal">
                              <span class="pl-1 pr-1">{{
                                subject.subject
                              }}</span>
                            </mat-chip>
                          }
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        }
        @if (isOwner || !showEmptyCertifications) {
          <div class="border border-solid border-color rounded-2xl p-8 gap-8">
            @if (showEmptyCertifications) {
              <div class="flex gap-8">
                <button
                  mat-icon-button
                  class="scale-[0.85] fab-button-color self-center"
                  routerLink="edit/certifications">
                  <mat-icon class="material-symbols-rounded scale-110"
                    >add</mat-icon
                  >
                </button>
                <div class="flex flex-col">
                  <span class="font-bold text-base">
                    Weiterbildungen & Zertifikate
                  </span>
                  <span class="text-base">
                    Hier kannst du offizielle Qualifikationen auflisten, die du
                    erworben hast.
                  </span>
                </div>
              </div>
            }
            @if (!showEmptyCertifications) {
              <div class="flex flex-col">
                <div class="flex">
                  <strong class="self-center text-base font-bold">
                    Weiterbildungen & Zertifikate
                  </strong>
                  @if (isOwner) {
                    <app-edit-button
                      class="pl-4 pb-2"
                      routerLink="edit/certifications"></app-edit-button>
                  }
                </div>
                <div class="flex flex-col gap-4">
                  <div class="flex flex-col">
                    <div class="flex flex-col gap-2">
                      <div class="flex gap-4 flex-wrap">
                        @for (
                          certification of profile.certifications;
                          track certification
                        ) {
                          <mat-chip class="font-normal">
                            <div
                              class="pl-1 pr-1 overflow-hidden text-ellipsis whitespace-nowrap max-w-[260px] md:max-w-[340px]">
                              {{ certification }}
                            </div>
                          </mat-chip>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </div>
    </div>
  </div>
}
