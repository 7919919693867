import { Component, EventEmitter, Output } from '@angular/core';
import { OrderProcessBenefit } from './order-process-benefit/order-process-benefit.component';

@Component({
  selector: 'app-order-process-start',
  templateUrl: './order-process-start.component.html',
  styleUrls: ['./order-process-start.component.scss'],
})
export class OrderProcessStartComponent {
  @Output() showPriceOverview = new EventEmitter<void>();

  benefits: OrderProcessBenefit[] = [
    {
      bgColor: '#2880FE',
      textColor: 'white',
      title: 'Schulprofil',
      subtitle: 'Erstelle ein öffentliches Schulprofil mit:',
      list: [
        'Sämtlichen Standorten der Schuleinheit',
        'Pädagogischen Schwerpunkten',
        'Video',
        'Fotos',
        'Audios',
        'Texten',
      ],
    },
    {
      bgColor: '#F5DFE1',
      title: 'Netzwerk',
      subtitle: 'Profitiere mehrfach von der Bildigsnetz-Community:',
      list: [
        'Schule im Fokus der Bildigsnetz-Mitglieder',
        'Aktives Personalrecruiting',
        'Praktische Such- und Kontaktfunktionen',
      ],
    },
    {
      bgColor: '#D6EE9D',
      title: 'Unbegrenzte Zugänge',
      subtitle: 'Verwaltet das Schulprofil und die Stellen gemeinsam:',
      list: [
        'Einfach Administrationsrechte vergeben',
        'Unbeschränkte Anzahl Administratoren pro Schuleinheit',
      ],
    },
    {
      bgColor: '#EAEBEF',
      title: 'Innovatives Stellenportal',
      subtitle: 'Nutze folgende Vorteile:',
      list: [
        'Übersichtliches Stellen- und Verwaltungstool',
        'Unbegrenzte Anzahl Stelleninserate schalten',
        'Feststellen und Stellvertretungen',
        'Inserate verknüpft mit dem Schulprofil',
        'Neue Massstäbe im Handling: Erleichternde Funktionen & ansprechende Darstellung',
      ],
    },
  ];

  getBenefitClasses(bgColor: string, textColor: string | null) {
    const classes = [];
    classes.push(`bg-[${bgColor}]`);

    if (textColor) {
      classes.push(`text-${textColor}`);
    }

    return classes;
  }

  showPrices() {
    this.showPriceOverview.emit();
  }
}
