<div class="flex justify-between sm:w-52">
  <div class="flex">
    <div
      class="inline-block rounded-full relative h-6 w-6 shrink-0"
      [ngClass]="{ success: success, error: !success }">
      <mat-icon
        class="material-symbols-rounded absolute w-full h-full scale-75 font-bold">
        {{ getIcon() }}
      </mat-icon>
    </div>
    @if (!!link) {
      <a class="mt-1 pl-2 text-[#2880fe] hover:underline" [routerLink]="link">{{
        text
      }}</a>
    }
    @if (!!scrollTargetId) {
      <span
        class="mt-1 pl-2 text-[#2880fe] hover:underline cursor-pointer"
        (click)="onClick()"
        >{{ text }}</span
      >
    }
  </div>
</div>
