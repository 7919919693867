import { Component } from '@angular/core';
import { Application } from '../../../types/application';
import { ApplicationService } from '../../../services/application.service';
import { EMPTY, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../../services/breadcrumb.service';

@Component({
  selector: 'app-my-applications',
  templateUrl: './my-applications.component.html',
  styleUrls: ['./my-applications.component.scss'],
})
export class MyApplicationsComponent {
  applications$: Observable<Application[]> = EMPTY;
  keys: string[] = [];
  loading = false;
  showArchived = false;
  noApplicationsDesc =
    'Sobald du deine ersten Bewerbungen verschickt hast, erscheinen sie hier.';
  noApplicationsDescArchived =
    'Hier werden deine archivierten Bewerbungen angezeigt.';

  constructor(
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    private applicationService: ApplicationService
  ) {
    route.data.subscribe(data => {
      this.showArchived = data['archived'];
      this.applications$ = applicationService.getMy(this.showArchived);

      if (this.showArchived) {
        breadcrumbService.set([
          { text: 'Meine Bewerbungen', link: '/applications/my' },
          { text: `Archiv` },
        ]);
      }
    });
  }

  withdraw(application: Application) {
    this.loading = true;
    this.applicationService.withdraw(application.id!).subscribe(() => {
      this.loading = false;
    });
  }

  archive(application: Application) {
    this.applicationService.archive(application.id!).subscribe();
  }
}
