import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-card-item',
  templateUrl: './progress-card-item.component.html',
  styleUrls: ['./progress-card-item.component.scss'],
})
export class ProgressCardItemComponent {
  @Input({ required: true }) text!: string;
  @Input({ required: true }) success!: boolean;
  @Input() link?: string;
  @Input() scrollTargetId?: string;

  getIcon() {
    return this.success ? 'check' : 'horizontal_rule';
  }

  onClick() {
    if (this.scrollTargetId) {
      const element = document.getElementById(this.scrollTargetId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }
}
