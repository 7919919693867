import { Component } from '@angular/core';
import { JobService } from '../../services/job.service';
import { ProfileService } from '../../services/profile.service';
import { EMPTY, Observable } from 'rxjs';
import { Profile } from '../../types/profile';
import { Job } from '../../types/job';
import { StatisticService } from '../../services/statistic.service';
import { Statistic } from '../../types/statistic';
import { ShareService } from '../../services/share.service';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { SchoolService } from '../../services/school.service';
import { School } from '../../types/school';
import { MatDialog } from '@angular/material/dialog';
import { OrderProcessComponent } from 'src/app/dialogs/order-process/order-process.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  readonly shareTitle = 'Bildigsnetz';
  readonly shareText =
    'Gib dem wichtigsten Berufsfeld ein Gesicht und werde Teil von Bildigsnetz: Für Passung und Attraktivität.';
  readonly shareUrl = 'https://bildigsnetz.ch';

  statistics$: Observable<Statistic> = EMPTY;
  currentProfile$: Observable<Profile> = EMPTY;
  profiles$: Observable<Profile[]> = EMPTY;
  schools$: Observable<School[]> = EMPTY;
  jobs$: Observable<Job[]> = EMPTY;

  constructor(
    jobService: JobService,
    profileServie: ProfileService,
    statisticService: StatisticService,
    schoolService: SchoolService,
    private shareService: ShareService,
    private analytics: Analytics,
    private dialog: MatDialog
  ) {
    this.currentProfile$ = profileServie.getMe(true);
    this.profiles$ = profileServie.getLatest();
    this.jobs$ = jobService.getLatest();
    this.schools$ = schoolService.getLatest(8);
    this.statistics$ = statisticService.get();
  }

  share() {
    logEvent(this.analytics, 'share_link');
    this.shareService.share({
      title: this.shareTitle,
      text: this.shareText,
      url: this.shareUrl,
    });
  }

  getShareLink() {
    return `mailto:?subject=${this.shareTitle}&amp;body=${this.shareText} ${this.shareUrl}`;
  }

  canShare() {
    return this.shareService.canShare();
  }

  openOrderDialog() {
    this.dialog.open(OrderProcessComponent, {
      minWidth: '100vw',
      minHeight: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      disableClose: true,
    });
  }
}
