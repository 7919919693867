import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, map } from 'rxjs';
import { BreadCrumb } from '../core/types';
import { Router, RoutesRecognized } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  breadcrumbs$: Observable<BreadCrumb[]>;

  private breadcrumbs: BreadCrumb[] = [];
  private update$ = new ReplaySubject(1);

  constructor(router: Router) {
    router.events.subscribe(data => {
      if (data instanceof RoutesRecognized) {
        if (!data.state.root.firstChild?.data?.['breadcrumbs']) this.clear();
      }
    });

    this.breadcrumbs$ = this.update$.pipe(map(() => this.breadcrumbs));
  }

  set(breadcrumbs: BreadCrumb[]) {
    this.breadcrumbs = breadcrumbs;
    this.update$.next(1);
  }

  clear() {
    this.breadcrumbs = [];
    this.update$.next(1);
  }
}
