import { Component, Input } from '@angular/core';
import { School } from '../../types/school';
import { getPublicFileUrl } from '../../core/helpers';
import { SchoolService } from '../../services/school.service';

@Component({
  selector: 'app-school-card',
  templateUrl: './school-card.component.html',
  styleUrls: ['./school-card.component.scss'],
})
export class SchoolCardComponent {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) id!: string;

  constructor(private schoolService: SchoolService) {}

  getAvatarUrl(school: School) {
    if (school.avatar) {
      return getPublicFileUrl('schools', this.id, 'avatar');
    }

    return 'assets/defaults/avatar.svg';
  }

  getBackgroundUrl(school: School) {
    if (school.backgroundImage) {
      return getPublicFileUrl('schools', this.id, 'backgroundImage');
    }

    return '/assets/defaults/background_school.jpeg';
  }

  getSchoolTitle(school: School) {
    return this.schoolService.getLevelsTitle(
      school.levels || [],
      school.levelDescriptions || []
    );
  }
}
