<div class="grid grid-cols-12">
  <div [class]="outerGridItemClasses">
    <strong class="font-bold text-4xl md:text-5xl">{{ title }}</strong>
    <div class="py-4">
      <span class="text-base font-very-bright-color">
        Dieses Detail ist zurzeit noch leer und wird anderen daher nicht
        angezeigt.
      </span>
    </div>
    <div class="flex flex-col bgfill rounded-2xl p-8 text-base gap-8">
      <div class="flex flex-col gap-2">
        <strong class="font-bold">{{ subtitle }}</strong>
        <span>{{ description }}</span>
      </div>
      @if (link) {
        <button
          class="w-32"
          color="primary"
          mat-stroked-button
          [routerLink]="link">
          <span>Hinzufügen</span>
        </button>
      }
    </div>
  </div>
</div>
