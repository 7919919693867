import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from '../../../../services/profile.service';
import { Profile } from '../../../../types/profile';

@Component({
  selector: 'app-profile-slogan-form',
  templateUrl: './profile-slogan-form.component.html',
  styleUrls: ['./profile-slogan-form.component.scss'],
})
export class ProfileSloganFormComponent implements OnInit {
  id: string;
  profile$: Observable<Profile>;

  form = this.fb.group({
    slogan: ['', []],
  });

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.profile$ = profileService.getMe(false);
  }

  ngOnInit(): void {
    this.profile$.subscribe(profile => {
      this.form.setValue({
        slogan: profile.about || '',
      } as any);
    });
  }

  save() {
    const profilePartial = this.form.value as Partial<Profile>;
    this.profileService.update(profilePartial).subscribe(_ => {
      this.router.navigate(['me', this.id]);
    });
  }
}
