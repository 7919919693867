import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgForm,
} from '@angular/forms';
import { MediaFile } from '../../../types/core';

@Component({
  selector: 'app-file-upload-field',
  templateUrl: './file-upload-field.component.html',
  styleUrls: ['./file-upload-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadFieldComponent),
      multi: true,
    },
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FileUploadFieldComponent implements ControlValueAccessor, OnInit {
  @Input() type: 'image' | 'video' | 'document' | 'audio' | 'unknown' =
    'unknown';
  @Input() title = 'Datei auswählen';
  @Input() required = false;
  @Output() onClear = new EventEmitter<void>();
  @Output() onSelect = new EventEmitter<MediaFile>();
  @ViewChild('fileUpload') fileUploadInput?: ElementRef<HTMLInputElement>;

  public propagateChange: any = () => {};
  public propagateTouch: any = () => {};
  isDisabled = false;
  acceptTypes = '';

  file?: MediaFile;

  readonly imageExtensions = ['.jpg', '.jpeg', '.png'];

  readonly videoExtensions = ['.avi', '.webm', '.mp4', '.wmv', '.mov'];

  readonly audioExtensions = ['.mp3'];

  readonly documentExtensions = ['.pdf'];

  constructor() {}

  ngOnInit(): void {
    switch (this.type) {
      case 'image':
        this.acceptTypes = this.imageExtensions.join(',');
        break;
      case 'audio':
        this.acceptTypes = this.audioExtensions.join(',');
        break;
      case 'document':
        this.acceptTypes = this.documentExtensions.join(',');
        break;
      case 'video':
        this.acceptTypes = this.videoExtensions.join(',');
        break;
      default:
        this.acceptTypes = [
          ...this.imageExtensions,
          ...this.audioExtensions,
          ...this.documentExtensions,
          ...this.videoExtensions,
        ].join(',');
        break;
    }
  }

  getExtension(filename: string) {
    const i = filename.lastIndexOf('.');
    return filename.substring(i);
  }

  onSelectFile(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files as File[];
      for (const file of files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e: any) => {
          if (e.target && e.target.result) {
            const displayUrl = e.target.result as string;
            this.file = {
              description: file.name,
              filename: file.name,
              type: this.type,
              displayUrl,
              file,
              uploaded: false,
            };
            this.propagateTouch(true);
            this.propagateChange(this.file);
            this.onSelect.emit(this.file);
          }
        };
      }
    }
  }

  clear() {
    this.file = undefined;
    this.propagateChange(undefined);
    this.fileUploadInput!.nativeElement.value = '';
    this.onClear.emit();
  }

  writeValue(value: MediaFile): void {
    if (value) {
      this.file = value;
    } else {
      this.propagateChange(value);
    }
  }

  registerOnChange(fn: (_: any) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (_: any) => void) {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
