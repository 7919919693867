import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { School } from '../../../../types/school';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from '../../../../services/files.service';
import { SchoolService } from '../../../../services/school.service';
import { BucketFile } from '../../../../types/core';
import { FormControl, Validators } from '@angular/forms';
import { v4 as uuid } from 'uuid';

export type Document = {
  title: FormControl<string | null>;
  originName: string | null;
  document?: PreviewDocument;
};
export type PreviewDocument = {
  file?: Blob;
  displayUrl: string;
  filename: string;
};

@Component({
  selector: 'app-school-documents-form',
  templateUrl: './school-documents-form.component.html',
  styleUrls: ['./school-documents-form.component.scss'],
})
export class SchoolDocumentsFormComponent implements OnInit {
  id: string;
  school$: Observable<School>;
  school?: School;
  loading = false;
  readonly maxCountDoc = 6;

  readonly acceptTypes = ['.pdf', '.doc', '.docx'];

  removedDocuments: BucketFile[] = [];
  currentDocumentList: Document[] = [];
  newDocumentList: Document[] = [
    {
      title: new FormControl<string>('', [Validators.required]),
      originName: null,
    },
  ];

  constructor(
    route: ActivatedRoute,
    private schoolService: SchoolService,
    private fileService: FileService,
    private router: Router
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.school$ = schoolService.getById(this.id);
  }

  ngOnInit(): void {
    this.school$.subscribe(school => {
      this.school = school;
      if (school.documents) {
        this.currentDocumentList = school.documents.map(d => ({
          title: new FormControl<string>(d.title || '', [Validators.required]),
          originName: d.description || null,
          document: {
            displayUrl: d.url || '',
            coverImage: { displayUrl: d.coverImage?.url },
            filename: d.filename || '',
          },
        }));
      }
    });
  }

  isFormValid() {
    const isValid = (m: Document) => {
      return m.title.valid;
    };
    const validNewDoc = this.newDocumentList
      .filter(d => d.document)
      .every(isValid);
    const validCurrentDoc = this.currentDocumentList.every(isValid);
    return validCurrentDoc && validNewDoc;
  }

  getExtension(filename: string) {
    const i = filename.lastIndexOf('.');
    return filename.substring(i);
  }

  onSelectFile(event: Event, newDoc: Document) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      const files = target.files;
      for (const file of files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          if (e.target && e.target.result) {
            const displayUrl = e.target.result as string;
            newDoc.originName = file.name;
            newDoc.document = {
              displayUrl,
              file: file,
              filename: file.name,
            };
            this.addNewLine();
          }
        };
      }
    }
  }

  addNewLine() {
    const sumDoc =
      this.newDocumentList.length + this.currentDocumentList.length;
    const lastDoc = this.newDocumentList[this.newDocumentList.length - 1];
    if (this.maxCountDoc > sumDoc && !!lastDoc.document) {
      this.newDocumentList.push({
        title: new FormControl<string>('', [Validators.required]),
        originName: null,
      });
    }
  }

  removeNewDocument(index: number) {
    this.newDocumentList.splice(index, 1);
    this.addNewLine();
  }

  removeDocument(index: number) {
    this.currentDocumentList?.splice(index, 1);
    const files = this.school?.documents?.splice(index, 1);
    this.removedDocuments = [...this.removedDocuments, ...(files || [])];
    this.addNewLine();
  }

  getFileExtension(filename: string): string {
    const i = filename.lastIndexOf('.');
    return filename.substring(i);
  }

  async uploadDocumentFiles(newDocs: Document[]) {
    const uploadedFiles: BucketFile[] = [];

    for (const newDoc of newDocs) {
      if (newDoc.document?.file) {
        // Avoid file name collisions by using a random folder name in between
        const randomFolderName = uuid().substring(0, 6);
        const path = `/schools/${this.id}/documents/${randomFolderName}`;
        const bucketFile = await this.fileService.uploadAsync(
          path,
          newDoc.document.file,
          newDoc.originName || '',
          true
        );

        bucketFile.title = newDoc.title.value || '';
        bucketFile.description = newDoc.originName || '';

        uploadedFiles.push(bucketFile);
      }
    }

    return uploadedFiles;
  }

  async save() {
    this.loading = true;
    try {
      // upload new files
      const newDoc = await this.uploadDocumentFiles(this.newDocumentList);

      // delete all removed files
      await Promise.all(
        this.removedDocuments.map(async (file: BucketFile) => {
          if (file.coverImage) {
            this.fileService.remove(file.coverImage.path);
          }
          return this.fileService.remove(file.path);
        })
      );

      // update titles and descriptions of current documents
      this.school?.documents?.forEach((doc, i) => {
        doc.title = this.currentDocumentList[i].title.value || '';
        doc.description = this.currentDocumentList[i].originName || '';
      });

      const documents = [...(this.school?.documents || []), ...newDoc];

      this.schoolService.update(this.id, { documents }).subscribe(async () => {
        await this.router.navigate(['schools', this.id]);
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
    }
  }
}
