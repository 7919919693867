import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../../services/profile.service';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Profile } from '../../../../types/profile';
import { convertRichTextToPlainText } from 'src/app/core/richText';

@Component({
  selector: 'app-profile-about-form',
  templateUrl: './profile-about-form.component.html',
  styleUrls: ['./profile-about-form.component.scss'],
})
export class ProfileAboutFormComponent implements OnInit {
  id: string;
  profile$: Observable<Profile>;

  form = this.fb.group({
    about: ['', []],
  });

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.profile$ = profileService.getMe(false);
  }

  ngOnInit(): void {
    this.profile$.subscribe(profile => {
      this.form.setValue({
        about: profile.about_html || '',
      } as any);
    });
  }

  save() {
    const { about } = this.form.getRawValue();
    const payload = {
      about: convertRichTextToPlainText(about) || '',
      about_html: about || '',
    };

    this.profileService.update(payload).subscribe(async () => {
      await this.router.navigate(['me', this.id]);
    });
  }
}
