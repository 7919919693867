import { Injectable } from '@angular/core';
import { from, map, Observable } from 'rxjs';
import {
  Client,
  GeocodeResponseData,
} from '@googlemaps/google-maps-services-js';
import {
  Firestore,
  collection,
  query,
  where,
  collectionData,
} from '@angular/fire/firestore';
import { GeoLocation } from '../types/core';
import { Functions, httpsCallableData } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  private client: Client;
  private searchAddressFunc: (data: {
    query: string;
  }) => Observable<GeocodeResponseData>;

  constructor(
    private firestore: Firestore,
    functions: Functions
  ) {
    this.client = new Client({});
    this.searchAddressFunc = httpsCallableData(functions, 'searchaddress', {});
  }

  searchAddress(query: string): Observable<GeocodeResponseData> {
    return this.searchAddressFunc({ query });
  }

  getLocation(
    response: GeocodeResponseData
  ): Observable<GeoLocation | undefined> {
    const location: GeoLocation = {};

    const components = response?.results[0]?.address_components;
    for (const component of components) {
      // city
      if (component.types.some((t: string) => t === 'locality')) {
        location.city = component.long_name;
      }
      // canton
      if (
        component.types.some((t: string) => t === 'administrative_area_level_1')
      ) {
        location.canton = component.long_name;
      }
      // zipcode
      if (component.types.some((t: string) => t === 'postal_code')) {
        location.zipcode = component.long_name;
      }
    }

    location.geoloc = response?.results[0]?.geometry?.location;

    if (!location.zipcode) {
      return from(Promise.resolve(undefined));
    }

    const ref = query(
      collection(this.firestore, 'locations'),
      where('zipcodes', 'array-contains', location.zipcode)
    );
    return collectionData(ref).pipe(
      map(result => {
        const item = result[0] as any;
        location.municipality = item.municipality;
        return location;
      })
    );
  }
}
