import { Component, Input, OnInit } from '@angular/core';
import { School } from '../../../../types/school';

@Component({
  selector: 'app-school-media-card',
  templateUrl: './school-media-card.component.html',
  styleUrls: ['./school-media-card.component.scss'],
})
export class SchoolMediaCardComponent implements OnInit {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) isOwner!: boolean;

  activeIndex = 0;
  prevButtonDisabled = true;
  nextButtonDisabled = false;

  get showEmptyCard() {
    return !this.school.media || this.school.media.length < 1;
  }
  ngOnInit(): void {
    this.setButtonState();
  }

  getTitle() {
    if (this.school.media) {
      return this.school.media[this.activeIndex]?.title || '';
    }

    return '';
  }

  getDescription() {
    if (this.school.media) {
      return this.school.media[this.activeIndex]?.description || '';
    }

    return '';
  }

  prev() {
    this.activeIndex--;
    this.setButtonState();
  }

  next() {
    this.activeIndex++;
    this.setButtonState();
  }

  private setButtonState() {
    this.prevButtonDisabled = this.activeIndex < 1;
    this.nextButtonDisabled =
      this.school.media?.length === 0 ||
      this.activeIndex >= (this.school.media?.length || 0) - 1;
  }
}
