<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Zeitraum Beschäftigungsverhältnis</h1>

    <div class="flex flex-col">
      <mat-form-field appearance="fill">
        <mat-label>Von</mat-label>
        <input
          matInput
          [min]="minDate"
          [matDatepicker]="pickerFrom"
          formControlName="durationFrom" />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Bis</mat-label>
        <input
          matInput
          [min]="minDate"
          [matDatepicker]="pickerTo"
          formControlName="durationTo" />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>

      <div>
        <mat-checkbox color="primary" formControlName="isDurationStartNow">
          ab sofort
        </mat-checkbox>
      </div>
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/jobs', id]">Abbrechen</a>
      <app-action-button
        [loading]="loading"
        (onClick)="save()"
        [disabled]="!form.valid"
        text="Speichern"></app-action-button>
    </div>
  </div>
</form>
