<a
  [routerLink]="['/schools', activeSchool.id]"
  class="flex w-full h-[200px] md:h-[360px] rounded-xl md:rounded-2xl overflow-hidden relative">
  <div class="grow gradient">
    <div
      class="absolute text-white z-10 h-full w-full flex flex-col justify-between pt-4 md:pt-8 px-6">
      <div class="text-2xl md:text-3xl lg:text-4xl pb-2 font-bold">
        Neue Schulprofile
      </div>
      @for (school of schools; track school.id) {
        <div
          class="absolute bottom-3 md:bottom-6 overflow-hidden h-[40%] flex flex-col justify-end">
          @if (isActive(school)) {
            <div
              [@fadeText]="{ value: activeIndex, params: { speed: speed } }"
              class="flex flex-col justify-end text-3xl md:text-5xl lg:text-6xl font-bold h-[60%] md:h-[70%]">
              <div class="flex">
                <span class="line-clamp-2 md:pb-2">{{
                  activeSchool.name
                }}</span>
              </div>
            </div>
          }
        </div>
      }
    </div>
    @for (school of schools; track school.id) {
      @if (isActive(school)) {
        <img
          alt="School Background"
          [@fadeImage]="{ value: activeIndex, params: { speed: speed } }"
          [src]="getBackgroundUrl(activeSchool)"
          class="absolute h-full w-full select-none -z-20 object-cover" />
      }
    }
  </div>
</a>
