@if (renderElement) {
  <div class="container gap-2 hidden md:block pt-4 md:pt-8 pl-4 md:pl-16">
    @if (crumbs$ | async; as crumbs) {
      <div class="flex w-full">
        @for (crumb of crumbs; track crumb; let i = $index) {
          <div class="breadcrumbs items-end gap-2">
            <a class="crumb text-sm" [routerLink]="crumb.link">{{
              crumb.text
            }}</a>
            @if (showSeperator(i, crumbs)) {
              <mat-icon class="material-symbols-rounded crumb-icon">
                chevron_right
              </mat-icon>
            }
          </div>
        }
      </div>
    }
  </div>
}
