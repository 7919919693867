@if (application$ | async; as application) {
  <div class="grid grid-cols-12 gap-4">
    <div class="col-span-12 lg:col-span-6 lg:col-start-4 flex flex-col gap-8">
      <div class="flex flex-col gap-4">
        <h1 class="font-bold text-4xl">Detailansicht</h1>
        @if (isSchoolAdmin) {
          <div class="flex flex-col">
            @if (!application.isAnonymousUser) {
              <app-round-image
                class="cursor-pointer"
                [routerLink]="['/profile', application.userId]"
                [src]="getAvatar(application)"
                size="80"
                [title]="application.name"
                [description]="application.title"></app-round-image>
            }
            @if (application.isAnonymousUser) {
              <app-round-image
                class="cursor-pointer"
                [src]="getAvatar(application)"
                size="80"
                [title]="application.name"
                [description]="application.title"></app-round-image>
            }
            <div class="flex justify-end items-center gap-4 sm:gap-8">
              <div>
                <a
                  mat-button
                  color="primary"
                  [routerLink]="['/jobs', application.jobId]">
                  Stellenbeschreibung
                </a>
              </div>
              @if (application.status === 'PENDING') {
                <app-action-button
                  (onClick)="reject(application)"
                  customColor="#E6155D"
                  text="Absage schreiben"
                  [loading]="loading" />
              }
            </div>
          </div>
        }
        @if (!isSchoolAdmin) {
          <div class="flex flex-col">
            <app-round-image
              class="cursor-pointer"
              [routerLink]="['/schools', application.schoolId]"
              [src]="getSchoolAvatar(application)"
              size="80"
              [title]="application.schoolName"
              [description]="application.schoolTitle"
              [rounded]="false"></app-round-image>
            <div class="flex justify-end items-center gap-8">
              <div>
                <a
                  mat-button
                  color="primary"
                  [routerLink]="['/jobs', application.jobId]">
                  Stellenbeschreibung
                </a>
              </div>
              @if (application.status === 'PENDING') {
                <app-action-button
                  (onClick)="withdraw(application)"
                  text="Zurückziehen" />
              }
            </div>
          </div>
        }
      </div>
      <mat-divider></mat-divider>
      <div class="flex flex-col">
        <h4 class="font-bold text-2xl">Kontakt</h4>
        <div class="flex sm:flex-row flex-col justify-between gap-4">
          <div class="flex items-center gap-4">
            <mat-icon class="material-symbols-rounded">mail</mat-icon>
            <span class="text-base">{{ application.email }}</span>
          </div>
          <div class="flex items-center gap-4">
            <mat-icon class="material-symbols-rounded">call</mat-icon>
            <span class="text-base">{{ application.phone }}</span>
          </div>
          <div class="flex items-center gap-4">
            <mat-icon class="material-symbols-rounded">schedule</mat-icon>
            <span class="text-clip text-base">
              {{ getHumnizedTime(application) }}
            </span>
          </div>
        </div>
      </div>
      <div class="pt-8">
        <h4 class="font-bold text-2xl">Nachricht</h4>
        <div class="flex flex-col gap-4 flex-wrap">
          <div
            class="text-ellipsis whitespace-pre-line text-base w-full break-words">
            {{ application.message }}
          </div>
        </div>
      </div>
      <div class="pt-8">
        <h4 class="font-bold text-2xl">Anhänge</h4>
        <div class="flex flex-col gap-8">
          <div class="flex flex-col text-base">
            <strong class="font-bold">Lebenslauf</strong>
            <div class="flex justify-between items-center">
              <span class="w-[70%] break-words">
                {{ application.cv.description || application.cv.filename }}
              </span>
              <button
                (click)="download(application.cv)"
                mat-stroked-button
                color="primary">
                Anzeigen
              </button>
            </div>
          </div>
          @if (qualifications.length) {
            <div class="flex flex-col text-base">
              <strong class="font-bold">Diplome und Zertifikate</strong>
              <div class="flex flex-col gap-2">
                @for (
                  qualification of qualifications;
                  track qualification.url
                ) {
                  <div class="flex justify-between items-center">
                    <span class="w-[70%] break-words">
                      {{ qualification.description || qualification.filename }}
                    </span>
                    <button
                      (click)="download(qualification)"
                      mat-stroked-button
                      color="primary">
                      Anzeigen
                    </button>
                  </div>
                }
              </div>
            </div>
          }
          @if (application.additionalDocs?.length) {
            <div class="flex flex-col text-base">
              <strong class="font-bold">Arbeitszeugnisse und Weiteres</strong>
              <div class="flex flex-col gap-2">
                @for (
                  additionalDoc of application.additionalDocs;
                  track additionalDoc.url
                ) {
                  <div class="flex justify-between items-center">
                    <span class="w-[70%] break-words">
                      {{ additionalDoc.description || additionalDoc.filename }}
                    </span>
                    <button
                      (click)="download(additionalDoc)"
                      mat-stroked-button
                      color="primary">
                      Anzeigen
                    </button>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
}
