import { Component } from '@angular/core';
import { ProfileService } from '../../../services/profile.service';
import { EMPTY, Observable } from 'rxjs';
import { Profile } from '../../../types/profile';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { SettingService } from 'src/app/services/setting.service';

@Component({
  selector: 'app-settings-account',
  templateUrl: './settings-account.component.html',
  styleUrls: ['./settings-account.component.scss'],
})
export class SettingsAccountComponent {
  profile$: Observable<Profile> = EMPTY;
  userHasEmailAuth: boolean;

  constructor(
    profileServie: ProfileService,
    breadcrumbService: BreadcrumbService,
    settingService: SettingService
  ) {
    breadcrumbService.set([
      { text: 'Deine Einstellungen', link: 'settings' },
      { text: 'Nutzerkonto' },
    ]);

    this.profile$ = profileServie.getMe(true);
    this.userHasEmailAuth = settingService.userHasEmailAuthProvider();
  }
}
