<div class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="font-bold text-4xl">Impressionen</h1>
      <p class="text-base text-[#B5B6C1]">
        Hier kannst du
        <span class="font-bold">Bildimpressionen</span>
        hochladen oder auch euren
        <span class="font-bold">Schulhaussong</span>
        ! Die Impressionen werden in der Reihenfolge angezeigt, in der du sie
        rauflädst.
      </p>
      <div class="flex items-center">
        <mat-icon class="text-[#B5B6C1] material-symbols-rounded pr-8">
          lightbulb
        </mat-icon>
        <div class="text-base text-[#B5B6C1]">
          Gut zu wissen: Bildigsnetz vermittelt zur Unterstützung professionelle
          Foto- und Videoproduktionen im Bildungsbereich.
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-16">
      @for (media of currentMediaList; track media; let i = $index) {
        <div class="flex flex-col">
          <div class="pb-4 flex justify-between w-full">
            <div class="flex flex-col">
              @if (media.media?.type === 'audio') {
                <app-video-player
                  [cover]="media.media?.coverImage?.displayUrl"
                  [src]="media.media?.displayUrl!"
                  alt="Cover Image"
                  type="audio/mp3"></app-video-player>
              }
              @if (media.media?.type === 'image') {
                <img
                  [alt]="media.title.value"
                  class="w-96 h-auto rounded-xl"
                  [src]="media.media?.displayUrl" />
              }
            </div>
            <button class="self-end" mat-icon-button (click)="removeMedia(i)">
              <mat-icon class="material-symbols-rounded">delete</mat-icon>
            </button>
          </div>
          <mat-form-field class="w-full">
            <mat-label>Titel</mat-label>
            <input rows="5" type="text" matInput [formControl]="media.title" />
            <mat-error>Füge einen Titel hinzu.</mat-error>
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>Beschreibung</mat-label>
            <textarea
              rows="5"
              type="text"
              matInput
              [formControl]="media.description"></textarea>
            <mat-error>Füge einen Beschreibungstext hinzu.</mat-error>
          </mat-form-field>
        </div>
      }
    </div>

    <div class="flex flex-col gap-16">
      @for (media of newMediaList; track media; let i = $index) {
        <div class="flex flex-col">
          <input
            type="file"
            class="hidden"
            [accept]="acceptTypes"
            (change)="onSelectFile($event, media, false)"
            #fileUpload />
          @if (media.media?.file) {
            <div class="pb-4 flex justify-between w-full">
              <div class="flex flex-col">
                @if (media.media?.type === 'audio') {
                  <app-video-player
                    [cover]="media.media?.coverImage?.displayUrl"
                    [src]="media.media?.displayUrl!"
                    type="audio/mp3"></app-video-player>
                }
                @if (media.media?.type === 'image') {
                  <img
                    class="w-96 h-auto rounded-xl"
                    [src]="media.media?.displayUrl"
                    [alt]="media.title" />
                }
              </div>
              <button
                class="self-end"
                mat-icon-button
                (click)="removeNewMedia(i)">
                <mat-icon class="material-symbols-rounded">delete</mat-icon>
              </button>
            </div>
          }
          @if (media.media?.type === 'audio' && !media.media?.coverImage) {
            <div>
              <input
                type="file"
                class="hidden"
                [accept]="imageExtensions"
                (change)="onSelectFile($event, media, true)"
                #titleUpload />
              <mat-form-field
                class="w-full cursor-pointer"
                (click)="titleUpload.click()">
                <mat-label>Titelbild</mat-label>
                <input matInput readonly required />
                <button matSuffix mat-icon-button>
                  <mat-icon class="material-symbols-rounded">upload</mat-icon>
                </button>
              </mat-form-field>
            </div>
          }
          @if (!media.media?.file) {
            <mat-form-field
              class="w-full cursor-pointer"
              (click)="fileUpload.click()">
              <mat-label>Foto oder Audiodatei</mat-label>
              <input matInput readonly />
              <button matSuffix mat-icon-button>
                <mat-icon class="material-symbols-rounded">upload</mat-icon>
              </button>
            </mat-form-field>
          }
          <mat-form-field class="w-full">
            <mat-label>Titel</mat-label>
            <input rows="5" type="text" matInput [formControl]="media.title" />
            <mat-error>Füge einen Titel hinzu.</mat-error>
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>Beschreibung</mat-label>
            <textarea
              rows="5"
              type="text"
              matInput
              [formControl]="media.description"></textarea>
            <mat-error>Füge einen Beschreibungstext hinzu.</mat-error>
          </mat-form-field>
        </div>
      }
    </div>

    <div class="flex items-center pb-8">
      <mat-icon class="text-[#FFB81C] material-symbols-rounded pr-8">
        info
      </mat-icon>
      <div class="text-base text-[#B5B6C1]">
        Achte auch auf eine gute Qualität deiner Fotos und Audiodateien.
        Unterstützt werden die Formate JPG, JPEG, PNG für Fotos und MP3 für
        Audiodateien.
      </div>
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button [routerLink]="['/schools', id]" [disabled]="loading">
        Abbrechen
      </a>
      <app-action-button
        [disabled]="!isFormValid()"
        (onClick)="save()"
        [loading]="loading"
        text="Speichern"></app-action-button>
    </div>
  </div>
</div>
