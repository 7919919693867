<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Über uns</h1>

    <app-rich-text-field
      placeholderText="Was macht euch als Schule aus? Was sind die Schwerpunkte und Eigenheiten auf Gemeinde- und Schuleinheitsebene? Interessant könnten bspw. eure pädagogischen Schwerpunkte sein."
      formControlName="about"></app-rich-text-field>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/jobs', id]">Abbrechen</a>
      <app-action-button
        [loading]="loading"
        (onClick)="save()"
        [disabled]="!form.valid"
        text="Speichern"></app-action-button>
    </div>
  </div>
</form>
