<div class="tag">
  <div class="pt-1.5 pl-1.5">
    @if (chip) {
      <span class="tag__chip">
        <span>{{ chip }}</span>
      </span>
    }
  </div>
  <span class="tag__text">{{ content }}</span>
</div>
