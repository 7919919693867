import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { JobService } from '../../../services/job.service';
import { School } from '../../../types/school';
import { SchoolService } from '../../../services/school.service';

@Component({
  selector: 'app-job-create',
  templateUrl: './job-create.component.html',
  styleUrls: ['./job-create.component.scss'],
})
export class JobCreateComponent {
  schoolId?: string;
  isLoading = false;
  schools$: Observable<School[]> = EMPTY;
  schoolControl = new FormControl<string>('', [Validators.required]);
  isFullTimeJobControl = new FormControl<boolean>(true);
  page = 0;

  constructor(
    private router: Router,
    private jobService: JobService,
    route: ActivatedRoute,
    schoolService: SchoolService
  ) {
    route.paramMap.subscribe(params => {
      this.schoolId = params.get('id') as string;
      schoolService.getById(this.schoolId).subscribe(school => {
        const schoolIds: string[] = [school.id!];
        school.locations?.forEach(l => {
          schoolIds.push(l.id!);
        });
        this.schools$ = schoolService.getSchoolsByIds(schoolIds, true);
      });
    });
  }

  create() {
    this.isLoading = true;
    const schoolId = this.schoolControl.value!;
    const isFullTimeJob = !!this.isFullTimeJobControl.value;

    this.jobService.create({ schoolId, isFullTimeJob }).subscribe(job => {
      this.router.navigate(['jobs', job.id!]);
      this.isLoading = false;
    });
  }

  next() {
    this.page++;
  }

  prev() {
    this.page--;
  }

  isDisabled() {
    return !this.schoolControl.value || this.schoolControl.value.length < 0;
  }
}
