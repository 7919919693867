import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  Observable,
  ReplaySubject,
  startWith,
  switchMap,
} from 'rxjs';
import {
  SchoolPermission,
  SchoolPermissionRequest,
} from '../../../../types/permissions';
import { Hit } from '@algolia/client-search';
import { Profile } from '../../../../types/profile';
import { PermissionsService } from '../../../../services/permissions.service';
import { ProfileService } from '../../../../services/profile.service';
import { NotificationService } from '../../../../services/notification.service';
import { getPublicFileUrl } from '../../../../core/helpers';
import { SchoolLocation } from '../../../../types/school';
import { SchoolService } from '../../../../services/school.service';

type PermissionControl = {
  permissionId: string;
  userId: string;
  username: string;
  schoolIds: FormControl<string[]>;
  publicPhone: FormControl<string>;
  publicEmail: FormControl<string>;
  publicJobFunction: FormControl<string>;
};

@Component({
  selector: 'app-school-permissions-form',
  templateUrl: './school-permissions-form.component.html',
  styleUrls: ['./school-permissions-form.component.scss'],
})
export class SchoolPermissionsFormComponent {
  id?: string;
  schoolLocations: SchoolLocation[] = [];

  showLoadingSpinner = false;
  loading = false;
  refreshPermissions$ = new ReplaySubject(1);
  refreshPermissionRequests$ = new ReplaySubject(1);

  permissionControls: PermissionControl[] = [];

  searchControl = new FormControl<string | Profile>('');
  profiles$: Observable<Hit<Profile>[]> = EMPTY;

  permissions$: Observable<SchoolPermission> = EMPTY;
  permissions?: SchoolPermission;

  permissionRequests$: Observable<SchoolPermissionRequest[]> = EMPTY;
  permissionRequestsCount = 0;

  constructor(
    private notificationService: NotificationService,
    private permissionsService: PermissionsService,
    private profileService: ProfileService,
    private schoolService: SchoolService,
    private router: Router,
    route: ActivatedRoute
  ) {
    route.params.subscribe(params => {
      const id = params['id'];
      this.id = id;
      schoolService.getById(id).subscribe(school => {
        const l = school.locations || [];
        this.schoolLocations = [
          {
            id: school.id,
            name: school.name,
            isRoot: school.isRoot,
          },
          ...l,
        ];
      });
      this.permissions$ = this.refreshPermissions$.pipe(
        switchMap(() => {
          return this.permissionsService.getById(id);
        })
      );
      this.permissions$.subscribe(p => {
        this.permissions = p;
        this.permissionControls = p.users.map(
          u =>
            ({
              permissionId: p.id,
              username: u.username,
              userId: u.userId,
              schoolIds: new FormControl<string[]>(u.contact?.schoolIds || []),
              publicEmail: new FormControl<string>(
                u.contact?.publicEmail || ''
              ),
              publicPhone: new FormControl<string>(
                u.contact?.publicPhone || ''
              ),
              publicJobFunction: new FormControl<string>(
                u.contact?.publicJobFunction || ''
              ),
            }) as PermissionControl
        );
      });
      this.refreshPermissions$.next(null);

      this.permissionRequests$ = this.refreshPermissionRequests$.pipe(
        switchMap(() => {
          return this.permissionsService.getPermissionRequests(id);
        })
      );
      this.refreshPermissionRequests$.next(null);
      this.refreshPermissionRequests$.subscribe(() => {
        this.permissionRequests$.subscribe(
          r => (this.permissionRequestsCount = r.length)
        );
      });

      this.profiles$ = this.searchControl.valueChanges.pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        map(
          value => (typeof value === 'string' ? value : `${value?.name}`) || ''
        ),
        filter(query => query.length > 2),
        switchMap(value =>
          this.profileService.search(value, {
            hitsPerPage: 5,
            facetFilters: ['isPublic:true'],
          })
        ),
        map(result =>
          result.hits.filter(
            h => !this.permissions?.userIds.includes(h.objectID)
          )
        )
      );
    });
  }

  async revokePermission(permission: PermissionControl, permissionId: string) {
    this.showLoadingSpinner = true;
    this.permissionsService.revoke(permissionId, permission.userId).subscribe({
      next: () => {
        this.notificationService.success(`${permission.username} entfernt`);
        this.refreshPermissions$.next(null);
      },
      complete: () => {
        this.showLoadingSpinner = false;
      },
    });
  }

  async addPermission(profile: Hit<Profile>) {
    this.showLoadingSpinner = true;
    this.searchControl.setValue('');
    this.permissionsService.add(profile.objectID!, this.id!).subscribe({
      next: () => {
        this.notificationService.success(`${profile.name} hinzugefügt`);
        this.refreshPermissions$.next(null);
      },
      complete: () => {
        this.showLoadingSpinner = false;
      },
    });
  }

  async approve(request: SchoolPermissionRequest) {
    this.loading = true;
    this.permissionsService.update(request.id!, true).subscribe({
      next: () => {
        this.notificationService.success(`${request.username} angenommen`);
        this.refreshPermissions$.next(null);
        this.refreshPermissionRequests$.next(null);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  async decline(request: SchoolPermissionRequest) {
    this.showLoadingSpinner = true;
    this.permissionsService.update(request.id!, false).subscribe({
      next: () => {
        this.notificationService.success(`${request.username} abgelehnt`);
        this.refreshPermissionRequests$.next(null);
      },
      complete: () => {
        this.showLoadingSpinner = false;
      },
    });
  }

  displayFn(profile: Profile): string {
    return profile?.name;
  }

  getImageUrl(userId: string) {
    return getPublicFileUrl('profiles', userId, 'avatar');
  }

  save() {
    this.loading = true;
    const data: SchoolPermission = {
      id: this.id!,
      schoolId: this.id!,
      userIds: [],
      users: this.permissionControls.map(p => ({
        userId: p.userId,
        username: p.username,
        email: p.publicEmail.value,
        createdOn: new Date().toISOString(),
        contact: {
          schoolIds: p.schoolIds.value,
          publicEmail: p.publicEmail.value,
          publicPhone: p.publicPhone.value,
          publicJobFunction: p.publicJobFunction.value,
        },
        role: 'admin',
      })),
    };
    this.schoolService.updateContacts(this.id!, data).subscribe(_ => {
      this.loading = false;
      this.notificationService.success('Kontaktpersonen aktualisiert');
      this.router.navigate(['/schools', this.id]);
    });
  }
}
