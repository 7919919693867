import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-card',
  templateUrl: './empty-card.component.html',
  styleUrls: ['./empty-card.component.scss'],
})
export class EmptyCardComponent implements OnInit {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() description?: string;
  @Input() link?: string;
  @Input() fullWidthAlways?: boolean;

  outerGridItemClasses = '';

  ngOnInit(): void {
    this.outerGridItemClasses = `flex flex-col col-span-12${this.fullWidthAlways ? '' : ' lg:col-span-8 lg:col-start-3'}`;
  }
}
