<div class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="font-bold text-4xl">Profilbild</h1>
      <span class="text-base text-[#B5B6C1]">
        Kein professionelles Foto nötig! Verwende ein Bild, das zeigt, wer du
        bist.
      </span>
    </div>
    <div class="flex flex-col gap-8">
      <input
        type="file"
        class="hidden"
        [accept]="acceptTypes"
        (change)="fileChangeEvent($event)"
        #fileUpload />
      <div class="flex flex-col">
        <div class="flex justify-end w-full">
          <image-cropper
            alignImage="left"
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="1 / 1"
            format="jpeg"
            (imageCropped)="imageCropped($event)"
            [resizeToWidth]="600"
            [imageQuality]="100"
            [resizeToHeight]="1024"
            [roundCropper]="true"
            [onlyScaleDown]="true"></image-cropper>
        </div>
        @if (!imageChangedEvent) {
          <div class="flex justify-center">
            <img
              alt="Vorschau"
              class="rounded-full w-[220px] h-[220px]"
              [src]="getImageUrl()" />
          </div>
        }
      </div>
      <div class="flex gap-4">
        <button mat-stroked-button color="primary" (click)="fileUpload.click()">
          <mat-icon class="material-symbols-rounded">upload</mat-icon>
          Foto wählen
        </button>
        <button mat-stroked-button color="primary" (click)="clear()">
          <mat-icon class="material-symbols-rounded">delete</mat-icon>
          Löschen
        </button>
      </div>

      <div class="flex items-center">
        <mat-icon class="text-[#FFB81C] material-symbols-rounded pr-8">
          info
        </mat-icon>
        <div class="text-base text-[#B5B6C1]">
          Mitglieder müssen auf Bildigsnetz ihre echten Identitäten nutzen. Nimm
          also ein Foto von dir auf oder lade ein vorhandenes Foto von dir hoch.
          Mögliche Dateiformate: JPG, BMP, PNG (max. 20 MB) Empfohlene
          Auflösung: 1024 x 1024 px
        </div>
      </div>
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/me', id]">Abbrechen</a>
      <app-action-button
        [disabled]="!imageChangedEvent && !deleted"
        (onClick)="save()"
        text="Speichern" />
    </div>
  </div>
</div>
