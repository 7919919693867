<div
  class="h-full overflow-hidden pb-4"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="fetchNext()"
  [fromRoot]="true"
  infiniteScrollContainer="#scroll-area">
  <div class="grid grid-cols-12 gap-x-4">
    <mat-form-field class="col-span-9 sm:col-span-10 lg:col-span-6">
      <input
        [formControl]="controls.serach"
        matInput
        [placeholder]="placeholderOptions[placeholderIndex]" />
      <mat-icon class="material-symbols-rounded" matPrefix>search</mat-icon>
    </mat-form-field>

    <div class="flex justify-end col-start-12 md:col-start-11 col-end-13">
      <button
        class="mt-2.5 lg:w-24"
        color="primary"
        mat-stroked-button
        (click)="toggleFilters()">
        @if (!showFilters) {
          <mat-icon
            class="material-symbols-rounded ml-0 mr-0 md:mr-auto md:ml-auto"
            >tune</mat-icon
          >
        }
        @if (showFilters) {
          <mat-icon
            class="material-symbols-rounded ml-0 mr-0 md:mr-auto md:ml-auto"
            >close</mat-icon
          >
        }
        <span class="hidden lg:inline-block">Filter</span>
      </button>
    </div>

    @if (showFilters) {
      <div class="grid grid-cols-12 gap-x-4 col-span-12">
        <mat-form-field appearance="fill" class="col-span-12 lg:col-span-6">
          <mat-label>Berufsbezeichnungen</mat-label>
          <mat-select [formControl]="controls.jobFunctions" multiple>
            <mat-optgroup label="Pädagogische Funktionen">
              @for (function of jobFunctionsPedagogic; track $index) {
                <mat-option [value]="function">
                  {{ function }}
                </mat-option>
              }
            </mat-optgroup>
            <mat-optgroup label="Leitungsfunktionen">
              @for (function of jobFunctionsLeadership; track $index) {
                <mat-option [value]="function">
                  {{ function }}
                </mat-option>
              }
            </mat-optgroup>
            <mat-optgroup label="Weitere bildungsrelevante Funktionen">
              @for (function of jobFunctionsAlternative; track $index) {
                <mat-option [value]="function">
                  {{ function }}
                </mat-option>
              }
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
        <div class="col-span-12 lg:col-span-6">
          <div class="flex flex-col sm:flex-row">
            <mat-form-field
              appearance="fill"
              class="flex grow basis-0 bn-combined-field-left">
              <mat-label>Ort</mat-label>
              <input
                type="text"
                matInput
                [formControl]="controls.location"
                [matAutocomplete]="auto" />
              <mat-icon class="material-symbols-rounded" matPrefix>
                location_on
              </mat-icon>
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayLocationFn">
                @for (location of locations$ | async; track location.place_id) {
                  <mat-option
                    [value]="location"
                    (onSelectionChange)="setLocation(location)">
                    {{ location.formatted_address }}
                  </mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
            <div class="bn-combined-field-divider"></div>
            <mat-form-field
              appearance="fill"
              class="flex grow basis-0 bn-combined-field-right">
              <mat-label>Umkreis</mat-label>
              <mat-select [formControl]="controls.radius">
                <mat-option [value]="undefined">—</mat-option>
                @for (radius of possibleRadius; track $index) {
                  <mat-option [value]="radius * 1000">
                    {{ radius }} km
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field appearance="fill" class="col-span-12 lg:col-span-6">
          <mat-label>Beschäftigungsart</mat-label>
          <mat-select [formControl]="controls.isFulltimeJob">
            <mat-option [value]="null"> - </mat-option>
            <mat-option [value]="1"> Festanstellung </mat-option>
            <mat-option [value]="0"> Stellvertretung </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="col-span-12 lg:col-span-6">
          <div class="flex flex-col sm:flex-row">
            <mat-form-field
              appearance="fill"
              class="flex grow basis-0 bn-combined-field-left">
              <mat-label>Schulstufen</mat-label>
              <mat-select [formControl]="controls.levels" multiple>
                @for (level of schoolLevels; track $index) {
                  <mat-option [value]="level">
                    {{ level }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
            <div class="bn-combined-field-divider"></div>
            <mat-form-field
              appearance="fill"
              class="flex grow basis-0 bn-combined-field-right">
              <mat-label>Stufenspezifikationen</mat-label>
              <mat-select [formControl]="controls.levelDescriptions" multiple>
                @for (description of schoolLevelDescriptions; track $index) {
                  <mat-option [value]="description">
                    {{ description }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field appearance="fill" class="col-span-12 lg:col-span-6">
          <mat-label>Fächer</mat-label>
          <input
            type="text"
            aria-label="Fächer"
            matInput
            [formControl]="subjectControl"
            [matAutocomplete]="autoSubject" />
          <mat-autocomplete
            #autoSubject="matAutocomplete"
            (optionSelected)="onSelectSubject($event)">
            @for (subject of filteredSubjects$ | async; track subject) {
              <mat-option [value]="subject">
                {{ subject }}
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        <div class="col-span-12 lg:col-span-6">
          <div class="flex flex-col sm:flex-row">
            <mat-form-field
              appearance="fill"
              class="flex grow basis-0 bn-combined-field-left">
              <mat-label>Zeitraum von</mat-label>
              <input
                matInput
                [min]="minDate"
                [matDatepicker]="pickerFirstWorkDay"
                [formControl]="controls.startTime" />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="pickerFirstWorkDay"></mat-datepicker-toggle>
              <mat-datepicker #pickerFirstWorkDay></mat-datepicker>
            </mat-form-field>
            <div class="bn-combined-field-divider"></div>
            <mat-form-field
              appearance="fill"
              class="flex grow basis-0 bn-combined-field-right">
              <mat-label>Zeitraum bis</mat-label>
              <input
                matInput
                [matDatepicker]="pickerEndDate"
                [formControl]="controls.endTime" />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="pickerEndDate"></mat-datepicker-toggle>
              <mat-datepicker #pickerEndDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="col-span-12">
          @for (subject of selectedSubjects; track subject) {
            <mat-chip-row (removed)="removeSubject(subject)">
              {{ subject }}
              <button matChipRemove>
                <mat-icon class="material-symbols-rounded">cancel</mat-icon>
              </button>
            </mat-chip-row>
          }
        </div>
      </div>
    }

    @if (!showFilters) {
      <div class="col-span-12 flex flex-wrap gap-4 pb-4">
        @if (controls.startTime.value) {
          <mat-chip>
            Von: {{ controls.startTime.value | date: 'dd.MM.YYYY' }}
            <button
              matChipRemove
              (click)="
                removeFilter(
                  controls.startTime,
                  controls.startTime.value,
                  'startTime'
                )
              ">
              <mat-icon class="material-symbols-rounded">cancel</mat-icon>
            </button>
          </mat-chip>
        }
        @if (controls.endTime.value) {
          <mat-chip>
            Bis: {{ controls.endTime.value | date: 'dd.MM.YYYY' }}
            <button
              matChipRemove
              (click)="
                removeFilter(
                  controls.endTime,
                  controls.endTime.value,
                  'endTime'
                )
              ">
              <mat-icon class="material-symbols-rounded">cancel</mat-icon>
            </button>
          </mat-chip>
        }
        @if (controls.isFulltimeJob.value !== null) {
          <mat-chip>
            {{
              controls.isFulltimeJob.value
                ? 'Festanstellung'
                : 'Stellvertretung'
            }}
            <button
              matChipRemove
              (click)="
                removeFilter(
                  controls.isFulltimeJob,
                  controls.isFulltimeJob.value,
                  'isFullTimeJob'
                )
              ">
              <mat-icon class="material-symbols-rounded">cancel</mat-icon>
            </button>
          </mat-chip>
        }
        @if (controls.location.value) {
          <mat-chip>
            {{ selectedLocation?.formatted_address }}
            <button
              matChipRemove
              (click)="
                removeFilter(
                  controls.location,
                  controls.location.value,
                  'location'
                )
              ">
              <mat-icon class="material-symbols-rounded">cancel</mat-icon>
            </button>
          </mat-chip>
        }
        @if (controls.radius.value) {
          <mat-chip>
            {{ controls.radius.value / 1000 }} km
            <button
              matChipRemove
              (click)="
                removeFilter(controls.radius, controls.radius.value, 'radius')
              ">
              <mat-icon class="material-symbols-rounded">cancel</mat-icon>
            </button>
          </mat-chip>
        }
        @for (function of controls.jobFunctions.value; track function) {
          <mat-chip>
            {{ function }}
            <button
              matChipRemove
              (click)="
                removeFilter(controls.jobFunctions, function, 'jobFunctions')
              ">
              <mat-icon class="material-symbols-rounded">cancel</mat-icon>
            </button>
          </mat-chip>
        }
        @for (function of controls.levels.value; track function) {
          <mat-chip>
            {{ function }}
            <button
              matChipRemove
              (click)="removeFilter(controls.levels, function, 'levels')">
              <mat-icon class="material-symbols-rounded">cancel</mat-icon>
            </button>
          </mat-chip>
        }
        @for (subject of selectedSubjects; track subject) {
          <mat-chip>
            {{ subject }}
            <button matChipRemove (click)="removeSubject(subject)">
              <mat-icon class="material-symbols-rounded">cancel</mat-icon>
            </button>
          </mat-chip>
        }
      </div>
    }
  </div>

  <div class="grid grid-cols-12 gap-4">
    @for (job of jobs; track job.objectID; let s = $index) {
      <a
        class="cursor-pointer col-span-12 md:col-span-6 xl:col-span-4 3xl:col-span-3"
        [routerLink]="[job.objectID]"
        queryParamsHandling="merge"
        (click)="navigate($event, job, s)">
        <app-job-card [job]="job" />
      </a>
    }

    @if (jobs.length === 0) {
      <app-empty-box
        class="col-span-12"
        title="Nichts zu sehen?"
        description="Bildigsnetz wählt bei Stelleninseraten einen komplett neuen Ansatz. Bei uns können nur Schulen Stellen ausschreiben, die auch ein Profil angelegt haben.  So ist garantiert, dass du für jedes Inserat ein zugehöriges Schulprofil hast - für eine bessere Passung! Da es uns erst ganz neu gibt, musst du dich also noch einen Moment gedulden. Wir freuen uns wahnsinnig zu sehen, wie die Plattform in den nächsten Monaten immer mehr an Form und Leben gewinnt!"></app-empty-box>
    }
  </div>
</div>
