<div
  class="flex flex-col application-card border-[#202020] border rounded-3xl h-full w-full p-5">
  <div class="flex relative">
    <div class="border border-[#eaebef] rounded-3xl">
      <div
        class="h-20 w-20 rounded-3xl bg-center bg-no-repeat bg-contain"
        [ngStyle]="{
          'background-image':
            'url(' +
            getAvatarUrl() +
            '), url(/assets/defaults/school_avatar.svg)'
        }"></div>
    </div>
    @if (application.status === 'REJECTED') {
      <div
        class="flex items-center absolute top-0 text-[#E6155D] right-0 text-sm gap-2">
        <mat-icon class="material-symbols-rounded">info</mat-icon>
        <span class="pt-1">Absage</span>
      </div>
    }
  </div>
  <div class="flex flex-col">
    <div class="pt-8 pb-7">
      <app-tag [text]="getTagText()"></app-tag>
    </div>
    <div class="h-[6.2rem]">
      <span class="font-bold text-base mb-1 line-clamp-2 text-ellipsis">
        {{ application.schoolName }}
      </span>
      <span class="text-[0.875rem] mb-1 line-clamp-1 text-ellipsis">
        {{ application.schoolTitle }}
      </span>
      <span class="text-[0.875rem] bright-font line-clamp-1 text-ellipsis">
        {{ application.schoolAddress?.city || '' }}
      </span>
    </div>
    <div class="flex flex-col">
      <span class="line-clamp-1 text-ellipsis">{{ getJobType() }}</span>
      @if (application.isFullTimeJob) {
        <span class="line-clamp-1 text-ellipsis">
          {{ getActivityRange() }}
        </span>
      }
      @if (!application.isFullTimeJob) {
        <span class="line-clamp-1 text-ellipsis">
          {{ getTimeRange() }}
        </span>
      }
    </div>
    <div class="flex flex-col">
      <div class="pt-4 pb-4">
        <mat-divider></mat-divider>
      </div>
      <div>
        <a
          [routerLink]="['/applications', application.id]"
          class="flex justify-between cursor-pointer">
          <div
            class="flex self-end items-center gap-4 primary-color w-full hover:font-bold hover-bold-icon">
            <mat-icon class="material-symbols-rounded">
              arrow_right_alt
            </mat-icon>
            <span class="pt-1">Ansehen</span>
          </div>
        </a>
        @if (!application.archived) {
          <div>
            <div class="pt-4 pb-4">
              <mat-divider></mat-divider>
            </div>
            <a
              (click)="onArchive()"
              class="flex justify-between cursor-pointer">
              <div
                class="flex self-end items-center gap-4 primary-color w-full hover:font-bold hover-bold-icon">
                <mat-icon class="material-symbols-rounded">archive</mat-icon>
                <span class="pt-1">Archivieren</span>
              </div>
            </a>
          </div>
        }
      </div>
    </div>
  </div>
</div>
