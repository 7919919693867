<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-4 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Ausbildung</h1>

    <div class="flex flex-col gap-4">
      <div class="flex flex-col">
        <span class="font-bold text-base pb-4">Detailbezeichnung</span>
        <mat-form-field>
          <mat-label>Name der Ausbildung</mat-label>
          <input
            placeholder="z.B. Primary Education"
            matInput
            formControlName="course" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Abschluss</mat-label>
          <input
            placeholder="z.B. Bachelor of Arts"
            matInput
            formControlName="degree" />
        </mat-form-field>
      </div>

      <div class="flex flex-col">
        <span class="font-bold text-base pb-4">Schule/Hochschule</span>
        <mat-form-field>
          <mat-label>Name der Schule/Hochschule</mat-label>
          <input matInput formControlName="schoolName" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ort der Schule/Hochschule</mat-label>
          <input matInput formControlName="location" />
        </mat-form-field>
      </div>

      <div class="flex flex-col">
        <span class="font-bold text-base pb-4">Zeitraum</span>
        <mat-form-field appearance="fill">
          <mat-label>Startdatum</mat-label>
          <input
            matInput
            appYearMonthFormat
            [matDatepicker]="startDatePicker"
            formControlName="startDate" />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker
            #startDatePicker
            startView="multi-year"
            (monthSelected)="
              setMonthAndYear($event, startDatePicker, form.controls.startDate)
            "></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Enddatum</mat-label>
          <input
            matInput
            appYearMonthFormat
            [matDatepicker]="endDatePicker"
            formControlName="endDate" />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker
            #endDatePicker
            startView="multi-year"
            (monthSelected)="
              setMonthAndYear($event, endDatePicker, form.controls.endDate)
            "></mat-datepicker>
        </mat-form-field>

        <mat-checkbox formControlName="isStudying" color="primary">
          bis heute
        </mat-checkbox>
      </div>
    </div>

    <div class="flex justify-between">
      @if (showDeleteButton) {
        <button
          class="hidden md:block"
          mat-button
          color="warn"
          (click)="remove()">
          Löschen
        </button>
      }
      @if (showDeleteButton) {
        <mat-icon
          class="md:hidden material-symbols-rounded"
          mat-button
          color="warn"
          (click)="remove()">
          delete
        </mat-icon>
      }
      <div class="flex gap-8">
        <a mat-button color="primary" [routerLink]="['/me', id]">Abbrechen</a>
        <button
          mat-flat-button
          color="primary"
          [disabled]="form.invalid"
          (click)="save()">
          Speichern
        </button>
      </div>
    </div>
  </div>
</form>
