import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { EMPTY, Observable, ReplaySubject, map } from 'rxjs';

export type PublicToolbarConfig = {
  showLogin?: boolean;
  showBoth?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class PublicToolbarService {
  config$: Observable<PublicToolbarConfig> = EMPTY;

  private config: PublicToolbarConfig = {
    showLogin: false,
    showBoth: false,
  };

  private update$ = new ReplaySubject(1);

  constructor(router: Router) {
    router.events.subscribe(data => {
      if (data instanceof RoutesRecognized) {
        if (!data.state.root.firstChild?.data?.['breadcrumbs']) this.clear();
      }
    });

    this.config$ = this.update$.pipe(map(() => this.config));
  }

  set(config: PublicToolbarConfig) {
    this.config = config;
    this.update$.next(1);
  }

  clear() {
    this.config = {
      showLogin: false,
      showBoth: false,
    };
    this.update$.next(1);
  }
}
