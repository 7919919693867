import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from '../../../../services/profile.service';
import { Profile } from '../../../../types/profile';

@Component({
  selector: 'app-profile-preferences-form',
  templateUrl: './profile-preferences-form.component.html',
  styleUrls: ['./profile-preferences-form.component.scss'],
})
export class ProfilePreferencesFormComponent implements OnInit {
  id: string;
  profile$: Observable<Profile>;
  iconColor = 'secondary';

  preferenceControl = new FormControl<string>('');
  preferences: string[] = [];

  constructor(
    private profileService: ProfileService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.profile$ = profileService.getMe(false);
  }

  ngOnInit(): void {
    this.profile$.subscribe(profile => {
      this.preferences = profile.preferences || [];
    });

    this.preferenceControl.valueChanges.subscribe(value => {
      this.iconColor = value && value.length > 0 ? 'primary' : 'secondary';
    });
  }

  add() {
    const value = this.preferenceControl.value;
    if (value && !this.preferences.some(p => p === value)) {
      this.preferences.push(value);
    }
    this.preferenceControl.setValue('');
  }

  addNamed(name: string) {
    this.preferences.push(name);
  }

  remove(preference: string) {
    const i = this.preferences.findIndex(p => p === preference);
    this.preferences.splice(i, 1);
  }

  save() {
    const preferences = this.preferences;
    this.profileService.update({ preferences }).subscribe(_ => {
      this.router.navigate(['me', this.id]);
    });
  }
}
