import { Component } from '@angular/core';
import { School } from '../../../types/school';
import { SchoolService } from '../../../services/school.service';
import { Observable, map } from 'rxjs';
import { groupby } from '../../../core/helpers';

@Component({
  selector: 'app-schools-my',
  templateUrl: './schools-my.component.html',
  styleUrls: ['./schools-my.component.scss'],
})
export class SchoolsMyComponent {
  groupedSchools: Observable<School[][]>;

  constructor(schoolService: SchoolService) {
    this.groupedSchools = schoolService.getMy().pipe(
      map(schools => {
        const groups = groupby<School>(schools, 'rootId');
        return Object.keys(groups).map(i => groups[i]) as School[][];
      })
    );
  }

  getRootName(schoolGroup: School[]) {
    return schoolGroup.find(s => s.id === schoolGroup[0].rootId)?.name;
  }

  getNonPublishedSchools(schools: School[]) {
    return schools.filter(s => !s.isPublic);
  }
}
