<h4 class="font-bold text-2xl" mat-dialog-title>Bewerber absagen</h4>
<div mat-dialog-content>
  <form [formGroup]="form" class="flex flex-col w-full gap-8">
    <div class="text-base text-black">
      Übernehme diesen Vorschlag oder passe ihn individuell an.
    </div>
    <div>
      <mat-form-field class="w-full">
        <mat-label>Individuelle Nachricht</mat-label>
        <textarea
          rows="12"
          matInput
          formControlName="text"
          placeholder="Individuelle Nachricht"></textarea>
      </mat-form-field>
    </div>
  </form>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button
      mat-button
      [disabled]="form.controls.text.invalid"
      (click)="accept()"
      color="warn">
      Absagen
    </button>
  </div>
</div>
