import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SchoolService } from '../../../../services/school.service';
import { School } from '../../../../types/school';
import { convertRichTextToPlainText } from 'src/app/core/richText';

@Component({
  selector: 'app-school-weoffer-form',
  templateUrl: './school-weoffer-form.component.html',
  styleUrls: ['./school-weoffer-form.component.scss'],
})
export class SchoolWeofferFormComponent implements OnInit {
  id: string;
  school$: Observable<School>;

  form = this.fb.group({
    whatWeOffer: ['', []],
  });

  constructor(
    route: ActivatedRoute,
    private fb: FormBuilder,
    private schoolService: SchoolService,
    private router: Router
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.school$ = this.schoolService.getById(this.id);
  }

  ngOnInit(): void {
    this.school$.subscribe(school => {
      this.form.setValue({
        whatWeOffer: school.whatWeOffer_html || '',
      } as any);
    });
  }

  async save() {
    const { whatWeOffer } = this.form.getRawValue();
    const payload = {
      whatWeOffer: convertRichTextToPlainText(whatWeOffer) || '',
      whatWeOffer_html: whatWeOffer || '',
    };

    this.schoolService.update(this.id, payload).subscribe(async () => {
      await this.router.navigate(['schools', this.id]);
    });
  }
}
