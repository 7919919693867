import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent {
  @Input() text = '';
  @Input() color = 'primary';
  @Input() customColor?: string;
  @Input() flat = true;
  @Input() loading = false;
  @Input() disabled = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick = new EventEmitter<void>();

  triggerAction() {
    if (!this.loading && !this.disabled) {
      this.loading = true;
      this.onClick.emit();
    }
  }
}
