import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { Application } from '../../../types/application';
import { ApplicationService } from '../../../services/application.service';
import { BucketFile } from '../../../types/core';
import { Profile } from '../../../types/profile';
import { ProfileService } from '../../../services/profile.service';
import { FileService } from '../../../services/files.service';
import { PermissionsService } from '../../../services/permissions.service';
import { School } from '../../../types/school';
import { SchoolService } from '../../../services/school.service';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { formatDistanceToNow } from 'date-fns';
import { getPublicFileUrl } from '../../../core/helpers';
import { de } from 'date-fns/locale';
import { MatDialog } from '@angular/material/dialog';
import { RejectMessageDialogComponent } from '../reject-message-dialog/reject-message-dialog.component';

@Component({
  selector: 'app-application-detail',
  templateUrl: './application-detail.component.html',
  styleUrls: ['./application-detail.component.scss'],
})
export class ApplicationDetailComponent {
  id?: string | null;
  loading = false;
  isSchoolAdmin = false;
  qualifications: BucketFile[] = [];
  application$?: Observable<Application>;
  profile$?: Observable<Profile>;
  school$?: Observable<School>;
  currentUser?: Profile;
  profileUrl?: string;
  missingFields: string[] = [];

  constructor(
    route: ActivatedRoute,
    private applicationService: ApplicationService,
    private fileService: FileService,
    private router: Router,
    private dialog: MatDialog,
    profileService: ProfileService,
    permissionService: PermissionsService,
    schoolService: SchoolService,
    breadcrumbService: BreadcrumbService
  ) {
    route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.application$ = applicationService.getById(this.id!).pipe(
        tap(application => {
          this.isSchoolAdmin = permissionService.isOwner(
            application.schoolRootId
          );
          this.qualifications = [
            ...(application.diplomas || []),
            ...(application.certificates || []),
            ...(application.qualifications || []),
          ];

          if (this.isSchoolAdmin) {
            if (application.userId) {
              this.profile$ = profileService.getById(application.userId).pipe(
                tap(profile => {
                  this.profileUrl = `/profile/${profile.id}`;
                  breadcrumbService.set([
                    { text: 'Meine Schule', link: 'my-schools' },
                    {
                      text: application.schoolName || '',
                      link: `schools/${application.schoolId}/jobs`,
                    },
                    {
                      text: application.jobFunction || 'Inserat',
                      link: `schools/${application.schoolId}/jobs/${application.jobId}/applicants`,
                    },
                    { text: `Bewerbung: ${profile.name}` },
                  ]);
                })
              );
            }
          } else {
            this.school$ = schoolService.getById(application.schoolId).pipe(
              tap(school => {
                this.profileUrl = `/schools/${school.id}`;
                breadcrumbService.set([
                  { text: 'Meine Bewerbungen', link: 'applications/my' },
                  { text: application.schoolName || '' },
                ]);
              })
            );
          }
        }),
        tap(() => {
          profileService.getMe(true).subscribe(profile => {
            this.currentUser = profile;
          });
        })
      );
    });
  }

  getAvatar(application: Application) {
    return getPublicFileUrl('profiles', application.userId!, 'avatar');
  }

  getSchoolAvatar(application: Application) {
    return getPublicFileUrl('schools', application.schoolId!, 'avatar');
  }

  getHumnizedTime(application: Application) {
    if (application.createdOn) {
      const d = Date.parse(application.createdOn!);
      const str = formatDistanceToNow(d, { addSuffix: true, locale: de });
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return '';
  }

  async download(file: BucketFile) {
    const key = `applications/${this.id!}/${file.filename}`;
    await this.fileService.download(key, file.description);
  }

  showRejectedButton(application: Application) {
    return (
      application.status === 'PENDING' &&
      !!this.currentUser?.id &&
      this.currentUser?.id !== application.userId
    );
  }

  showWithdrawButton(application: Application) {
    return (
      application.status === 'PENDING' &&
      !!this.currentUser?.id &&
      this.currentUser?.id === application.userId
    );
  }

  showAttachements(application: Application) {
    return (
      application.status === 'PENDING' || application.status === 'ACCEPTED'
    );
  }

  withdraw(application: Application) {
    this.loading = true;
    this.applicationService
      .withdraw(application.id!)
      .subscribe(_ => this.router.navigate([`/applications/my`]));
  }

  reject(application: Application) {
    this.loading = true;
    this.dialog
      .open(RejectMessageDialogComponent, {
        maxWidth: 'calc(100vw - 16px)',
        width: '600px',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((response: { confirm: boolean; text: string }) => {
        if (response?.confirm) {
          this.loading = true;
          this.applicationService
            .reject(application.id!, response.text)
            .subscribe(_ => {
              this.router.navigate([
                `/schools/${application.schoolId}/applications`,
              ]);
            });
        } else {
          this.loading = false;
        }
      });
  }
}
