import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { JobService } from '../../../../services/job.service';
import { Job } from '../../../../types/job';
import {
  SchoolLevelDescriptions,
  SchoolLevels,
} from '../../../../types/school';

@Component({
  selector: 'app-job-school-levels-form',
  templateUrl: './job-school-levels-form.component.html',
  styleUrls: ['./job-school-levels-form.component.scss'],
})
export class JobSchoolLevelsFormComponent implements OnInit {
  id: string;
  job$: Observable<Job>;
  schoolLevels = SchoolLevels;
  schoolLevelDescriptions = SchoolLevelDescriptions;
  loading = false;

  form = this.fb.group({
    schoolLevels: [[], [Validators.required]],
    schoolLevelDescriptions: [[], []],
  });

  constructor(
    private fb: FormBuilder,
    private jobService: JobService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.job$ = jobService.getById(this.id);
  }

  ngOnInit() {
    this.job$.subscribe(job => {
      this.form.setValue({
        schoolLevels: job.schoolLevels || [],
        schoolLevelDescriptions: job.schoolLevelDescriptions || [],
      } as any);
    });
  }

  save() {
    this.loading = true;
    const jobPartial = this.form.value as Partial<Job>;
    this.jobService.update(this.id, jobPartial).subscribe(_ => {
      this.loading = false;
      this.router.navigate(['jobs', this.id]);
    });
  }
}
