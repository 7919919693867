import { inject } from '@angular/core';
import {
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { of, map, mergeMap } from 'rxjs';
import { ProfileService } from '../services/profile.service';

export const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);

export const redirectLoggedInToTarget = () => {
  const redirectAfterLoginJson = localStorage.getItem('redirectAfterLogin');
  if (!redirectAfterLoginJson) {
    return redirectLoggedInTo(['home']);
  }

  const target = JSON.parse(redirectAfterLoginJson) as { path: string };
  localStorage.removeItem('redirectAfterLogin');
  return redirectLoggedInTo(target.path || ['home']);
};

export const authGuard = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const profileService = inject(ProfileService);

  return profileService.isAuthorized().pipe(
    mergeMap(isLoggedIn => {
      if (!isLoggedIn) return of(router.createUrlTree(['/login']));
      return profileService.getMe(true).pipe(
        map(profile => {
          if (!profile.isComplete) {
            return router.createUrlTree(['/welcome']);
          }
          return true;
        })
      );
    })
  );
};

export const isCurrentUserAuthGuard = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const profileService = inject(ProfileService);

  return profileService.isAuthorized().pipe(
    mergeMap(isLoggedIn => {
      if (!isLoggedIn) return of(router.createUrlTree(['/login']));

      return profileService.getMe(true).pipe(
        map(profile => {
          if (!profile.isComplete) {
            return router.createUrlTree(['/welcome']);
          }

          if (profile.id !== route.paramMap.get('id')) {
            return router.createUrlTree(['/profile/', profile.id]);
          }

          return true;
        })
      );
    })
  );
};

export const redirectUnauthorizedToLogin = (route: ActivatedRouteSnapshot) => {
  const path = route.pathFromRoot
    .map(v => v.url.map(segment => segment.toString()).join('/'))
    .join('/');
  const params = route.queryParams;
  localStorage.setItem('redirectAfterLogin', JSON.stringify({ path, params }));
  return redirectUnauthorizedTo(['login']);
};
