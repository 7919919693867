import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-settings-password-reset-confirm-dialog',
  templateUrl: './settings-password-reset-confirm-dialog.component.html',
  styleUrls: ['./settings-password-reset-confirm-dialog.component.scss'],
})
export class SettingsPasswordResetConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SettingsPasswordResetConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public email: string
  ) {}
}
