import { Injectable } from '@angular/core';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { CommentRequest } from '../types/comment';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  private sendContactFunc: (data: {
    request: CommentRequest;
  }) => Observable<void>;

  constructor(functions: Functions) {
    this.sendContactFunc = httpsCallableData(functions, 'sendcontactmail', {});
  }

  send(request: CommentRequest) {
    return this.sendContactFunc({ request });
  }
}
