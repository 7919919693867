@if (!showEmptyCard) {
  <div class="grid grid-cols-12 md:gap-4">
    <div class="flex flex-col col-span-12 lg:col-span-8 lg:col-start-3">
      <div>
        <strong class="font-bold text-4xl md:text-5xl">Deine Aufgaben</strong>
        @if (isOwner && !job.isClosed) {
          <app-edit-button
            class="pl-4"
            routerLink="edit/tasks"></app-edit-button>
        }
      </div>
      <app-rich-text-view
        class="pt-4"
        [html]="job.yourTasks_html"
        tailwindCssLineClamp="line-clamp-6"></app-rich-text-view>
    </div>
  </div>
}

@if (showEmptyCard) {
  <app-empty-card
    title="Deine Aufgaben"
    subtitle="Das erwartet deine künftigen Mitarbeitenden."
    description="Gehe bei der Aufgabenbeschreibung ruhig ins Detail, da Bewerbende genau wissen möchten, was sie erwartet."
    link="edit/tasks"></app-empty-card>
}
