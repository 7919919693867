<div class="grid grid-cols-12">
  <div class="flex flex-wrap justify-between col-span-full">
    <div class="flex">
      @if (!showArchived) {
        <h1 class="font-bold text-[2.125rem]">Inserate</h1>
      }
      @if (showArchived) {
        <h1 class="font-bold text-[2.125rem]">Archiv</h1>
      }
    </div>
    <div class="flex w-full sm:w-auto gap-4 justify-end">
      @if (!showArchived) {
        <a
          routerLink="create"
          class="flex gap-2 border-none action-button"
          color="primary"
          mat-stroked-button>
          <mat-icon
            class="material-symbols-rounded ml-0 mr-0 md:mr-auto md:ml-auto">
            add
          </mat-icon>
          <span class="inline-block pr-2">Erstellen</span>
        </a>
      }
      @if (!showArchived) {
        <button
          (click)="toggleArchive()"
          class="flex gap-2"
          color="primary"
          mat-stroked-button>
          <mat-icon
            class="material-symbols-rounded ml-0 mr-0 md:mr-auto md:ml-auto">
            archive
          </mat-icon>
          <span class="inline-block pr-2">Archiv</span>
        </button>
      }
      @if (showArchived) {
        <button
          (click)="toggleArchive()"
          class="flex gap-2"
          color="primary"
          mat-stroked-button>
          <mat-icon
            class="material-symbols-rounded ml-0 mr-0 md:mr-auto md:ml-auto">
            work
          </mat-icon>
          <span class="inline-block pr-2">Inserate</span>
        </button>
      }
    </div>
  </div>
  @if (!showArchived) {
    <div class="col-span-12 pt-8">
      <div class="grid grid-cols-12 gap-4 pt-4">
        @for (job of jobsPublic$ | async; track job.id) {
          <app-job-card
            class="col-span-12 md:col-span-4"
            [navigation]="true"
            [job]="job"></app-job-card>
        }
        @if ((jobsPublic$ | async)?.length === 0) {
          <app-empty-box
            class="col-span-12"
            title="Nichts zu sehen?"
            [description]="this.noActiveJobsDesc"></app-empty-box>
        }
      </div>
    </div>
  }
  @if (showArchived) {
    <div class="col-span-12 pt-8">
      <div class="grid grid-cols-12 gap-4 pt-4">
        @for (job of jobsArchived$ | async; track job.id) {
          <app-job-card
            class="col-span-12 md:col-span-4"
            [navigation]="true"
            [job]="job"></app-job-card>
        }
        @if ((jobsArchived$ | async)?.length === 0) {
          <app-empty-box
            class="col-span-12"
            title="Nichts zu sehen?"
            [description]="this.noArchivedJobsDesc"></app-empty-box>
        }
      </div>
    </div>
  }
</div>

@if (!showArchived) {
  <div class="grid grid-flow-col pt-16">
    <div class="grid grid-cols-12">
      <div class="col-span-12">
        <h1 class="font-bold text-[2.125rem]">Entwürfe</h1>
      </div>
      <div class="col-span-12 pt-8">
        <div class="grid grid-cols-12 gap-4 pt-4">
          @if (hasDraftJobs) {
            @for (job of jobsNotPublic$ | async; track job.id) {
              <app-job-card
                class="col-span-12 md:col-span-4"
                [navigation]="true"
                [showApplicants]="false"
                [job]="job"></app-job-card>
            }
          }
          @if (!hasDraftJobs && (jobsNotPublic$ | async)?.length === 0) {
            <app-empty-box
              class="col-span-12"
              title="Nichts zu sehen?"
              [description]="this.noDraftJobsDesc"></app-empty-box>
          }
        </div>
      </div>
    </div>
  </div>
}
