import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { School } from '../../../types/school';
import { getPublicFileUrl } from '../../../core/helpers';
import {
  trigger,
  style,
  transition,
  animate,
  group,
} from '@angular/animations';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-welcome-school-card',
  templateUrl: './welcome-school-card.component.html',
  styleUrls: ['./welcome-school-card.component.scss'],
  animations: [
    trigger('fadeText', [
      transition(
        ':enter',
        [
          style({ transform: 'translateY(200%)' }),
          group([
            animate(
              '{{speed}}ms ease-in-out',
              style({ transform: 'translateY(0)' })
            ),
          ]),
        ],
        { params: { speed: 0 } }
      ),
      transition(
        ':leave',
        [
          group([
            animate(
              '{{speed}}ms ease-in-out',
              style({ transform: 'translateY(-200%)' })
            ),
          ]),
        ],
        {
          params: { speed: 0 },
        }
      ),
    ]),
    trigger('fadeImage', [
      transition(
        ':enter',
        [
          style({ transform: 'translateX(-100%)' }),
          group([
            animate(
              '{{speed}}ms ease-in-out',
              style({ transform: 'translateX(0)' })
            ),
          ]),
        ],
        { params: { speed: 0 } }
      ),
      transition(
        ':leave',
        [
          group([
            animate(
              '{{speed}}ms ease-in-out',
              style({ transform: 'translateX(100%)' })
            ),
          ]),
        ],
        {
          params: { speed: 0 },
        }
      ),
    ]),
  ],
})
export class WelcomeSchoolCardComponent implements OnInit, OnDestroy {
  @Input() schools: School[] = [];
  activeSchool!: School;
  activeIndex = 0;
  speed = 0;
  subscription?: Subscription;

  ngOnInit() {
    this.activeSchool = this.schools[this.activeIndex];
    // skip initial animation
    setTimeout(() => {
      this.speed = 750;
    }, 750);

    this.subscription = interval(3000).subscribe(() => {
      if (this.activeIndex === this.schools.length - 1) {
        this.activeIndex = 0;
      } else {
        this.activeIndex++;
      }
      this.activeSchool = this.schools[this.activeIndex];
    });
  }

  getBackgroundUrl(school: School) {
    if (school.backgroundImage) {
      return getPublicFileUrl('schools', school.id!, 'backgroundImage');
    }

    return '/assets/dashboard/school_animation_placeholder.webp';
  }

  isActive(school: School) {
    return school === this.activeSchool;
  }

  ngOnDestroy() {
    // necessary to reset animation on next visit
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.activeIndex = 0;
      this.activeSchool = this.schools[this.activeIndex];
    }
  }
}
