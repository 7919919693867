import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { JobService } from '../../../../services/job.service';
import { Job, Subjects } from '../../../../types/job';

@Component({
  selector: 'app-job-subjects-form',
  templateUrl: './job-subjects-form.component.html',
  styleUrls: ['./job-subjects-form.component.scss'],
})
export class JobSubjectsFormComponent implements OnInit {
  id: string;
  job$: Observable<Job>;
  subjects = Subjects;
  loading = false;

  form = this.fb.group({
    subjects: [[], []],
  });

  constructor(
    private fb: FormBuilder,
    private jobService: JobService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.job$ = jobService.getById(this.id);
  }

  ngOnInit(): void {
    this.job$.subscribe(job => {
      this.form.setValue({
        subjects: job.subjects || [],
      } as any);
    });
  }

  save() {
    this.loading = true;
    const jobPartial = this.form.value as Partial<Job>;
    this.jobService.update(this.id, jobPartial).subscribe(_ => {
      this.loading = false;
      this.router.navigate(['jobs', this.id]);
    });
  }
}
