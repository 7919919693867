import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['./secondary-button.component.scss'],
})
export class SecondaryButtonComponent {
  @Input() disabled = false;
  @Output() onClick = new EventEmitter();

  emitEvent() {
    if (this.disabled) return;
    this.onClick.emit();
  }
}
