import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubscriptionType } from 'src/app/types/subscription';

@Component({
  selector: 'app-ordering-address',
  templateUrl: './ordering-address.component.html',
  styleUrls: ['./ordering-address.component.scss'],
})
export class OrderingAddressComponent {
  @Input({ required: true }) form: FormGroup = new FormGroup({});
  @Input() subscriptionType: SubscriptionType = 'SCHOOL_S';
  @Output() next = new EventEmitter<void>();

  onNext() {
    this.next.emit();
  }
}
