<form [formGroup]="form" (ngSubmit)="save()" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Slogan</h1>

    <mat-form-field>
      <textarea matInput rows="12" formControlName="slogan"></textarea>
    </mat-form-field>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/me', id]">Abbrechen</a>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="!form.valid">
        Speichern
      </button>
    </div>
  </div>
</form>
