<div
  class="flex flex-col border-[1.5px] rounded-2xl h-full w-full p-5"
  [ngClass]="{
    'bg-[#E1EDFF]': job.isFullTimeJob,
    'border-[#E1EDFF]': job.isFullTimeJob,
    'bg-[#F5DFE1]': !job.isFullTimeJob,
    'border-[#F5DFE1]': !job.isFullTimeJob,
    'job-card': !navigation
  }">
  <div class="flex justify-between">
    <div class="rounded-xl">
      <div
        class="h-20 w-20 rounded-xl bg-center bg-no-repeat bg-contain"
        [ngStyle]="{
          'background-image':
            'url(' +
            getAvatarUrl() +
            '), url(/assets/defaults/school_avatar.svg)'
        }"></div>
    </div>
    <div class="flex flex-col items-end justify-between">
      <div
        class="h-10 w-10 rounded-full flex justify-center items-center"
        [ngClass]="job.isFullTimeJob ? 'bg-[#001A41]' : 'bg-[#3B0716]'">
        <mat-icon
          class="material-symbols-rounded"
          [ngClass]="job.isFullTimeJob ? 'text-[#ECF4FF]' : 'text-[#F5DFE1]'">
          {{ job.isFullTimeJob ? 'work' : 'work_history' }}
        </mat-icon>
      </div>
      @if (job.isPublic && !job.isClosed) {
        <span class="bright-font text-[12px]">
          <span class="primary-color">•</span>
          {{ getHumanizedTime() }}
        </span>
      }
    </div>
  </div>
  <div class="flex flex-col mt-5 gap-5">
    <div>
      <strong
        class="text-2xl"
        [ngClass]="job.isFullTimeJob ? 'text-[#001A41]' : 'text-[#3B0716]'">
        {{ job.function }}</strong
      >
      <span class="text-base bright-font line-clamp-1 text-ellipsis">
        {{ getTitle(job) }}
      </span>
    </div>
    <div class="text-base bright-font">
      <span class="font-bold line-clamp-1 text-ellipsis">{{
        getJobType()
      }}</span>
      <span class="line-clamp-1 text-ellipsis">
        {{ getWorkloadAndDurationText(job) }}
      </span>
    </div>
    <div class="text-base bright-font">
      <span class="font-bold line-clamp-1 text-ellipsis">
        Schulhaus {{ job.schoolName }}
      </span>
      <span class="line-clamp-1 text-ellipsis">
        {{ job.schoolAddress?.city || '' }}
      </span>
    </div>
  </div>

  @if (navigation) {
    <div class="flex flex-col">
      <div class="pt-4 pb-4">
        <mat-divider></mat-divider>
      </div>
      <a
        [routerLink]="['/jobs', job.id]"
        class="flex justify-between cursor-pointer primary-color">
        @if (!job.isClosed) {
          <div
            class="self-end flex items-center w-full gap-2 hover-bold-icon hover:font-bold">
            <mat-icon class="material-symbols-rounded">edit</mat-icon>
            <span class="pt-1">Bearbeiten</span>
          </div>
        }
        @if (job.isClosed) {
          <div
            class="self-end flex items-center w-full gap-2 hover-bold-icon hover:font-bold">
            <mat-icon class="material-symbols-rounded">visibility</mat-icon>
            <span class="pt-1">Ansehen</span>
          </div>
        }
      </a>
      @if (showApplicants) {
        <div class="pt-4 pb-4">
          <mat-divider></mat-divider>
        </div>
      }
      @if (showApplicants) {
        <a
          [routerLink]="[
            '/my-schools',
            job.schoolId,
            'jobs',
            job.id,
            'applicants'
          ]"
          class="flex justify-between cursor-pointer primary-color">
          <div
            class="self-end items-center w-full flex gap-2 hover-bold-icon hover:font-bold">
            <mat-icon class="material-symbols-rounded">mail</mat-icon>
            <div class="pt-1">
              <strong class="font-bold">{{ job.countApplications }}</strong>
              <span> Bewerbung</span>
              @if (job.countApplications !== 1) {
                <span>en</span>
              }
            </div>
          </div>
        </a>
      }
    </div>
  }
</div>
