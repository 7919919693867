<div class="flex flex-col gap-4">
  <div class="grid grid-cols-12" [ngClass]="{ disabled: disabled }">
    <div
      class="col-span-6 sm:col-span-6 md:col-span-4 flex items-center"
      [routerLink]="['/applications', application.id]">
      <app-round-image
        class="cursor-pointer"
        [disabled]="disabled"
        size="60"
        [src]="getAvatar(application)"
        [title]="application.name"
        [description]="application.title"></app-round-image>
    </div>
    <div class="col-span-4 md:col-span-2 hidden md:flex items-center gap-2">
      <mat-icon class="hidden sm:block material-symbols-rounded">
        schedule
      </mat-icon>
      <span class="text-clip">{{ getHumnizedTime(application) }}</span>
    </div>
    <div class="hidden md:flex col-span-2 items-center gap-2">
      <mat-icon class="material-symbols-rounded">call</mat-icon>
      <span>{{ application.phone }}</span>
    </div>
    <div class="col-span-6 md:col-span-2 flex justify-end items-center">
      <app-action-button
        (onClick)="onReject()"
        [loading]="loading"
        [disabled]="disabled"
        customColor="#E6155D"
        text="Absage schreiben" />
    </div>
  </div>
</div>
