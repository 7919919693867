<form [formGroup]="form" (ngSubmit)="save()" class="grid grid-cols-12 gap-4">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="font-bold text-4xl">Dein Suchagent</h1>
      <p class="text-base text-[#B5B6C1]">
        Mit deinem persönlichen Suchagenten wirst du benachrichtigt, sobald eine
        passende Stelle ausgeschrieben wird!
      </p>
    </div>

    <div class="flex flex-col">
      <div class="flex flex-col">
        <div class="flex justify-between items-center">
          <span class="text-base">E-Mail-Benachrichtung aktivieren</span>
          <mat-slide-toggle
            color="primary"
            [formControl]="form.controls.isEmailNotificationEnabled" />
        </div>
        @if (form.controls.isEmailNotificationEnabled.value) {
          <mat-form-field appearance="fill" class="pt-4">
            <mat-label>E-Mail</mat-label>
            <input
              type="text"
              aria-label="E-Mail"
              matInput
              [formControl]="form.controls.email" />
          </mat-form-field>
        }
      </div>

      @if (showSchoolSubscriptionForm()) {
        <div class="flex gap-x-4">
          <div class="flex flex-col sm:flex-row w-full">
            <mat-form-field
              appearance="fill"
              class="flex grow basis-0 bn-combined-field-left">
              <mat-label>Ort</mat-label>
              <input
                type="text"
                matInput
                [formControl]="form.controls.location"
                [matAutocomplete]="auto" />
              <mat-icon class="material-symbols-rounded" matPrefix>
                location_on
              </mat-icon>
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayLocationFn">
                @for (location of locations$ | async; track location) {
                  <mat-option
                    [value]="location"
                    (onSelectionChange)="setLocation(location)">
                    {{ location.formatted_address }}
                  </mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
            <div class="bn-combined-field-divider"></div>
            <mat-form-field
              appearance="fill"
              class="flex grow basis-0 bn-combined-field-right">
              <mat-label>Umkreis</mat-label>
              <mat-select [formControl]="form.controls.radius">
                <mat-option [value]="0">—</mat-option>
                @for (radius of possibleRadius; track radius) {
                  <mat-option [value]="radius * 1000">
                    {{ radius }} km
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field appearance="fill">
          <mat-label>Schulstufen</mat-label>
          <mat-select [formControl]="form.controls.levels" multiple>
            @for (level of schoolLevels; track $index) {
              <mat-option [value]="level">
                {{ level }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Beschäftigungsart</mat-label>
          <mat-select [formControl]="form.controls.employmentType">
            <mat-option value="both"> Alle Stellen </mat-option>
            <mat-option value="fulltime"> Nur Feststellen </mat-option>
            <mat-option value="substitute"> Nur Stellvertretungen </mat-option>
          </mat-select>
        </mat-form-field>
      }
    </div>

    <div class="flex justify-end gap-8">
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="!isValid()">
        Speichern
      </button>
    </div>
  </div>
</form>
