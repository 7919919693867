import { Component, Input, OnInit } from '@angular/core';
import { getPublicFileUrl } from '../../../../core/helpers';
import { School, SchoolLocation } from '../../../../types/school';
import { SchoolService } from '../../../../services/school.service';

@Component({
  selector: 'app-school-header',
  templateUrl: './school-header.component.html',
  styleUrls: ['./school-header.component.scss'],
})
export class SchoolHeaderComponent implements OnInit {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) isOwner!: boolean;

  hideAvatar = false;
  locations: SchoolLocation[] = [];

  constructor(private schoolService: SchoolService) {}

  ngOnInit(): void {
    if (this.school.locations && this.school.locations?.length > 0) {
      // add self to list
      this.locations = [
        {
          id: this.school.id,
          name: this.school.name,
          isRoot: this.school.isRoot,
          isPublic: this.school.isPublic,
        },
        ...this.school.locations,
      ];

      if (!this.isOwner) {
        this.locations = this.locations.filter(l => !!l.isPublic);
      }
    }

    this.hideAvatar = !this.school.avatar && !this.isOwner;
  }

  getAvatarUrl(school: School) {
    if (school.avatar && school.id) {
      return getPublicFileUrl('schools', school.id, 'avatar');
    }

    return 'assets/defaults/school_avatar.svg';
  }

  getBackgroundUrl(school: School) {
    if (school.backgroundImage && school.id) {
      return getPublicFileUrl('schools', school.id, 'backgroundImage');
    }

    return 'assets/defaults/background_school.jpeg';
  }

  getSchoolLevelsTitle(school: School) {
    return this.schoolService.getLevelsTitle(
      school.levels || [],
      school.levelDescriptions || []
    );
  }
}
