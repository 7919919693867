<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Antrittsdatum</h1>

    <mat-form-field appearance="fill">
      <mat-label>Datum</mat-label>
      <input
        matInput
        [matDatepicker]="pickerEndDate"
        formControlName="durationFrom" />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="pickerEndDate"></mat-datepicker-toggle>
      <mat-datepicker #pickerEndDate></mat-datepicker>
    </mat-form-field>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/jobs', id]">Abbrechen</a>
      <app-action-button
        [loading]="loading"
        (onClick)="save()"
        [disabled]="!form.valid"
        text="Speichern"></app-action-button>
    </div>
  </div>
</form>
