import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolService } from '../../../services/school.service';

@Component({
  selector: 'app-school-verification',
  templateUrl: './school-verification.component.html',
  styleUrls: ['./school-verification.component.scss'],
})
export class SchoolVerificationComponent implements OnInit {
  error = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private schoolService: SchoolService
  ) {}

  async ngOnInit(): Promise<void> {
    const code = this.route.snapshot.paramMap.get('code') as string;
    this.schoolService.create(code).subscribe({
      next: async () => {
        await this.router.navigate(['schools', code, 'onboarding']);
      },
      error: e => {
        this.error = true;
        console.error(e);
      },
    });
  }
}
