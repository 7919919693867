import { Component, Input } from '@angular/core';
import { Profile } from '../../../../types/profile';

@Component({
  selector: 'app-profile-preferences-card',
  templateUrl: './profile-preferences-card.component.html',
  styleUrls: ['./profile-preferences-card.component.scss'],
})
export class ProfilePreferencesCardComponent {
  @Input({ required: true }) profile!: Profile;
  @Input({ required: true }) isOwner!: boolean;

  get showEmptyCard() {
    return !this.profile.preferences || this.profile.preferences.length < 1;
  }
}
