<button
  [disabled]="disabled"
  [ngClass]="{ 'bg-color': !disabled }"
  class="bg-color w-full"
  mat-flat-button
  (click)="emitEvent()">
  <span [ngClass]="{ 'primary-color': !disabled }">
    <ng-content></ng-content>
  </span>
</button>
