@if (hasValue) {
  <div class="flex gap-4 items-center">
    <ng-content></ng-content>
    @if (editable) {
      <div class="w-fit">
        <app-edit-button [routerLink]="link"></app-edit-button>
      </div>
    }
  </div>
}

<div class="pt-1 pb-1">
  @if (!hasValue && editable) {
    <button
      [routerLink]="link"
      class="flex gap-2"
      color="primary"
      mat-stroked-button>
      @if (!!icon) {
        <mat-icon
          class="material-symbols-rounded ml-0 mr-0 md:mr-auto md:ml-auto">
          {{ icon }}
        </mat-icon>
      }
      <span class="inline-block" [ngClass]="{ 'pr-2': !!icon }">{{
        title
      }}</span>
    </button>
  }
</div>
