<div class="pt-10">
  <strong class="block font-bold text-4xl md:text-6xl mb-6">
    Gratuliere - es hat geklappt!
  </strong>

  <div class="flex flex-col gap-8 md:gap-11 text-base">
    @if (subscriptionType !== 'SCHOOL_CUSTOM') {
      <p>
        Deine Schule ist nun stolze Besitzerin eines Bildigsnetz-Probeabos und
        kann sämtliche Funktionen uneingeschränkt nutzen! Es fehlt nur noch ein
        Schritt, bevor du loslegen kannst.
      </p>
    }
    @if (subscriptionType === 'SCHOOL_CUSTOM') {
      <p>
        Du wirst bald von uns für ein individuelles Angebot kontaktiert. Wir
        freuen uns auf das Kennenlernen.
      </p>
    }
    <p class="border-solid rounded-3xl bg-[#ECF4FF] p-4 md:p-6">
      Wir haben dir zur Verifizierung einen Link an
      <strong>{{ emailAddress }}</strong> geschickt.<br />
      <strong
        >Klicke auf den Link in der Mail, um deine E-Mail-Adresse zu
        bestätigen{{ subscriptionType === 'SCHOOL_CUSTOM' ? '.' : '' }}</strong
      >
      {{
        subscriptionType !== 'SCHOOL_CUSTOM'
          ? 'und mit dem On-Boarding deiner Schule zu starten.'
          : ''
      }}
    </p>
    @if (subscriptionType === 'SCHOOL_CUSTOM') {
      <p>
        Herzliche Grüsse<br />
        Dein Bildungsnetz-Team
      </p>
    }
    @if (subscriptionType !== 'SCHOOL_CUSTOM') {
      <p class="text-sm text-[#B5B6C1]">
        * Nichts zu sehen? Schau auch in den Spam-Ordner. Und wenn du Gmail
        nutzt: Vielleicht ist die E-Mail unter "Werbung" oder "Soziale
        Netzwerke" gelandet.
      </p>
    }
  </div>
  <app-secondary-button
    class="block w-fit ml-auto mr-0 mt-12"
    (onClick)="onClose()"
    >Zurück zu Bildigsnetz</app-secondary-button
  >
</div>
