import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {
  EMPTY,
  Observable,
  ReplaySubject,
  from,
  map,
  startWith,
  switchMap,
} from 'rxjs';
import { ProfileService } from '../../../../services/profile.service';
import {
  EmploymentTypes,
  Profile,
  WorkExperience,
} from '../../../../types/profile';
import { v4 as uuid } from 'uuid';
import {
  SchoolLevelDescriptions,
  SchoolLevels,
} from '../../../../types/school';
import { Subjects } from '../../../../types/job';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-profile-work-experience-form',
  templateUrl: './profile-work-experience-form.component.html',
  styleUrls: ['./profile-work-experience-form.component.scss'],
})
export class ProfileWorkExperienceFormComponent implements OnInit {
  id: string;
  experienceId: string;
  profile$: Observable<Profile>;
  profile?: Profile;
  showDeleteButton = false;

  selectedSubjects: (typeof Subjects)[] = [];
  filteredSubjects$: Observable<(typeof Subjects)[number][]> = EMPTY;
  subjectControl = new FormControl<string>('');
  reloadFilteredSubjects$ = new ReplaySubject(1);

  subjects = Subjects;
  schoolLevels = SchoolLevels;
  schoolLevelDescriptions = SchoolLevelDescriptions;
  employmentTypes = EmploymentTypes;

  form = this.fb.group({
    jobTitle: ['', [Validators.required]],
    schoolLevels: [[], []],
    schoolLevelDescriptions: [[], []],
    employmentType: [null, [Validators.required]],
    employmnentRateInPercantage: ['', []],
    employer: ['', [Validators.required]],
    location: ['', [Validators.required]],
    startDate: ['', [Validators.required]],
    endDate: ['', [Validators.required]],
    isWorking: [false, []],
    description: ['', [Validators.maxLength(500)]],
  });

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.profile$ = profileService.getMe(false);
    this.experienceId = this.getId();
  }

  ngOnInit(): void {
    this.showDeleteButton =
      this.route.snapshot.paramMap.get('experienceId') !== 'new';
    this.profile$.subscribe(profile => {
      this.profile = profile;
      const experience = profile.workExperiences?.find(
        e => e.id === this.experienceId
      );

      this.form.setValue({
        jobTitle: experience?.jobTitle || '',
        schoolLevels: experience?.schoolLevels || [],
        schoolLevelDescriptions: experience?.schoolLevelDescriptions || [],
        employmentType: experience?.employmentType || '',
        employmnentRateInPercantage:
          experience?.employmnentRateInPercantage || '',
        employer: experience?.employer || '',
        location: experience?.location || '',
        startDate: experience?.startDate || null,
        endDate: experience?.endDate || null,
        isWorking: experience?.isWorking || false,
        description: experience?.description || '',
      } as any);

      if (experience?.isWorking) {
        this.form.controls.endDate.disable();
      }

      this.selectedSubjects = experience?.subjects || ([] as any[]);

      this.filteredSubjects$ = this.reloadFilteredSubjects$.pipe(
        switchMap(() =>
          from(
            this.subjectControl.valueChanges.pipe(
              startWith(''),
              map(value => this.filter(value || ''))
            )
          )
        )
      );
      this.reloadFilteredSubjects$.next(1);

      this.form.controls.isWorking.valueChanges.subscribe(isWorking => {
        if (isWorking) {
          this.form.controls.endDate.setValue(null);
          this.form.controls.endDate.disable();
        } else {
          this.form.controls.endDate.enable();
        }
      });
    });
  }

  onSelectSubject(event: MatAutocompleteSelectedEvent) {
    this.subjectControl.setValue('');
    if (!this.selectedSubjects.includes(event.option.value)) {
      this.selectedSubjects.push(event.option.value);
    }
    this.reloadFilteredSubjects$.next(1);
  }

  removeSubject(subject: any) {
    const i = this.selectedSubjects.indexOf(subject);
    this.selectedSubjects.splice(i, 1);
    this.reloadFilteredSubjects$.next(1);
  }

  getId() {
    const id = this.route.snapshot.paramMap.get('experienceId') || uuid();
    if (id === 'new') {
      return uuid();
    }

    return id;
  }

  remove() {
    const workExperiences = this.profile?.workExperiences || [];
    const i = workExperiences.findIndex(e => e.id === this.experienceId);
    if (i === -1) return;
    workExperiences.splice(i, 1);
    this.profileService.update({ workExperiences }).subscribe(_ => {
      this.router.navigate(['me', this.id]);
    });
  }

  save() {
    const { startDate, endDate, ...data } = this.form.value;
    const experience = {
      ...data,
      startDate: this.getDateStringOrNull(startDate),
      endDate: this.getDateStringOrNull(endDate),
      subjects: this.selectedSubjects,
      id: this.experienceId,
    } as any as WorkExperience;

    const workExperiences = this.profile?.workExperiences || [];
    const index = workExperiences.findIndex(e => e.id === this.experienceId);
    if (index !== -1) {
      workExperiences[index] = experience;
    } else {
      workExperiences.push(experience);
    }

    this.profileService.update({ workExperiences }).subscribe(_ => {
      this.router.navigate(['me', this.id]);
    });
  }

  setMonthAndYear(
    value: Date,
    datepicker: MatDatepicker<any>,
    control: FormControl
  ) {
    control.setValue(value);
    datepicker.close();
  }

  private getDateStringOrNull(d: string | Date | null | undefined) {
    if (typeof d === 'string') {
      return d;
    }

    if (!d) return null;

    return new Date(d.getFullYear(), d.getMonth(), 15).toISOString();
  }

  private filter(value: string) {
    const filterValue = value.toLowerCase();

    return this.subjects
      .filter(sub => !this.selectedSubjects.includes(sub as any))
      .filter(sub => sub.toLowerCase().includes(filterValue));
  }
}
