@if (!showEmptyCard) {
  <div class="flex flex-col">
    <div>
      <strong class="font-bold text-4xl md:text-5xl">Steckbrief</strong>
      @if (isOwner) {
        <app-edit-button class="pl-4" routerLink="edit/info"></app-edit-button>
      }
    </div>
    <div
      class="flex flex-row xl:justify-between flex-wrap xl:flex-nowrap text-sm md:text-base pt-[52px] pb-5 xl:px-8 bg-[#F1F5FB] rounded-xl mt-4">
      @if (school.isRoot) {
        <div
          class="flex-none basis-[50%] md:basis-[33%] xl:basis-auto w-[150px] xl:w-fit h-[100px] flex flex-col gap-2 items-center">
          <mat-icon
            class="material-symbols-rounded scale-[2]"
            [svgIcon]="getIcon(school.type!, 'address')"></mat-icon>
          @if (numberOfLocations === 1) {
            <div>
              <strong class="font-bold">1</strong>
              Standort
            </div>
          }
          @if (numberOfLocations > 1) {
            <div>
              <strong class="font-bold">
                {{ numberOfLocations }}
              </strong>
              Standorte
            </div>
          }
        </div>
      }
      @if (!!school.countEmployees) {
        <div
          class="flex-none basis-[50%] md:basis-[33%] xl:basis-auto w-[150px] xl:w-fit h-[100px] flex flex-col gap-2 items-center">
          <mat-icon
            class="scale-[1.8]"
            [svgIcon]="getIcon(school.type!, 'teachers')"></mat-icon>
          <div>
            <strong class="font-bold">{{ school.countEmployees }}</strong>
            Mitarbeitende
          </div>
        </div>
      }
      @if (!!school.countStudents) {
        <div
          class="flex-none basis-[50%] md:basis-[33%] xl:basis-auto w-[150px] xl:w-fit h-[100px] flex flex-col gap-2 items-center">
          <mat-icon
            class="scale-[2]"
            [svgIcon]="getIcon(school.type!, 'students')"></mat-icon>
          <div>
            <strong class="font-bold">{{ school.countStudents }}</strong>
            Lernende
          </div>
        </div>
      }
      @if (!!school.address?.street) {
        <a
          class="flex-none basis-[50%] md:basis-[33%] xl:basis-auto w-[150px] xl:w-fit h-[100px] flex flex-col gap-2 items-center"
          [href]="getGmapsLink()"
          target="_blank">
          <mat-icon
            class="scale-[1.9]"
            [svgIcon]="getIcon(school.type!, 'location')"></mat-icon>
          <div class="flex items-center">
            <div>
              {{ school.address?.city }}
            </div>
            <mat-icon class="material-symbols-rounded">open_in_new</mat-icon>
          </div>
        </a>
      }
      @if (school.isRoot && !!school.address?.website) {
        <a
          class="flex-none basis-[50%] md:basis-[33%] xl:basis-auto w-[150px] xl:w-fit h-[100px] flex flex-col gap-2 items-center"
          [href]="getSchoolWebsite()"
          target="_blank">
          <mat-icon
            class="scale-[1.7]"
            [svgIcon]="getIcon(school.type!, 'website')"></mat-icon>
          <div class="flex items-center">
            <div>Website</div>
            <mat-icon class="material-symbols-rounded">open_in_new</mat-icon>
          </div>
        </a>
      }
      @if (!!rootSchool?.cooperatingCollegeOfEducation) {
        <a
          class="flex-none basis-[50%] md:basis-[33%] xl:basis-auto w-[150px] xl:w-fit h-[100px] flex flex-col gap-2 items-center"
          [href]="
            getCollegeOfEducationWebsite(
              rootSchool?.cooperatingCollegeOfEducation!
            )
          "
          target="_blank">
          <mat-icon
            class="scale-[1.8]"
            [svgIcon]="getIcon(school.type!, 'coop-ph')"></mat-icon>
          <div>Kooperationsschule</div>
          <div class="flex items-center">
            <div>{{ rootSchool?.cooperatingCollegeOfEducation }}</div>
            <mat-icon class="material-symbols-rounded">open_in_new</mat-icon>
          </div>
        </a>
      }
    </div>
  </div>
}

@if (showEmptyCard && isOwner) {
  <app-empty-card
    title="Steckbrief"
    subtitle="Die wichtigsten Kennzahlen auf einen Blick."
    description="Hier trägst du die ungefähre Anzahl Lernender und Mitarbeitender ein,
  wie auch die Adresse."
    link="edit/info"
    [fullWidthAlways]="true"></app-empty-card>
}
