<div class="grid grid-cols-12">
  <div class="flex flex-col gap-4 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Sprachen</h1>

    <mat-form-field appearance="fill">
      <mat-label>Sprachen</mat-label>
      <input
        type="text"
        aria-label="Fächer"
        matInput
        [formControl]="languagesControl"
        [matAutocomplete]="auto" />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        (optionSelected)="onSelectLanguage($event)">
        @for (language of filteredLanguages$ | async; track language) {
          <mat-option [value]="language">
            {{ language }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    @for (language of selectedLanguages; track language) {
      <div class="flex flex-col">
        <div class="flex justify-between items-center">
          <span class="font-bold text-base pl-[18px]">
            {{ language.language }}
          </span>
          <button
            mat-icon-button
            aria-label="delete"
            (click)="remove(language)">
            <mat-icon class="material-symbols-rounded">delete</mat-icon>
          </button>
        </div>
        <mat-form-field appearance="fill">
          <mat-label>Kenntnisstand</mat-label>
          <mat-select [formControl]="language.levelControl">
            @for (proficiency of languageProficiencies; track $index) {
              <mat-option [value]="proficiency.value">
                {{ proficiency.name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/me', id]">Abbrechen</a>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="!isValid()"
        (click)="save()">
        Speichern
      </button>
    </div>
  </div>
</div>
