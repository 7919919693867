<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Pensum</h1>

    <div class="flex flex-col gap-4">
      <strong class="font-bold text-base">Pensum in Prozent</strong>
      <div class="flex gap-4">
        <mat-form-field class="grow">
          <mat-label>von %</mat-label>
          <input
            matInput
            type="number"
            min="0"
            max="100"
            formControlName="activityRangeInPercentageFrom" />
        </mat-form-field>

        <mat-form-field class="grow">
          <mat-label>bis %</mat-label>
          <input
            matInput
            type="number"
            min="0"
            max="100"
            formControlName="activityRangeInPercentageTo" />
        </mat-form-field>
      </div>

      <strong class="font-bold text-base">Pensum in Wochenlektionen</strong>
      <div class="flex gap-4">
        <mat-form-field class="grow">
          <mat-label>von</mat-label>
          <input
            matInput
            type="number"
            min="0"
            max="35"
            formControlName="activityRangeInHoursFrom" />
        </mat-form-field>

        <mat-form-field class="grow">
          <mat-label>bis</mat-label>
          <input
            matInput
            type="number"
            min="0"
            max="35"
            formControlName="activityRangeInHoursTo" />
        </mat-form-field>
      </div>
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/jobs', id]">Abbrechen</a>
      <app-action-button
        [loading]="loading"
        (onClick)="save()"
        [disabled]="!form.valid || !isAnyFormFieldSet()"
        text="Speichern"></app-action-button>
    </div>
  </div>
</form>
