@if (!showEmptyCard) {
  <div class="flex flex-col gap-4">
    <div clasS="flex gap-4">
      <strong class="font-bold text-4xl md:text-5xl">
        Schulentwicklung & -betrieb
      </strong>
      @if (isOwner) {
        <app-edit-button routerLink="edit/documents"></app-edit-button>
      }
    </div>
    <div class="grid grid-cols-12 gap-4">
      @for (document of school.documents; track document.url) {
        <a
          [href]="getUrl(document)"
          target="_blank"
          matRipple
          class="flex cursor-pointer gap-4 flex-row items-center rounded-2xl card-color p-8 col-span-12 xl:col-span-6">
          <mat-icon
            class="shrink-0 h-10 w-10 pl-[7px] pt-[5px] border border-solid border-black rounded-full material-symbols-rounded">
            description
          </mat-icon>
          <div class="text-xl">{{ document.title }}</div>
        </a>
      }
    </div>
  </div>
}

@if (showEmptyCard && isOwner) {
  <app-empty-card
    title="Schulentwicklung & -betrieb"
    subtitle="Orientierung geben."
    description="Wie sind die Sitzungen verteilt und wann finden sie statt? Welche schulischen Anlässe pflegt eine Schule? Was sind die Entwicklungsschwerpunkte des Schulprogramms? All das sind Dinge, welche Bewerbende brennend interessieren."
    link="edit/documents"
    [fullWidthAlways]="true"></app-empty-card>
}
