import { Component } from '@angular/core';
import { ProfileService } from '../../../services/profile.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Profile, ProfileContact } from '../../../types/profile';

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss'],
})
export class ProfileDetailComponent {
  isCurrentUser = false;
  profile$?: Observable<Profile>;
  contact?: ProfileContact | null;
  id?: string;

  constructor(profileService: ProfileService, route: ActivatedRoute) {
    route.paramMap.subscribe(params => {
      this.id = params.get('id') as string;
      this.profile$ = profileService.getById(this.id);
      profileService.getMe(true).subscribe(me => {
        this.isCurrentUser = me.id === this.id;
      });

      profileService.getContact(this.id).subscribe({
        next: contact => {
          this.contact = contact;
        },
        error: _ => {
          this.contact = null;
        },
      });
    });
  }
}
