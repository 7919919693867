import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Languages } from '../../../../types/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router, ActivatedRoute } from '@angular/router';
import {
  Observable,
  EMPTY,
  ReplaySubject,
  switchMap,
  from,
  startWith,
  map,
} from 'rxjs';
import { ProfileService } from '../../../../services/profile.service';
import { LanguageProficiency, Profile } from '../../../../types/profile';
import { LanguageSkill } from '../../../../types/profile';

type SelectedLanguage = {
  language: (typeof Languages)[number];
  levelControl: FormControl<LanguageProficiency | null>;
};

@Component({
  selector: 'app-profile-languages-form',
  templateUrl: './profile-languages-form.component.html',
  styleUrls: ['./profile-languages-form.component.scss'],
})
export class ProfileLanguagesFormComponent implements OnInit {
  id: string;
  profile$: Observable<Profile>;
  languages = Languages;

  selectedLanguages: SelectedLanguage[] = [];
  filteredLanguages$: Observable<(typeof Languages)[number][]> = EMPTY;
  languagesControl = new FormControl<string>('');
  reloadFilteredLanguages$ = new ReplaySubject(1);

  languageProficiencies = [
    { name: 'Grundkenntnisse', value: 0 },
    { name: 'Gute Kenntnisse', value: 1 },
    { name: 'Fliessend', value: 2 },
    { name: 'Verhandlungssicher', value: 3 },
    { name: 'Muttersprache', value: 4 },
  ];

  constructor(
    private profileService: ProfileService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.profile$ = profileService.getMe(false);
  }

  ngOnInit(): void {
    this.filteredLanguages$ = this.reloadFilteredLanguages$.pipe(
      switchMap(() =>
        from(
          this.languagesControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filter(value || ''))
          )
        )
      )
    );

    this.profile$.subscribe(profile => {
      this.selectedLanguages =
        profile.languages?.map(
          l =>
            ({
              language: l.language,
              levelControl: new FormControl<LanguageProficiency>(
                l.proficiency,
                [Validators.required]
              ),
            }) as SelectedLanguage
        ) || [];

      this.reloadFilteredLanguages$.next(1);
    });
  }

  onSelectLanguage(event: MatAutocompleteSelectedEvent) {
    this.languagesControl.setValue('');
    if (
      !this.selectedLanguages.map(l => l.language).includes(event.option.value)
    ) {
      this.selectedLanguages.push({
        language: event.option.value,
        levelControl: new FormControl<LanguageProficiency | null>(null, [
          Validators.required,
        ]),
      });
    }
    this.reloadFilteredLanguages$.next(1);
  }

  isValid() {
    return !this.selectedLanguages.some(l => !l.levelControl.valid);
  }

  remove(language: SelectedLanguage) {
    const i = this.selectedLanguages.indexOf(language);
    this.selectedLanguages.splice(i, 1);
    this.reloadFilteredLanguages$.next(1);
  }

  save() {
    const languages: LanguageSkill[] = this.selectedLanguages.map(l => ({
      language: l.language,
      proficiency: l.levelControl.value as LanguageProficiency,
    }));
    this.profileService.update({ languages }).subscribe(_ => {
      this.router.navigate(['me', this.id]);
    });
  }

  private filter(value: string) {
    const filterValue = value.toLowerCase();
    const selectedLanguages = this.selectedLanguages?.map(l => l.language);

    return this.languages
      .filter(lang => !selectedLanguages.includes(lang))
      .filter(lang => lang.toLowerCase().includes(filterValue));
  }
}
