<form [formGroup]="contactForm" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="font-bold text-4xl">Kontaktdaten</h1>
      <span class="text-base">
        Wir benötigen deine Kontaktdaten, damit Schulen dein Profil finden und
        dir passende Stellenangebote machen können.
      </span>
      <div class="flex items-center pt-8 pb-4">
        <mat-icon class="text-[#FFB81C] material-symbols-rounded pr-8">
          info
        </mat-icon>
        <div class="text-base text-[#B5B6C1]">
          Deine Kontaktdaten können nur Mitglieder sehen, denen du das in deinen
          Einstellungen zur Privatsphäre erlaubt hast.
        </div>
      </div>
    </div>

    <div class="flex flex-col">
      <mat-form-field>
        <mat-label>Postleitzahl</mat-label>
        <input matInput type="text" maxlength="4" formControlName="zipcode" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Ort</mat-label>
        <input matInput type="text" formControlName="city" />
      </mat-form-field>

      @if (userHasEmailAuth) {
        <mat-form-field class="pb-4">
          <mat-label>E-Mail</mat-label>
          <input matInput type="text" formControlName="email" />
          <mat-hint>
            <a routerLink="/settings/change-email">E-Mail Adresse ändern?</a>
          </mat-hint>
        </mat-form-field>
      }

      <mat-form-field>
        <mat-label>Telefonnummer</mat-label>
        <input matInput type="text" formControlName="phone" />
      </mat-form-field>
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" routerLink="/settings/account">Zurück</a>
      <app-action-button
        [disabled]="!contactForm.valid"
        (onClick)="save()"
        text="Speichern"></app-action-button>
    </div>
  </div>
</form>
