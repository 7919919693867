import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reject-message-dialog',
  templateUrl: './reject-message-dialog.component.html',
  styleUrls: ['./reject-message-dialog.component.scss'],
})
export class RejectMessageDialogComponent {
  readonly defaultText = `Guten Tag

Vielen herzlichen Dank für deine spannende Bewerbung. Wir haben uns sehr darüber gefreut. Leider müssen wir dir trotzdem absagen - auch wenn die Wahl nicht einfach war. Vielleicht klappt es bei einem nächsten Mal - zögere nicht, dich wieder zu bewerben.

Liebe Grüsse`;

  form = this.fb.group({
    text: [this.defaultText, [Validators.required]],
  });

  constructor(
    public dialogRef: MatDialogRef<RejectMessageDialogComponent>,
    private fb: FormBuilder
    // @Inject(MAT_DIALOG_DATA) public props: ConfirmDialogProps,
  ) {}

  accept() {
    const text = this.form.controls.text.getRawValue();
    if (text && text.length > 0) {
      this.dialogRef.close({ confirm: true, text });
    }
  }
}
