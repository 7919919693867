import { Component, Input } from '@angular/core';
import { LanguageProficiency, Profile } from '../../../../types/profile';

@Component({
  selector: 'app-profile-language-card',
  templateUrl: './profile-language-card.component.html',
  styleUrls: ['./profile-language-card.component.scss'],
})
export class ProfileLanguageCardComponent {
  @Input({ required: true }) profile!: Profile;
  @Input({ required: true }) isOwner!: boolean;

  get showEmptyCard() {
    return !this.profile.languages || this.profile.languages.length < 1;
  }

  readonly mapLanguageProficiency = {
    0: 'Grundkenntnisse',
    1: 'Gute Kenntnisse',
    2: 'Fliessend',
    3: 'Verhandlungssicher',
    4: 'Muttersprache',
  };

  getProficiency(proficiency: LanguageProficiency) {
    return this.mapLanguageProficiency[proficiency];
  }
}
