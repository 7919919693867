import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { getUnixTime } from 'date-fns';
import { Observable } from 'rxjs';
import { JobService } from '../../../../services/job.service';
import { Job } from '../../../../types/job';

@Component({
  selector: 'app-job-duration-form',
  templateUrl: './job-duration-form.component.html',
  styleUrls: ['./job-duration-form.component.scss'],
})
export class JobDurationFormComponent implements OnInit {
  id: string;
  job?: Job;
  job$: Observable<Job>;
  minDate = new Date();
  loading = false;

  form = this.fb.group({
    durationFrom: ['', [Validators.required]],
    durationTo: ['', [Validators.required]],
    isDurationStartNow: [false, []],
  });

  constructor(
    private fb: FormBuilder,
    private jobService: JobService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.job$ = jobService.getById(this.id);

    this.form.controls.isDurationStartNow.valueChanges.subscribe(selected => {
      if (selected) {
        this.form.controls.durationFrom.disable();
      } else {
        this.form.controls.durationFrom.enable();
      }
    });
  }

  ngOnInit(): void {
    this.job$.subscribe(job => {
      this.job = job;
      this.form.setValue({
        durationFrom: job.durationFrom || '',
        durationTo: job.durationTo || '',
        isDurationStartNow: !!job.isDurationStartNow,
      } as any);
    });
  }

  save() {
    this.loading = true;
    const { durationFrom, durationTo, isDurationStartNow } = this.form
      .value as any;
    const duration = {
      durationFrom: durationFrom || null,
      durationFromUnix: durationFrom
        ? getUnixTime(Date.parse(durationFrom))
        : null,
      durationTo: durationTo || null,
      durationToUnix: durationTo ? getUnixTime(Date.parse(durationTo)) : null,
      isDurationStartNow,
    };

    this.jobService
      .update(this.id, { ...duration } as Partial<Job>)
      .subscribe(_ => {
        this.loading = false;
        this.router.navigate(['jobs', this.id]);
      });
  }
}
