import { Component } from '@angular/core';
import { Auth, sendPasswordResetEmail } from '@angular/fire/auth';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PasswordForgetSuccessDialogComponent } from '../password-forget-success-dialog/password-forget-success-dialog.component';
import { PublicToolbarService } from '../../../services/public-toolbar.service';

@Component({
  selector: 'app-password-forget',
  templateUrl: './password-forget.component.html',
  styleUrls: ['./password-forget.component.scss'],
})
export class PasswordForgetComponent {
  loading = false;
  emailControl = new FormControl('', [Validators.email, Validators.required]);

  constructor(
    private auth: Auth,
    private dialog: MatDialog,
    toolbarService: PublicToolbarService
  ) {
    toolbarService.set({});
  }

  async resetPassword() {
    const email = this.emailControl.value;
    this.loading = true;
    if (email) {
      try {
        await sendPasswordResetEmail(this.auth, email);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
        this.emailControl.reset('');
        this.dialog.open(PasswordForgetSuccessDialogComponent, {});
      }
    }
  }
}
