<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="font-bold text-4xl">Name ändern</h1>
      <span class="text-base">
        Deine persönlichen Daten sind die Basis deines Bildigsnetz-Profils.
        Falls du hier Änderungen vornimmst, werden diese von uns geprüft und
        manuell freigeschaltet.
      </span>
      <a
        class="flex items-center pt-8 pb-4"
        routerLink="/settings/change-name/info">
        <mat-icon class="text-[#FFB81C] material-symbols-rounded pr-8">
          info
        </mat-icon>
        <div class="text-base text-[#B5B6C1]">Wichtige Hinweise zum Namen</div>
      </a>
    </div>

    <div class="flex flex-col">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input type="text" matInput formControlName="name" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Änderungsgrund</mat-label>
        <textarea
          type="text"
          matInput
          formControlName="reason"
          rows="6"></textarea>
      </mat-form-field>
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" routerLink="/settings/account">Zurück</a>
      <app-action-button
        [loading]="loading"
        [disabled]="!form.valid"
        (onClick)="change()"
        text="Speichern"></app-action-button>
    </div>
  </div>
</form>
