<div class="grid grid-cols-12 md:gap-4">
  <div class="block md:hidden pb-4 col-span-12">
    <a mat-button routerLink="/settings">
      <mat-icon class="material-symbols-rounded">keyboard_backspace</mat-icon>
    </a>
  </div>

  <h1 class="lg:col-start-3 lg:col-span-9 col-span-12 font-bold text-4xl">
    Zugangsdaten
  </h1>
  <div
    class="flex flex-col lg:col-start-3 lg:col-span-9 col-span-12 lg:flex-row gap-4">
    <a
      routerLink="/settings/change-contact"
      class="flex w-full flex-col rounded-3xl bg-[#F1F5FB] p-8">
      <div class="flex flex-col gap-2">
        <strong class="font-bold text-xl pt-8">Kontaktdaten</strong>
        <div class="flex justify-between">
          <span class="text-base">
            Wohnort{{ userHasEmailAuth ? ', E-Mail' : '' }} und Telefonnummer
          </span>
          <mat-icon class="material-symbols-rounded">trending_flat</mat-icon>
        </div>
      </div>
    </a>

    @if (userHasEmailAuth) {
      <a
        routerLink="/settings/change-password"
        class="flex w-full flex-col rounded-3xl bg-[#F1F5FB] p-8">
        <div class="flex flex-col gap-2">
          <strong class="font-bold text-xl pt-8">Passwort ändern</strong>
          <div class="flex justify-between">
            <span class="text-base">**********</span>
            <mat-icon class="material-symbols-rounded">trending_flat</mat-icon>
          </div>
        </div>
      </a>
    }
  </div>

  <h1 class="lg:col-start-3 lg:col-span-9 col-span-12 font-bold text-4xl pt-16">
    Persönliche Einstellungen
  </h1>
  <div
    class="flex flex-col lg:col-start-3 lg:col-span-9 col-span-12 lg:flex-row gap-4">
    <a
      routerLink="/settings/change-name"
      class="flex w-full flex-col rounded-3xl bg-[#F1F5FB] p-8">
      <div class="flex flex-col gap-2">
        <strong class="font-bold text-xl pt-8">Name ändern</strong>
        <div class="flex justify-between">
          <span class="text-base">{{ (profile$ | async)!.name }}</span>
          <mat-icon class="material-symbols-rounded">trending_flat</mat-icon>
        </div>
      </div>
    </a>

    <a
      routerLink="/settings/delete-account"
      class="flex w-full flex-col rounded-3xl bg-[#F1F5FB] p-8">
      <div class="flex flex-col gap-2">
        <strong class="font-bold text-xl pt-8">Profil löschen</strong>
        <div class="flex justify-between">
          <span class="text-base">
            Möchtest du Bildigsnetz nicht mehr nutzen?
          </span>
          <mat-icon class="material-symbols-rounded">trending_flat</mat-icon>
        </div>
      </div>
    </a>
  </div>
</div>
