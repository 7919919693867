import { BaseModel, BucketFile, GeoPoint, Languages } from './core';
import { Subjects } from './job';
import { SchoolLevelDescriptions, SchoolLevels } from './school';

export type Profile = BaseModel & {
  title: string;
  name: string;
  zipcode: string;
  city: string;
  canton?: string;
  municipality?: string;

  jobFunctions: ProfileJobFunction[];

  subjects: SubjectSkill[];
  languages: LanguageSkill[];

  about?: string;
  about_html?: string;

  slogan?: string;

  educations?: Education[];
  workExperiences?: WorkExperience[];

  preferences?: string[];
  certifications?: string[];

  avatar?: BucketFile | null;
  backgroundImage?: BucketFile | null;

  isComplete: boolean;
  isPublic: boolean;
  _geoloc?: GeoPoint | null;

  interestedInSubstituteJobs?: boolean;
  interestedInFulltimeJobs?: boolean;
};

export type ProfileAttachements = {
  cv: BucketFile;
  // TODO: Remove (-> obsolete)
  diplomas?: BucketFile[];
  // TODO: Remove (-> obsolete)
  certificates?: BucketFile[];
  qualifications?: BucketFile[];
  additionalDocs?: BucketFile[];
};

export type ProfileContact = {
  email: string;
  phone: string;
};

export type ProfilePreferences = {
  newsletterEmailOptIn: boolean;
  publicContactInformation: boolean;
  publicOnlineAccessible: boolean;
  analyticTrackingEnabled: boolean;
};

export type Education = {
  id: string;
  course: string;
  degree: string;
  schoolName: string;
  startDate: string | null;
  endDate: string | null;
  isStudying: boolean;
  location: string;
};

export type ProfileJobFunction = {
  name: string;
  jobLevels: (typeof SchoolLevels)[number][];
  jobLevelDescriptions: (typeof SchoolLevelDescriptions)[number][];
  inStudy: boolean;
};

export type SubjectSkill = {
  subject: (typeof Subjects)[number];
  schoolLevels: (typeof SchoolLevels)[number][];
};

export type LanguageSkill = {
  language: (typeof Languages)[number];
  proficiency: LanguageProficiency;
};

export enum LanguageProficiency {
  ELEMENTARY = 0,
  LIMITED_WOKRING = 1,
  PROFESSIONAL_WORKING = 2,
  FULL_PROFESSIONAL_WORKING = 3,
  NATIVE_OR_BILINGUAL = 4,
}

export type WorkExperience = {
  id: string;
  jobTitle: string;
  subjects?: (typeof Subjects)[number][];
  schoolLevels?: (typeof SchoolLevels)[number][];
  schoolLevelDescriptions?: (typeof SchoolLevelDescriptions)[number][];
  employmentType: (typeof EmploymentTypes)[number];
  employmnentRateInPercantage?: string; // Beschäftigungsgrad
  employer: string;
  location: string;
  startDate: string;
  isWorking: boolean;
  endDate?: string;
  description?: string;
};

export const EmploymentTypes = [
  'Festanstellung',
  'Stellvertretung',
  'Ausbildung',
] as const;
