<div mat-dialog-content>
  <div class="flex flex-col gap-4 text-base text-black">
    <h1 class="font-bold text-2xl">Du hast Post</h1>
    <span>
      Wenn deine E-Mail Adresse korrekt ist, solltest du eine E-Mail von uns
      erhalten.
    </span>
    <span>
      Klicke auf den Link in der E-Mail, um dein Passwort zurückzusetzen.
    </span>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close color="primary">Weiter</button>
</div>
