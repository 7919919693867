import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { getUnixTime } from 'date-fns';
import { Observable } from 'rxjs';
import { JobService } from '../../../../services/job.service';
import { Job } from '../../../../types/job';

@Component({
  selector: 'app-job-application-deadline-form',
  templateUrl: './job-application-deadline-form.component.html',
  styleUrls: ['./job-application-deadline-form.component.scss'],
})
export class JobApplicationDeadlineFormComponent implements OnInit {
  id: string;
  job?: Job;
  job$: Observable<Job>;
  minDate = new Date();
  loading = false;

  form = this.fb.group({
    applicationEndDate: ['', []],
  });

  constructor(
    private fb: FormBuilder,
    private jobService: JobService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.job$ = jobService.getById(this.id);
  }

  ngOnInit(): void {
    this.job$.subscribe(job => {
      this.job = job;
      this.form.setValue({
        applicationEndDate: job.applicationEndDate || '',
      } as any);
    });
  }

  save() {
    this.loading = true;
    const data = this.form.value;
    const duration = {
      applicationEndDate: data.applicationEndDate || null,
      applicationEndDateUnix: data.applicationEndDate
        ? getUnixTime(Date.parse(data.applicationEndDate))
        : null,
    };

    this.jobService
      .update(this.id, { ...duration } as Partial<Job>)
      .subscribe(_ => {
        this.router.navigate(['jobs', this.id]);
        this.loading = false;
      });
  }
}
