import { Injectable } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { from, map } from 'rxjs';
import { Statistic } from '../types/statistic';

@Injectable({
  providedIn: 'root',
})
export class StatisticService {
  constructor(private firestore: Firestore) {}

  get() {
    const docRef = doc(this.firestore, 'statistics/counts');
    return from(getDoc(docRef)).pipe(map(d => d.data() as Statistic));
  }
}
