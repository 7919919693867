<div class="grid grid-cols-12" [class.pt-16]="showTopPadding">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col gap-8">
      <h1 class="font-bold text-4xl">Was macht ein sicheres Passwort aus?</h1>
      <span class="text-base">
        Ein sicheres Passwort erfüllt
        <span class="font-bold">mindestens</span>
        folgende Anforderungen:
        <ul class="list-disc list-inside">
          <li>8 Zeichen lang</li>
          <li>1 Grossbuchstabe</li>
          <li>1 Kleinbuchstabe</li>
          <li>1 Zahl</li>
          <li>1 Sonderzeichen (z. B. *, %, §, ?)</li>
        </ul>
      </span>
      <span class="text-base">
        Es sollte keinesfalls deinen Namen, deine Telefonnummer oder deine
        E-Mail-Adresse enthalten.
      </span>
    </div>

    <div class="flex justify-end gap-8">
      <app-secondary-button [routerLink]="returnPath">
        Sicheres Passwort festlegen
      </app-secondary-button>
    </div>
  </div>
</div>
