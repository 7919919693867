import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { JobService } from '../../../../services/job.service';
import { Job } from '../../../../types/job';

@Component({
  selector: 'app-job-requirements-form',
  templateUrl: './job-requirements-form.component.html',
  styleUrls: ['./job-requirements-form.component.scss'],
})
export class JobRequirementsFormComponent implements OnInit {
  id: string;
  job$: Observable<Job>;
  loading = false;

  form = this.fb.group({
    baseYear: [false, []],
    diplom: [false, []],
    student: [false, []],
  });

  constructor(
    private fb: FormBuilder,
    private jobService: JobService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.job$ = jobService.getById(this.id);
  }

  ngOnInit(): void {
    this.job$.subscribe(job => {
      if (job.requirements) {
        this.form.setValue(job.requirements as any);
      }
    });
  }

  save() {
    this.loading = true;
    const jobPartial = { requirements: this.form.value } as Partial<Job>;
    this.jobService.update(this.id, jobPartial).subscribe(_ => {
      this.loading = false;
      this.router.navigate(['jobs', this.id]);
    });
  }
}
