import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {
  EMPTY,
  Observable,
  ReplaySubject,
  from,
  map,
  startWith,
  switchMap,
} from 'rxjs';
import { ProfileService } from '../../../../services/profile.service';
import { Profile, SubjectSkill } from '../../../../types/profile';
import { Subjects } from '../../../../types/job';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SchoolLevels } from '../../../../types/school';

type SelectedSubject = {
  subject: (typeof Subjects)[number];
  schoolLevelsControl: FormControl<(typeof SchoolLevels)[number][] | null>;
};

@Component({
  selector: 'app-profile-subjects-form',
  templateUrl: './profile-subjects-form.component.html',
  styleUrls: ['./profile-subjects-form.component.scss'],
})
export class ProfileSubjectsFormComponent implements OnInit {
  id: string;
  profile$: Observable<Profile>;
  subjects = Subjects;
  schoolLevels = SchoolLevels;

  selectedSubjects: SelectedSubject[] = [];
  filteredSubjects$: Observable<(typeof Subjects)[number][]> = EMPTY;
  subjectsControl = new FormControl<string>('');

  reloadFilteredSubjects$ = new ReplaySubject(1);

  constructor(
    private profileService: ProfileService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.profile$ = profileService.getMe(false);
  }

  ngOnInit(): void {
    this.filteredSubjects$ = this.reloadFilteredSubjects$.pipe(
      switchMap(() =>
        from(
          this.subjectsControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filter(value || ''))
          )
        )
      )
    );

    this.profile$.subscribe(profile => {
      this.selectedSubjects =
        profile.subjects?.map(
          s =>
            ({
              subject: s.subject,
              schoolLevelsControl: new FormControl<
                (typeof SchoolLevels)[number][] | null
              >(s.schoolLevels, [Validators.required]),
            }) as SelectedSubject
        ) || [];

      this.reloadFilteredSubjects$.next(1);
    });
  }

  onSelectSubject(event: MatAutocompleteSelectedEvent) {
    this.subjectsControl.setValue('');
    if (
      !this.selectedSubjects.map(s => s.subject).includes(event.option.value)
    ) {
      this.selectedSubjects = [
        {
          subject: event.option.value,
          schoolLevelsControl: new FormControl<
            (typeof SchoolLevels)[number][] | null
          >(null, [Validators.required]),
        },
        ...this.selectedSubjects,
      ];
    }
    this.reloadFilteredSubjects$.next(1);
  }

  remove(subject: SelectedSubject) {
    const i = this.selectedSubjects.indexOf(subject);
    this.selectedSubjects.splice(i, 1);
    this.reloadFilteredSubjects$.next(1);
  }

  isValid() {
    return !this.selectedSubjects.some(s => !s.schoolLevelsControl.valid);
  }

  save() {
    const subjects: SubjectSkill[] = this.selectedSubjects.map(s => ({
      subject: s.subject,
      schoolLevels: s.schoolLevelsControl
        .value as (typeof SchoolLevels)[number][],
    }));

    this.profileService.update({ subjects }).subscribe(_ => {
      this.router.navigate(['me', this.id]);
    });
  }

  private filter(value: string) {
    const filterValue = value.toLowerCase();
    const _selectedSubjects = this.selectedSubjects?.map(s => s.subject);

    return this.subjects
      .filter(subject => !_selectedSubjects.includes(subject))
      .filter(subject => subject.toLowerCase().includes(filterValue));
  }
}
