import { Component, EventEmitter, Output } from '@angular/core';
import { SubscriptionType } from 'src/app/types/subscription';

@Component({
  selector: 'app-order-process-products',
  templateUrl: './order-process-products.component.html',
  styleUrls: ['./order-process-products.component.scss'],
})
export class OrderProcessProductsComponent {
  @Output() selectOffer = new EventEmitter<SubscriptionType>();

  offers: {
    type: SubscriptionType;
    title: string;
    priceTotal?: number;
  }[] = [
    {
      type: 'SCHOOL_S',
      title: 'Unter 200 Lernende',
      priceTotal: 990,
    },
    {
      type: 'SCHOOL_L',
      title: 'Über 200 Lernende',
      priceTotal: 1290,
    },
    {
      type: 'SCHOOL_CUSTOM',
      title: 'Kantone und Gemeinden',
    },
  ];

  getBenefits() {
    const benefits: {
      title: string;
      description: string;
      priceText: string | null;
    }[] = [
      {
        title: 'Netzwerk',
        description:
          'Schule im Fokus der Bildigsnetz-Mitglieder, Aktives Personalrecruiting, Praktische Such- und Kontaktfunktionen',
        priceText: 'Inklusive',
      },
      {
        title: 'Schulprofil',
        description:
          'Sämtliche Standorte der Schuleinheit, Pädagogische Schwerpunkte, Video, Fotos, Audios, Texte',
        priceText: 'Inklusive',
      },
      {
        title: 'Stellenportal',
        description:
          'Übersichtliches Stellen- und Verwaltungstool, unbegrenzte Anzahl Stelleninserate schalten, Feststellen und Stellvertretungen, Verknüpfung der Inserate mit dem Schulprofil. Neue Massstäbe im Handling: Erleichternde Funktionen & ansprechende Darstellung.',
        priceText: 'Inklusive',
      },
      {
        title: 'Zugänge',
        description:
          'Einfach Administrationsrechte vergeben, unbeschränkte Anzahl Administratoren pro Schuleinheit.',
        priceText: 'Inklusive',
      },
    ];

    return benefits;
  }

  select(type: SubscriptionType) {
    this.selectOffer.emit(type);
  }
}
