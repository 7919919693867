import { convert } from 'html-to-text';

export function isRichTextFieldEmpty(
  value: string | undefined | null
): boolean {
  if (!value) {
    return true;
  }

  return !convert(value);
}

export function convertRichTextToPlainText(
  value: string | undefined | null
): string {
  return convert(value || '');
}
