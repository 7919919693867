<div class="grid grid-cols-12">
  <div class="flex flex-col gap-4 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Unterrichtsfächer</h1>

    <mat-form-field appearance="fill">
      <mat-label>Fächer</mat-label>
      <input
        type="text"
        aria-label="Fächer"
        matInput
        [formControl]="subjectsControl"
        [matAutocomplete]="auto" />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        (optionSelected)="onSelectSubject($event)">
        @for (subject of filteredSubjects$ | async; track subject) {
          <mat-option [value]="subject">
            {{ subject }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    @for (subject of selectedSubjects; track subject) {
      <div class="flex flex-col">
        <div class="flex justify-between items-center">
          <span class="font-bold text-base pl-[18px]">{{
            subject.subject
          }}</span>
          <button mat-icon-button aria-label="delete" (click)="remove(subject)">
            <mat-icon class="material-symbols-rounded">delete</mat-icon>
          </button>
        </div>
        <mat-form-field appearance="fill">
          <mat-label>Stufe</mat-label>
          <mat-select [formControl]="subject.schoolLevelsControl" multiple>
            @for (level of schoolLevels; track $index) {
              <mat-option [value]="level">
                {{ level }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/me', id]">Abbrechen</a>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="!isValid()"
        (click)="save()">
        Speichern
      </button>
    </div>
  </div>
</div>
