<div class="flex flex-col grow">
  <span class="self-center font-bold text-8xl pb-4 app-progress-bar-headline">
    {{ progress }} %
  </span>
  <div class="flex w-full h-4 items-center relative">
    <div
      [style.width.%]="progress"
      class="h-4 bg-[#d6ee9d] rounded-md absolute z-10"></div>
    <div class="h-2 bg-[#202020] rounded-md absolute w-full"></div>
  </div>
</div>
