import { Component, Input } from '@angular/core';
import { School } from '../../../../types/school';
import { isRichTextFieldEmpty } from 'src/app/core/richText';

@Component({
  selector: 'app-school-weoffer-card',
  templateUrl: './school-weoffer-card.component.html',
  styleUrls: ['./school-weoffer-card.component.scss'],
})
export class SchoolWeofferCardComponent {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) isOwner!: boolean;

  get showEmptyCard() {
    return isRichTextFieldEmpty(this.school.whatWeOffer_html);
  }
}
