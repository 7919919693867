import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Profile, ProfileJobFunction } from '../../../../types/profile';
import { getPublicFileUrl } from '../../../../core/helpers';
import { MediaMatcher } from '@angular/cdk/layout';
import { ProfileService } from '../../../../services/profile.service';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent implements OnInit {
  @Input({ required: true }) profile!: Profile;
  @Input({ required: true }) id!: string;

  jobFunctions: ProfileJobFunction[] = [];

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.jobFunctions = this.profile.jobFunctions || [];
  }

  getJobTitle(jobFunction: ProfileJobFunction) {
    return this.profileService.getJobTitle(jobFunction);
  }

  getImageUrl(profile: Profile) {
    if (profile.avatar) {
      return getPublicFileUrl('profiles', this.id, 'avatar');
    }

    return null;
  }
}
