<div class="grid grid-cols-12 pt-24">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="text-2xl md:text-4xl font-bold">Inserat archivieren</h1>
      <p class="text-base">
        Wir hoffen, du konntest die passende Fachperson für deine Organisation
        gewinnen! Bitte teile uns für die Statistik mit, ob dir dies gelungen
        ist und falls ja, über welchen Kanal.
      </p>
    </div>
    <div class="max-w-[1724px] wrapper w-full flex flex-col justify-between">
      <div>
        <mat-radio-group
          class="flex flex-col"
          color="primary"
          [formControl]="reasonControl">
          <mat-radio-button value="bildigsnetz">
            Auf Bildigsnetz
          </mat-radio-button>
          <mat-radio-button value="other">
            Auf einem anderen Kanal
          </mat-radio-button>
          <mat-radio-button value="nohiring">
            Niemanden eingestellt
          </mat-radio-button>
        </mat-radio-group>

        <div class="w-full flex justify-end pt-8 gap-4">
          <button mat-button mat-dialog-close>Abbrechen</button>
          <app-action-button
            [disabled]="reasonControl.invalid"
            (onClick)="close()"
            customColor="#E6155D"
            text="Archivieren"></app-action-button>
        </div>
      </div>
    </div>
  </div>
</div>
