import { Component, Input, OnInit } from '@angular/core';
import { Profile, SubjectSkill } from '../../../../types/profile';
import { groupby } from '../../../../core/helpers';
import { SchoolLevels } from '../../../../types/school';

@Component({
  selector: 'app-profile-qualification-card',
  templateUrl: './profile-qualification-card.component.html',
  styleUrls: ['./profile-qualification-card.component.scss'],
})
export class ProfileQualificationCardComponent implements OnInit {
  @Input({ required: true }) profile!: Profile;
  @Input({ required: true }) isOwner!: boolean;

  showEmptySubjects = false;
  showEmptyCertifications = false;
  subjectGroups?: SubjectSkill[][];

  getJobLevels(levels: (typeof SchoolLevels)[number][]) {
    if (
      levels.includes('Kindergarten') &&
      levels.includes('Unterstufe') &&
      levels.includes('Mittelstufe')
    ) {
      const l = levels.filter(
        l => l !== 'Kindergarten' && l !== 'Unterstufe' && l !== 'Mittelstufe'
      );
      const rest = l.length > 0 ? ` | ${l.join(' | ')}` : '';
      return `Kindergarten | Primarstufe${rest}`;
    }
    if (levels.includes('Unterstufe') && levels.includes('Mittelstufe')) {
      const l = levels.filter(l => l !== 'Unterstufe' && l !== 'Mittelstufe');
      const rest = l.length > 0 ? ` | ${l.join(' | ')}` : '';
      return `Primarstufe${rest}`;
    }
    if (levels.includes('Kindergarten') && levels.includes('Unterstufe')) {
      const l = levels.filter(l => l !== 'Kindergarten' && l !== 'Unterstufe');
      const rest = l.length > 0 ? ` | ${l.join(' | ')}` : '';
      return `KUST${rest}`;
    }

    return levels.join(' | ');
  }

  ngOnInit(): void {
    this.showEmptyCertifications =
      !this.profile.certifications || this.profile.certifications.length < 1;
    this.showEmptySubjects =
      !this.profile.subjects || this.profile.subjects.length < 1;

    const groups = this.profile.subjects
      ? groupby(this.profile.subjects, 'schoolLevels')
      : undefined;
    if (groups) {
      this.subjectGroups = Object.keys(groups).map(i => groups[i]);
    }
  }
}
