@if (!showEmptyCard) {
  <div class="flex flex-col">
    <div>
      <strong class="font-bold text-4xl md:text-5xl">Über uns</strong>
      @if (isOwner) {
        <app-edit-button class="pl-4" routerLink="edit/about"></app-edit-button>
      }
    </div>
    <app-rich-text-view
      class="pt-4"
      [html]="school.about_html"
      tailwindCssLineClamp="line-clamp-[17]"></app-rich-text-view>
  </div>
}
@if (showEmptyCard && isOwner) {
  <app-empty-card
    title="Über uns"
    [subtitle]="subtitle"
    [description]="description"
    link="edit/about"
    [fullWidthAlways]="true"></app-empty-card>
}
