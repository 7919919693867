import { Component, OnInit } from '@angular/core';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  Storage,
} from '@angular/fire/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import { BucketFile } from '../../../../types/core';
import { School } from '../../../../types/school';
import { SchoolService } from '../../../../services/school.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

type PreviewMedia = { file?: Blob; displayUrl?: string };

@Component({
  selector: 'app-school-background-form',
  templateUrl: './school-background-form.component.html',
  styleUrls: ['./school-background-form.component.scss'],
})
export class SchoolBackgroundFormComponent implements OnInit {
  id: string;
  school$: Observable<School>;

  media: PreviewMedia = {};

  deleted = false;
  imageChangedEvent?: any;
  croppedImage?: Blob | undefined | null;

  readonly acceptTypes = ['.jpg', '.jpeg', '.png'];

  constructor(
    private storage: Storage,
    private schoolService: SchoolService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.school$ = schoolService.getById(this.id);
  }

  ngOnInit(): void {
    this.school$.subscribe(school => {
      this.media = {
        displayUrl: school.backgroundImage?.url || '',
      };
    });
  }

  async upload(file: Blob): Promise<BucketFile> {
    const filename = `backgroundImage`;
    const path = `/schools/${this.id}/${filename}`;
    const storageRef = ref(this.storage, path);

    // upload file
    await uploadBytes(storageRef, file);
    const publicUrl = await getDownloadURL(storageRef);

    return {
      path,
      url: publicUrl,
      mimetype: file.type || '',
    };
  }

  clear() {
    this.deleted = true;
    this.media = {};
  }

  async remove() {
    const path = `/schools/${this.id}/backgroundImage`;
    const storageRef = ref(this.storage, path);
    await deleteObject(storageRef);
  }

  async save() {
    let obs: Observable<void> = EMPTY;
    if (this.croppedImage) {
      let t = parseInt(localStorage.getItem('TICK') || '0');
      t = t + 1;
      localStorage.setItem('TICK', t.toString());
      const backgroundImage = await this.upload(this.croppedImage);
      obs = this.schoolService.update(this.id, { backgroundImage });
    } else if (!this.media.file) {
      await this.remove();
      obs = this.schoolService.update(this.id, { backgroundImage: null });
    }

    obs.subscribe(_ => {
      this.router.navigate(['schools', this.id]);
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob;
  }

  getImageUrl() {
    return this.media.displayUrl
      ? this.media.displayUrl
      : 'assets/defaults/background_school.jpeg';
  }
}
