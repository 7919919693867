import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type ConfirmDialogProps = {
  text: string;
  confirmText?: string;
  cancelText?: string;
  onlyAcceptButton?: boolean;
  buttonColor?: string;
};

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public props: ConfirmDialogProps
  ) {}

  accept() {
    this.dialogRef.close(true);
  }
}
