import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { SubscriptionType } from 'src/app/types/subscription';

@Component({
  selector: 'app-order-process-ordering',
  templateUrl: './order-process-ordering.component.html',
  styleUrls: ['./order-process-ordering.component.scss'],
})
export class OrderProcessOrderingComponent implements OnInit {
  @Input() subscriptionType: SubscriptionType = 'SCHOOL_S';
  @Output() closeDialog = new EventEmitter<void>();

  @ViewChild('stepper') private stepper: MatStepper | undefined;

  addressForm = this.fb.group({
    organization: ['', [Validators.required]],
    name: ['', Validators.required],
    street: ['', Validators.required],
    streetNumber: ['', Validators.required],
    zipcode: ['', Validators.required],
    city: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });

  overviewForm = this.fb.group({
    acceptTos: [false, Validators.requiredTrue],
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (this.subscriptionType === 'SCHOOL_CUSTOM') {
      // ToS are not necessary for custom offer (Kantone und Gemeinden)
      this.overviewForm.patchValue({
        acceptTos: true,
      });
    }
  }

  getAddressData() {
    return {
      organization: this.addressForm.value.organization!,
      name: this.addressForm.value.name!,
      street: this.addressForm.value.street!,
      streetNumber: this.addressForm.value.streetNumber!,
      zipcode: this.addressForm.value.zipcode!,
      city: this.addressForm.value.city!,
      email: this.addressForm.value.email!,
    };
  }

  onNext() {
    this.stepper?.next();
  }

  getCurrentStep() {
    return this.stepper?.selectedIndex;
  }

  onPrev() {
    this.stepper?.previous();
  }

  onCloseDialog() {
    this.closeDialog.emit();
  }
}
