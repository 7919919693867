<form [formGroup]="form" class="grid grid-cols-12 pt-16 min-h-screen">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col gap-2">
      <h1 class="font-bold text-4xl leading-snug">
        Erhalte Zugang zur Community mit vielen Vorteilen, indem du dich
        kostenlos registrierst.
      </h1>
      <p class="text-[#B5B6C1] text-base">
        Voraussetzung: Du bist im Bildungskontext tätig - bspw. in der Schule,
        an einer PH, in der Verwaltung, Betreuung oder Politik.
      </p>
    </div>
    <div class="flex flex-col">
      <mat-form-field>
        <mat-label>E-Mail</mat-label>
        <input type="email" matInput formControlName="email" />
        @if (form.get('email')?.hasError('email')) {
          <mat-error> E-Mail Adresse ist ungültig. </mat-error>
        }
        @if (form.get('email')?.hasError('required')) {
          <mat-error> E-Mail Adresse ist erforderlich. </mat-error>
        }
      </mat-form-field>

      <app-password-fields
        [parentForm]="form"
        helpPageUrl="/signup/secure-password"
        type="signup"></app-password-fields>

      <mat-checkbox color="primary" formControlName="acceptNewsletter">
        Newsletter zustimmen
      </mat-checkbox>
      <span class="pt-4">
        Durch das Registrieren stimmst du den
        <a [href]="baseUrl + '/agb'" class="hover:underline primary-color">
          AGB </a
        >, der
        <a [href]="baseUrl + '/cookie'" class="hover:underline primary-color"
          >Cookie-Richtlinie</a
        >
        und der
        <a
          [href]="baseUrl + '/datenschutz'"
          class="hover:underline primary-color">
          Datenschutzerklärung
        </a>
        zu.
      </span>
    </div>

    @if (signupError) {
      <mat-error>
        {{ signupError }}
      </mat-error>
    }

    <div class="flex w-full pb-2">
      <app-action-button
        class="w-full"
        [loading]="loading"
        (onClick)="signupWithEmail()"
        [disabled]="!form.valid"
        text="Registrieren" />
    </div>

    <mat-divider />

    <div class="flex flex-col gap-8 items-center pt-2">
      <div class="flex flex-col lg:flex-row gap-4 items-center w-full">
        <button
          (click)="signupWithEdulog()"
          class="cursor-pointer text-white hover:bg-[#144472] bg-[#002654] rounded-full border border-solid border-black flex w-full items-center h-10 justify-center gap-4">
          <mat-icon class="scale-125" svgIcon="custom_auth_edulog"></mat-icon>
          <span class="pt-1 font-bold tracking-wide"
            >Registrieren mit Edulog</span
          >
        </button>
        <button
          (click)="signupWithGoogle()"
          class="cursor-pointer hover:bg-[#f6f5f8] rounded-full border border-solid border-black flex w-full items-center h-10 justify-center gap-4">
          <mat-icon class="scale-125" svgIcon="custom_auth_google"></mat-icon>
          <span class="pt-1 font-bold tracking-wide"
            >Registrieren mit Google</span
          >
        </button>
      </div>
    </div>
  </div>
</form>
