import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

// Sentry.init({
//   environment: environment.name,
//   dsn: environment.sentry.dsn,
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: ["localhost", "https://stage.app.bildigsnetz.ch", "https://app.bildigsnetz.ch", "https://firestore.googleapis.com", "https://europe-west3-bildigsnetz-stage.cloudfunctions.net", "https://firebasestorage.googleapis.com/"],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: environment.production ? 0.1 : 1,
//   // Session Replay
//   replaysSessionSampleRate: environment.production ? 0.01 : 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
