<div [formGroup]="parentForm">
  <div formGroupName="passwordFields" class="flex flex-col col-span-12">
    <a class="flex items-center pb-4 pl-2" [routerLink]="helpPageUrl">
      <mat-icon class="text-[#FFB81C] material-symbols-rounded pr-8">
        info
      </mat-icon>
      <div class="text-base text-[#B5B6C1]">
        Was macht ein sicheres Passwort aus?
      </div>
    </a>
    <mat-form-field>
      <mat-label>{{ type === 'change-pw' ? 'Neues' : '' }} Passwort</mat-label>
      <input type="password" matInput formControlName="password" />
      @if (form.get('password')?.hasError('required')) {
        <mat-error> Bitte gib dein Passwort ein. </mat-error>
      }
      @if (form.get('password')?.hasError('passwordStrengthError')) {
        <mat-error> Passwort zu schwach. Siehe Info oben. </mat-error>
      }
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        {{ type === 'change-pw' ? 'Neues' : '' }} Passwort wiederholen
      </mat-label>
      <input type="password" matInput formControlName="passwordRepetition" />
      @if (form.get('passwordRepetition')?.hasError('required')) {
        <mat-error> Bitte gib dein Passwort erneut ein. </mat-error>
      }
      @if (form.get('passwordRepetition')?.hasError('passwordMatch')) {
        <mat-error>
          Das Password stimmt nicht mit dem ersten überein.
        </mat-error>
      }
    </mat-form-field>
  </div>
</div>
