import { Component, Input } from '@angular/core';
import { School } from '../../../../types/school';
import { isRichTextFieldEmpty } from 'src/app/core/richText';

@Component({
  selector: 'app-school-focus-card',
  templateUrl: './school-focus-card.component.html',
  styleUrls: ['./school-focus-card.component.scss'],
})
export class SchoolFocusCardComponent {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) isOwner!: boolean;

  get showEmptyCard() {
    return isRichTextFieldEmpty(this.school.pedagogicFocus_html);
  }
}
