<div class="grid grid-flow-col">
  <div class="grid grid-cols-12">
    <div class="col-span-12">
      <h1 class="font-bold text-[2.125rem]">Meine Schule</h1>
    </div>

    @for (group of groupedSchools | async; track group) {
      <div class="col-span-12 pt-16">
        <p class="text-xl flex">{{ getRootName(group) }}</p>
        <div class="grid grid-cols-12 gap-4 pt-4">
          <app-card
            [routerLink]="['/schools', group[0].rootId]"
            class="col-span-12 md:col-span-4 lg:col-span-3 cursor-pointer"
            title="Schulprofil"
            description="Hier gelangst du zu deinem Schulprofil und kannst es bearbeiten." />
          <app-card
            [routerLink]="['/my-schools', group[0].rootId, 'jobs']"
            class="col-span-12 md:col-span-4 lg:col-span-3 cursor-pointer"
            title="Inserate"
            description="Hier gelangst du zur Verwaltungsübersicht der Schuleinheits-Inserate und eingehender Bewerbungen." />
          <app-card
            [routerLink]="[
              '/my-schools',
              group[0].rootId,
              'edit',
              'permissions'
            ]"
            class="col-span-12 md:col-span-4 lg:col-span-3 cursor-pointer"
            title="Admins"
            description="Füge weitere Personen hinzu, welche deine Schule verwalten können." />
        </div>
        <div class="grid grid-cols-12 gap-4 pt-4">
          @for (school of getNonPublishedSchools(group); track school.id) {
            <app-card
              [routerLink]="['/schools', school.id]"
              class="col-span-12 md:col-span-4 lg:col-span-3 cursor-pointer"
              title="Nicht veröffentlichtes Schulprofil"
              [dotted]="true"
              [description]="school.name" />
          }
        </div>
      </div>
    }
  </div>
</div>
