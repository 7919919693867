import { Component, Input } from '@angular/core';
import { School } from '../../../../types/school';
import { isRichTextFieldEmpty } from 'src/app/core/richText';

@Component({
  selector: 'app-school-about-card',
  templateUrl: './school-about-card.component.html',
  styleUrls: ['./school-about-card.component.scss'],
})
export class SchoolAboutCardComponent {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) isOwner!: boolean;

  get subtitle() {
    return this.school.isRoot
      ? 'Was macht deine Schuleinheit aus?'
      : 'Was macht diesen spezifischen Schulstandort aus?';
  }

  get description() {
    return this.school.isRoot
      ? 'Steigere die Attraktivität deiner Schule, indem du interessante Fakten teilst. Hier kannst du Bezug auf die gesamte Schuleinheit nehmen.'
      : 'Hier nimmst du Bezug auf standortspezifische Eigenschaften. Was ist speziell an diesem Standort? Weicht er in irgendeiner Form von der restlichen Schuleinheit ab?';
  }

  get showEmptyCard() {
    return isRichTextFieldEmpty(this.school.about_html);
  }
}
