<div class="grid grid-cols-12 pt-16">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col text-base">
      <h1 class="font-bold text-4xl">Wir sagen Adieu!</h1>
      <span>
        Es war schön das du bei uns warst. Auf deiner beruflichen Reise wünschen
        wir dir alles Gute!
      </span>
    </div>
    <div class="flex flex-col">
      <span>Herzliche Grüsse</span>
      <span>Dein Bildigsnetz Team</span>
    </div>
  </div>
</div>
