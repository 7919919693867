@if (!showEmptyCard) {
  <div class="grid grid-cols-12 md:gap-4">
    <div class="flex flex-col col-span-12 lg:col-span-8 lg:col-start-3">
      <div>
        <strong class="font-bold text-4xl md:text-5xl">Über uns</strong>
        @if (isOwner && !job.isClosed) {
          <app-edit-button
            class="pl-4"
            routerLink="edit/about"></app-edit-button>
        }
      </div>
      <app-rich-text-view
        class="pt-4"
        [html]="job.about_html"
        tailwindCssLineClamp="line-clamp-6"></app-rich-text-view>
    </div>
  </div>
}

@if (showEmptyCard) {
  <app-empty-card
    title="Über uns"
    subtitle="Kleiner Text, grosse Wirkung."
    description="Der Einstiegstext trägt entscheidend zum Erfolg deiner Stellenanzeige bei. Wecke die Aufmerksamkeit von Jobsuchenden!"
    link="edit/about"></app-empty-card>
}
