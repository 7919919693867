import { Component } from '@angular/core';
import { BreadcrumbService } from '../../../services/breadcrumb.service';

@Component({
  selector: 'app-settings-change-password-secure-help',
  templateUrl: './settings-change-password-secure-help.component.html',
  styleUrls: ['./settings-change-password-secure-help.component.scss'],
})
export class SettingsChangePasswordSecureHelpComponent {
  constructor(breadcrumbService: BreadcrumbService) {
    breadcrumbService.set([
      { text: 'Deine Einstellungen', link: 'settings' },
      { text: 'Nutzerkonto', link: 'settings/account' },
      { text: 'Passwort ändern', link: 'settings/change-password' },
      { text: 'Was macht ein sicheres Passwort aus?' },
    ]);
  }
}
