import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { School } from '../../../../types/school';

@Component({
  selector: 'app-school-progress-card',
  templateUrl: './school-progress-card.component.html',
  styleUrls: ['./school-progress-card.component.scss'],
})
export class SchoolProgressCardComponent implements OnInit {
  @Input({ required: true }) school!: School;
  @Input() buttonDisabled = false;
  @Output() publish = new EventEmitter<void>();

  permissionLink = 'edit/permissions';

  hasAbout = false;
  hasType = false;
  hasInfo = false;
  hasContacts = false;
  hasMedia = false;
  hasPedagogicFocus = false;
  hasDocuments = false;
  hasWeOffer = false;

  ngOnInit(): void {
    this.permissionLink = `/schools/${this.school.rootId}/edit/permissions`;

    this.checkProgress();
  }

  checkProgress() {
    this.hasAbout = !!this.school.about;
    this.hasInfo = this.isSchoolInfoComplete();
    this.hasType = !!this.school.type && !!this.school.levels;
    this.hasContacts =
      !!this.school.contacts && this.school.contacts.length > 0;
    this.hasMedia = !!this.school.media && this.school.media.length > 0;
    this.hasDocuments = !!this.school.documents;
    this.hasPedagogicFocus = !!this.school.pedagogicFocus;
    this.hasWeOffer = !!this.school.whatWeOffer;
  }

  private isSchoolInfoComplete(): boolean {
    const hasPeopleCounts =
      !!this.school.countEmployees && !!this.school.countStudents;
    const hasAddress =
      !!this.school.address &&
      !!this.school.address.street &&
      !!this.school.address.zipcode &&
      !!this.school.address.city;

    return hasPeopleCounts && hasAddress;
  }

  onPublish() {
    this.publish.emit();
  }
}
