import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input({
    required: true,
    // eslint-disable-next-line @angular-eslint/no-input-rename
    transform: (value: number) => (value > 100 ? 100 : value),
  })
  progress!: number;
}
