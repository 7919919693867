<div class="grid grid-cols-12">
  <div class="flex flex-wrap justify-between col-span-full">
    <div class="flex">
      @if (!showArchived) {
        <h1 class="font-bold text-[2.125rem]">Meine Bewerbungen</h1>
      }
      @if (showArchived) {
        <h1 class="font-bold text-[2.125rem]">Archivierte Bewerbungen</h1>
      }
    </div>
    <div class="flex w-full sm:w-auto gap-4 justify-end">
      @if (!showArchived) {
        <button
          mat-stroked-button
          routerLink="archived"
          class="flex gap-2 action-button"
          color="primary">
          <mat-icon
            class="material-symbols-rounded ml-0 mr-0 md:mr-auto md:ml-auto">
            archive
          </mat-icon>
          <span class="inline-block pr-2">Archiv</span>
        </button>
      }
      <!-- <button mat-stroked-button *ngIf="!showArchived" routerLink="/jobs/create" class="flex gap-2 action-button"
      color="primary">
      <mat-icon class="material-symbols-rounded ml-0 mr-0 md:mr-auto md:ml-auto">attach_file</mat-icon>
      <span class="inline-block pr-2">Anhänge</span>
    </button> -->
    </div>
  </div>
  <div class="col-span-12 pt-8">
    <div class="grid grid-cols-12 gap-4 pt-4">
      @for (application of applications$ | async; track application.id) {
        <app-application-card
          class="col-span-12 md:col-span-4 lg:col-span-3"
          (archive)="archive(application)"
          [application]="application" />
      }

      @if ((applications$ | async)?.length === 0) {
        <app-empty-box
          class="col-span-12"
          title="Nichts zu sehen?"
          description="{{
            showArchived ? noApplicationsDescArchived : noApplicationsDesc
          }}"></app-empty-box>
      }
    </div>
  </div>
</div>
