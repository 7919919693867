import { Component } from '@angular/core';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDialogComponent,
  ConfirmDialogProps,
} from '../../../components/confirm-dialog/confirm-dialog.component';
import { ProfileService } from '../../../services/profile.service';
import { NotificationService } from '../../../services/notification.service';
import { Router } from '@angular/router';
import { Auth, signOut } from '@angular/fire/auth';
import { from } from 'rxjs';

@Component({
  selector: 'app-settings-delete-account',
  templateUrl: './settings-delete-account.component.html',
  styleUrls: ['./settings-delete-account.component.scss'],
})
export class SettingsDeleteAccountComponent {
  loading = false;
  constructor(
    breadcrumbService: BreadcrumbService,
    private profileService: ProfileService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private router: Router,
    private auth: Auth
  ) {
    breadcrumbService.set([
      { text: 'Deine Einstellungen', link: 'settings' },
      { text: 'Nutzerkonto', link: 'settings/account' },
      { text: 'Profil löschen' },
    ]);
  }

  showDialog() {
    const dialogRef = this.dialog.open<
      ConfirmDialogComponent,
      ConfirmDialogProps
    >(ConfirmDialogComponent, {
      data: {
        text: 'Es tut uns leid, dich als Mitglied zu verlieren.',
        cancelText: 'Abbrechen',
        confirmText: 'Profil löschen',
      },
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.loading = true;
        this.profileService.delete().subscribe({
          next: () => {
            this.notificationService.success('Profil wurde gelöscht.');
            this.loading = false;
            localStorage.clear();
            from(signOut(this.auth)).subscribe(_ => {
              this.router.navigate(['/goodbye']);
            });
          },
          error: _ => {
            this.notificationService.error(
              'Fehler beim Löschen des Accounts aufgetreten. Versuchen Sie es später erneut.'
            );
            this.loading = false;
          },
        });
      }
    });
  }
}
