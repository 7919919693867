import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SchoolService } from '../../../../services/school.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogProps,
} from '../../../../components/confirm-dialog/confirm-dialog.component';
import { School } from '../../../../types/school';
import { Observable } from 'rxjs';
import { ApiVideoService } from '../../../../services/api-video.service';
import { UploadProgressEvent } from '@api.video/video-uploader';

@Component({
  selector: 'app-school-intro-card',
  templateUrl: './school-intro-card.component.html',
  styleUrls: ['./school-intro-card.component.scss'],
})
export class SchoolIntroCardComponent {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) isOwner!: boolean;

  uploadProgress$: Observable<UploadProgressEvent>;
  loading = false;

  readonly acceptTypes = ['.avi', '.webm', '.mp4', '.wmv', '.mov'];

  get showEmptyCard() {
    return !this.school.video;
  }

  constructor(
    private schoolService: SchoolService,
    private dialog: MatDialog,
    private apiVideoService: ApiVideoService
  ) {
    this.uploadProgress$ = apiVideoService.uploadProgress$;
  }

  getFileExtension(filename: string): string {
    const i = filename.lastIndexOf('.');
    return filename.substring(i);
  }

  async onRemove() {
    const dialogRef = this.dialog.open<
      ConfirmDialogComponent,
      ConfirmDialogProps
    >(ConfirmDialogComponent, {
      data: {
        text: 'Willst Du das Video wirklich löschen?',
        cancelText: 'Abbrechen',
        confirmText: 'Löschen',
      },
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed && this.school.video?.id && this.school.id) {
        const schoolId = this.school.id;
        const videoId = this.school.video?.id;
        this.loading = true;
        this.apiVideoService.remove(videoId, schoolId).subscribe(_ => {
          this.schoolService.update(schoolId, { video: null }).subscribe(_ => {
            this.loading = false;
            this.school.video = null;
          });
        });
      }
    });
  }

  showProgress(progress: UploadProgressEvent) {
    const p = Math.round((progress.uploadedBytes / progress.totalBytes) * 100);
    if (p === 100) {
      return 'Video wird verarbeitet...';
    } else if (p > 0) {
      return `${p} %`;
    } else {
      return '';
    }
  }

  async upload(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!this.loading && target.files && target.files.length > 0) {
      this.loading = true;
      const file = target.files[0];
      const response = await this.apiVideoService.uploadAsync(file);

      const video = {
        id: response.videoId,
        hls: response.assets?.hls || '',
        mp4: response.assets?.mp4 || '',
      };

      await this.schoolService.updateAsync(this.school.id!, { video });
      this.school.video = video;
      this.loading = false;
    }
  }
}
