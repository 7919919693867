import { Component, Input } from '@angular/core';

export type OrderProcessBenefit = {
  bgColor: string;
  textColor?: string;
  title: string;
  subtitle: string;
  list: string[];
};

@Component({
  selector: 'app-order-process-benefit',
  templateUrl: './order-process-benefit.component.html',
  styleUrls: ['./order-process-benefit.component.scss'],
})
export class OrderProcessBenefitComponent {
  @Input() benefit: OrderProcessBenefit = {
    bgColor: '',
    title: '',
    subtitle: '',
    list: [],
  };
}
