import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SchoolService } from '../../../services/school.service';
import { School, SchoolLocation, SchoolTypes } from '../../../types/school';

@Component({
  selector: 'app-school-onboarding',
  templateUrl: './school-onboarding.component.html',
  styleUrls: ['./school-onboarding.component.scss'],
})
export class SchoolOnboardingComponent implements OnInit {
  readonly maxLocationCount = 10;

  id: string;
  index = 0;
  school$: Observable<School>;
  isLoading = false;
  countLocations?: number;

  schoolTypes = SchoolTypes;

  schoolTypeControl = new FormControl<(typeof SchoolTypes)[number] | null>(
    null,
    [Validators.required]
  );

  isCountLocationsEnabled = true;

  schoolName = new FormControl('', [Validators.required]);
  locationFields: FormControl<string | null | undefined>[] = [
    new FormControl('', [Validators.required]),
  ];

  constructor(
    private schoolService: SchoolService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.school$ = schoolService.getById(this.id);
  }

  ngOnInit(): void {
    this.school$.subscribe(school => {
      // this.isCountLocationsEnabled = !school.locations;
      this.schoolName.setValue(school?.name || '');
      if (school?.locations) {
        if (school.locations.length === 0) {
          this.countLocations = 1;
          this.locationFields = [new FormControl('', [Validators.required])];
        } else {
          this.countLocations = school.locations.length;
          this.locationFields = school.locations.map(
            l => new FormControl(l.name, [Validators.required])
          );
        }
      }
    });
  }

  setCountLocations(e: any) {
    let newValue = parseInt(e.target.value || 1);
    newValue = newValue < 1 ? 1 : newValue;
    if (newValue > 10) newValue = this.maxLocationCount;
    const prevCount = this.countLocations || 1;
    const diff = prevCount - newValue;
    this.countLocations = newValue;

    if (prevCount > newValue) {
      const s = prevCount - diff;
      this.locationFields = this.locationFields.slice(0, s);
    } else if (prevCount < newValue) {
      for (let i = 0; i < diff * -1; i++) {
        this.locationFields.push(
          new FormControl('', [Validators.required, Validators.minLength(3)])
        );
      }
    }
  }

  isValid() {
    if (this.countLocations === 1) {
      return this.schoolName.valid && this.schoolTypeControl.valid;
    }
    if (this.countLocations || 1 > 1) {
      return (
        this.locationFields.every(l => l.valid) && this.schoolTypeControl.valid
      );
    }

    return false;
  }

  getLocationId(school: School, index: number) {
    return school.locations?.[index]?.id || null;
  }

  async save(currentSchool: School) {
    const schoolType = this.schoolTypeControl
      .value as (typeof SchoolTypes)[number];
    this.isLoading = true;
    const schoolName = this.schoolName.value as string;
    if (this.countLocations === 1) {
      this.locationFields[0].setValue(schoolName);
    }

    const locationNames = this.locationFields.map(l => l.value as string);

    const school: Partial<School> = {
      name: schoolName,
      type: schoolType,
      locations:
        (this.countLocations || 1) > 1
          ? locationNames.map(
              (ln, index) =>
                ({
                  name: ln,
                  id: this.getLocationId(currentSchool, index),
                  isRoot: false,
                }) as SchoolLocation
            )
          : [],
    };

    this.schoolService.update(this.id, school).subscribe(() => {
      this.schoolService.updateSchoolLocations(this.id).subscribe({
        next: async () => {
          await this.router.navigate(['schools', this.id]);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    });
  }
}
