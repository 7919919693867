import { Component, Input, OnInit } from '@angular/core';
import { School } from '../../../../types/school';
import { JobService } from '../../../../services/job.service';
import { Job } from '../../../../types/job';

@Component({
  selector: 'app-school-jobs-card',
  templateUrl: './school-jobs-card.component.html',
  styleUrls: ['./school-jobs-card.component.scss'],
})
export class SchoolJobsCardComponent implements OnInit {
  @Input({ required: true }) school!: School;

  jobs?: Job[];

  constructor(private jobService: JobService) {}

  ngOnInit(): void {
    if (this.school.isRoot) {
      this.jobService
        .getBySchoolRoot(this.school.rootId!, true, false)
        .subscribe(jobs => (this.jobs = jobs));
    } else {
      this.jobService
        .getBySchool(this.school.id!)
        .subscribe(jobs => (this.jobs = jobs));
    }
  }
}
