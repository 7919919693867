<div class="grid grid-cols-12 gap-8 pt-40">
  <h1 class="col-span-12 font-bold text-4xl">Einstellungen</h1>

  <a
    routerLink="/settings/account"
    class="flex flex-col col-span-12 md:col-span-4 rounded-3xl bg-[#F1F5FB] p-12 gap-24">
    <div
      class="flex justify-center items-center w-[52px] h-[52px] rounded-full p-2 bg-[#202020]">
      <mat-icon class="material-symbols-rounded text-white">person</mat-icon>
    </div>
    <div class="flex flex-col gap-2">
      <strong class="font-bold text-2xl">Nutzerkonto</strong>
      <span class="text-base">Zugangsdaten und persönliche Einstellungen</span>
    </div>
  </a>

  <a
    routerLink="/settings/privacy"
    class="flex flex-col col-span-12 md:col-span-4 rounded-3xl bg-[#F1F5FB] p-12 gap-24">
    <div
      class="flex justify-center items-center w-[52px] h-[52px] rounded-full p-2 bg-[#202020]">
      <mat-icon class="material-symbols-rounded text-white">lock</mat-icon>
    </div>
    <div class="flex flex-col gap-2">
      <strong class="font-bold text-2xl">Privatsphäre</strong>
      <span class="text-base">
        Hier bestimmst du, welche deiner Angaben sichtbar sind
      </span>
    </div>
  </a>

  <a
    routerLink="/settings/marketing"
    class="flex flex-col col-span-12 md:col-span-4 rounded-3xl bg-[#F1F5FB] p-12 gap-24">
    <div
      class="flex justify-center items-center w-[52px] h-[52px] rounded-full p-2 bg-[#202020]">
      <mat-icon class="material-symbols-rounded text-white">
        notifications
      </mat-icon>
    </div>
    <div class="flex flex-col gap-2">
      <strong class="font-bold text-2xl">Benachrichtigungen</strong>
      <span class="text-base">
        Benachrichtigungen per E-Mail und Stellen-Vorschläge
      </span>
    </div>
  </a>
</div>
