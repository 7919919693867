import { Component } from '@angular/core';
import {
  Auth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { NotificationService } from '../../services/notification.service';
import { FirebaseError } from '@angular/fire/app';
import { PublicToolbarService } from '../../services/public-toolbar.service';
import {
  firebaseErrorCodes,
  mapFirebaseErrorCodeToUserMessage,
} from 'src/app/core/firebaseErrorMapper';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  showLoginError = false;
  loading = false;

  form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  constructor(
    private auth: Auth,
    private router: Router,
    private fb: FormBuilder,
    private profileService: ProfileService,
    private notificationService: NotificationService,
    private oidcService: OidcSecurityService,
    toolbarConfig: PublicToolbarService
  ) {
    toolbarConfig.set({
      showLogin: false,
      showBoth: false,
    });

    this.form.valueChanges.subscribe(_ => {
      this.form.updateValueAndValidity();
    });
  }

  async reload() {
    // reloads current page to trigger logic of router
    await this.router.navigateByUrl('/', { skipLocationChange: true });
    await this.router.navigate(['home']);
  }

  loginWithEdulog() {
    this.oidcService.authorize();
  }

  async loginGoogle() {
    try {
      await signInWithPopup(this.auth, new GoogleAuthProvider());
      this.profileService.authorize().subscribe(async () => {
        this.reload();
      });
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (
          error.code === firebaseErrorCodes.accountExistsWithDifferentCredential
        ) {
          this.notificationService.error(
            mapFirebaseErrorCodeToUserMessage(error.code)
          );
          return;
        }
      }

      // Unknown errors
      this.notificationService.error(
        'Ein Fehler ist aufgetreten. Bitte versuche es später erneut.'
      );

      this.loading = false;
    }
  }

  async loginEmail() {
    this.loading = true;
    const email = this.form.get('email')?.value;
    const password = this.form.get('password')?.value;
    try {
      await signInWithEmailAndPassword(this.auth, email!, password!);
      this.profileService.authorize().subscribe(async () => {
        await this.reload();
      });
      this.loading = false;
    } catch (error) {
      setTimeout(() => {
        this.showLoginError = true;
        this.loading = false;
      }, 1500);
    }
  }
}
