import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Application } from '../../types/application';
import { getPublicFileUrl } from '../../core/helpers';
import { JobService } from 'src/app/services/job.service';
import { Job } from 'src/app/types/job';

@Component({
  selector: 'app-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss'],
})
export class ApplicationCardComponent {
  @Input({ required: true }) application!: Application;
  @Output() archive = new EventEmitter();

  constructor(private jobService: JobService) {}

  getAvatarUrl() {
    if (this.application?.schoolId) {
      return getPublicFileUrl('schools', this.application?.schoolId, 'avatar');
    }
    return '';
  }

  getTagText() {
    return this.application?.jobFunction;
  }

  getJobType() {
    return this.application.isFullTimeJob
      ? 'Festanstellung'
      : 'Stellvertretung';
  }

  getTimeRange() {
    const jobInfo = {
      isDurationStartNow: this.application.jobIsDurationStartNow,
      durationFrom: this.application.jobDurationFrom,
      durationTo: this.application.jobDurationTo,
    } as Job;

    return this.jobService.getFormattedSubstitutionPeriodShort(jobInfo);
  }

  getActivityRange() {
    this.jobService.getFormattedActivityRange(
      this.application.jobActivityRangeInPercentage,
      this.application.jobActivityRangeInHours
    );
  }

  onArchive() {
    this.archive.emit();
  }
}
