import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { JobService } from '../../../../services/job.service';
import { Job } from '../../../../types/job';
import { convertRichTextToPlainText } from 'src/app/core/richText';

@Component({
  selector: 'app-job-tasks-form',
  templateUrl: './job-tasks-form.component.html',
  styleUrls: ['./job-tasks-form.component.scss'],
})
export class JobTasksFormComponent implements OnInit {
  id: string;
  job$: Observable<Job>;
  loading = false;

  form = this.fb.group({
    yourTasks: ['', []],
  });

  constructor(
    private fb: FormBuilder,
    private jobService: JobService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.job$ = jobService.getById(this.id);
  }

  ngOnInit(): void {
    this.job$.subscribe(job => {
      this.form.setValue({
        yourTasks: job.yourTasks_html || '',
      } as any);
    });
  }

  save() {
    this.loading = true;

    const { yourTasks } = this.form.getRawValue();
    const payload = {
      yourTasks: convertRichTextToPlainText(yourTasks) || '',
      yourTasks_html: yourTasks || '',
    };

    this.jobService.update(this.id, payload).subscribe(async () => {
      this.loading = false;
      await this.router.navigate(['jobs', this.id]);
    });
  }
}
