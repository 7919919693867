<div class="grid grid-cols-12">
  <div class="flex flex-col gap-4 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Weiterbildungen und Zertifikate</h1>

    <div class="flex flex-col">
      @for (control of certificationControls; track control) {
        <mat-form-field>
          <mat-label>Qualifikation</mat-label>
          <input matInput [formControl]="control" />
          @if (control.value) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="control.setValue('')">
              <mat-icon class="material-symbols-rounded">close</mat-icon>
            </button>
          }
        </mat-form-field>
      }
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/me', id]">Abbrechen</a>
      <button mat-flat-button color="primary" type="submit" (click)="save()">
        Speichern
      </button>
    </div>
  </div>
</div>
