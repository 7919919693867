import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { SchoolService } from '../../../../services/school.service';
import {
  School,
  SchoolLevelDescriptions,
  SchoolLevels,
  SchoolTypes,
} from '../../../../types/school';

@Component({
  selector: 'app-school-name-form',
  templateUrl: './school-name-form.component.html',
  styleUrls: ['./school-name-form.component.scss'],
})
export class SchoolNameFormComponent implements OnInit {
  id!: string;
  school$: Observable<School> = EMPTY;
  school!: School;
  loading = false;

  schoolTypes = SchoolTypes;
  schoolLevels = SchoolLevels;
  schoolLevelDescriptions = SchoolLevelDescriptions;

  showJobLevelDescriptionsField = false;

  form = this.fb.group({
    name: ['', [Validators.required]],
    type: ['', [Validators.required]],
    levels: [[''], [Validators.required]],
    levelDescriptions: [[''], []],
  });

  constructor(
    route: ActivatedRoute,
    private fb: FormBuilder,
    private schoolService: SchoolService,
    private router: Router
  ) {
    route.paramMap.subscribe(params => {
      this.id = params.get('id') as string;
      this.school$ = schoolService.getById(this.id);
      this.school$.subscribe(s => (this.school = s));
    });
  }

  ngOnInit() {
    this.school$.subscribe(school => {
      this.form.setValue({
        name: school.name || '',
        type: school.type || null,
        levels: school.levels || [],
        levelDescriptions: school.levelDescriptions || [],
      });
    });

    this.form.controls.levels.valueChanges.subscribe(levels => {
      this.showJobLevelDescriptionsField =
        !!levels && levels.includes('Sekundarstufe II');
    });
  }

  getSchoolTitle(levels?: string[]) {
    let levelsTemp: string[] = [];
    if (levels?.includes('Unterstufe') && levels?.includes('Mittelstufe')) {
      levelsTemp = [
        ...levels.filter(l => l !== 'Unterstufe' && l !== 'Mittelstufe'),
        'Primarstufe',
      ];
    } else {
      levelsTemp = levels || [];
    }

    return levelsTemp.join(' | ');
  }

  async save() {
    this.loading = true;
    const values = this.form.getRawValue() as Partial<School>;

    // prevent overwriting school type
    if (!this.school?.isRoot) {
      delete values.type;
    }

    const title = this.getSchoolTitle(values.levels);
    this.schoolService.update(this.id, { ...values, title }).subscribe(_ => {
      this.schoolService.updateSchoolLocations(this.id).subscribe(_ => {
        this.router.navigate(['schools', this.id]);
        this.loading = false;
      });
    });
  }
}
