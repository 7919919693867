import { Component, Input, OnInit } from '@angular/core';
import { Education, Profile } from '../../../../types/profile';
import { format } from 'date-fns';
import { translateMonths } from '../../../../core/helpers';

@Component({
  selector: 'app-profile-education-card',
  templateUrl: './profile-education-card.component.html',
  styleUrls: ['./profile-education-card.component.scss'],
})
export class ProfileEducationCardComponent implements OnInit {
  @Input({ required: true }) profile!: Profile;
  @Input({ required: true }) isOwner!: boolean;

  showAll = false;
  amount = 3;
  educations: Education[] = [];

  get showEmptyCard() {
    return !this.profile.educations || this.profile.educations.length < 1;
  }

  ngOnInit(): void {
    this.educations =
      this.profile.educations?.sort((a, b) => {
        if (!a.endDate) return -1;
        if (!b.endDate) return 1;
        return b.endDate < a.endDate ? -1 : 1;
      }) || [];
  }

  getStartDate(education: Education) {
    if (!education.startDate) return '';
    const month = translateMonths(
      format(new Date(education.startDate), 'MMMM')
    ).toUpperCase();
    const year = format(new Date(education.startDate), 'Y');
    return `${month} ${year}`;
  }

  getEndDate(education: Education) {
    if (!education.endDate) return 'HEUTE';
    const month = translateMonths(
      format(new Date(education.endDate), 'MMMM')
    ).toUpperCase();
    const year = format(new Date(education.endDate), 'Y');
    return `${month} ${year}`;
  }

  toggleShowAll() {
    this.showAll = !this.showAll;
    this.amount = this.showAll ? this.profile.educations?.length || 0 : 3;
  }
}
