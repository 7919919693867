import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Application } from '../../../types/application';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-job-close-dialog',
  templateUrl: './job-close-dialog.component.html',
  styleUrls: ['./job-close-dialog.component.scss'],
})
export class JobCloseDialogComponent {
  reasonControl = new FormControl<string>('', [Validators.required]);

  constructor(public dialogRef: MatDialogRef<Application>) {}

  close() {
    this.dialogRef.close({ confirm: true, reason: this.reasonControl.value });
  }
}
