import { Component } from '@angular/core';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { Validators, FormBuilder } from '@angular/forms';
import {
  Auth,
  updatePassword,
  sendPasswordResetEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from '@angular/fire/auth';
import { NotificationService } from '../../../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { SettingsPasswordResetConfirmDialogComponent } from '../settings-password-reset-confirm-dialog/settings-password-reset-confirm-dialog.component';
import { Router } from '@angular/router';
import {
  isFirebaseErrorKnown,
  mapFirebaseErrorCodeToUserMessage,
} from 'src/app/core/firebaseErrorMapper';
import { FirebaseError } from '@angular/fire/app';

@Component({
  selector: 'app-settings-change-password',
  templateUrl: './settings-change-password.component.html',
  styleUrls: ['./settings-change-password.component.scss'],
})
export class SettingsChangePasswordComponent {
  loading = false;
  changePwError = '';
  generalErrorMessage =
    'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.';

  form = this.fb.group({
    password: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    private auth: Auth,
    private dialog: MatDialog,
    private router: Router,
    private notification: NotificationService,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.set([
      { text: 'Deine Einstellungen', link: 'settings' },
      { text: 'Nutzerkonto', link: 'settings/account' },
      { text: 'Passwort ändern' },
    ]);
  }

  async resetPassword() {
    const user = this.auth.currentUser;

    if (!user?.email) {
      // TODO: Error Handling
      return;
    }

    this.dialog.open(SettingsPasswordResetConfirmDialogComponent, {
      data: user.email,
    });

    await sendPasswordResetEmail(this.auth, user.email!);
  }

  async change() {
    this.loading = true;
    const user = this.auth.currentUser;
    const password = this.form.get('password')?.value;
    const newPassword = (this.form.get('passwordFields')?.value as any)
      ?.password as string;

    // Show general error message when data consistency is not given
    if (!user || !password || !newPassword) {
      this.changePwError = this.generalErrorMessage;
      this.loading = false;

      return;
    }

    try {
      // Do a reauthentication before (https://stackoverflow.com/a/37812541)
      const userCredentials = EmailAuthProvider.credential(
        user.email!,
        password
      );
      await reauthenticateWithCredential(user, userCredentials);

      await updatePassword(user, newPassword!);
    } catch (error) {
      // Known errors
      if (isFirebaseErrorKnown(error)) {
        this.changePwError = mapFirebaseErrorCodeToUserMessage(
          (error as FirebaseError).code
        );
      }

      // Unkown errors
      if (!this.changePwError) {
        this.changePwError = this.generalErrorMessage;
      }

      this.loading = false;
      return;
    }

    this.loading = false;
    this.notification.success('Password aktualisiert.');

    this.router.navigate(['/settings/account']);
  }
}
