<div class="flex flex-col grow justify-center">
  <div class="flex flex-col grow">
    <div class="flex w-full justify-between">
      <div>
        @if (page !== 0 && stepperContainer?.getCurrentStep() !== 2) {
          <mat-icon
            class="material-symbols-rounded scale-100 cursor-pointer"
            (click)="onPrev()">
            keyboard_backspace
          </mat-icon>
        }
      </div>
      <div>
        <mat-icon
          class="material-symbols-rounded scale-100 cursor-pointer"
          (click)="onCloseDialog()">
          close
        </mat-icon>
      </div>
    </div>
    <div class="flex flex-col grow">
      <div class="flex max-w-[1724px] self-center h-full grow">
        <div class="flex grow py-10 md:px-14">
          @if (page === 0) {
            <app-order-process-start
              (showPriceOverview)="onNext()"></app-order-process-start>
          }
          @if (page === 1) {
            <app-order-process-products
              (selectOffer)="
                onSelectOffer($event)
              "></app-order-process-products>
          }
          @if (page === 2 && selectedOffer) {
            <app-order-process-ordering
              #stepperContainer
              class="-mt-9 md:-mt-[93px]"
              [subscriptionType]="selectedOffer"
              (closeDialog)="onCloseDialog()"></app-order-process-ordering>
          }
        </div>
      </div>
    </div>
  </div>
</div>
