<div class="flex flex-col gap-4 flex-wrap">
  <div
    #textbox
    [ngClass]="getLineClampClass()"
    class="text-ellipsis whitespace-pre-line break-words NgxEditor__View"
    [innerHTML]="html"></div>
  @if (
    expanded ||
    textbox.offsetHeight < textbox.scrollHeight ||
    textbox.offsetWidth < textbox.scrollWidth
  ) {
    <div class="flex justify-center">
      @if (expanded) {
        <button mat-button color="primary" (click)="toggleText()">
          <mat-icon class="material-symbols-rounded">expand_less</mat-icon>
          Weniger anzeigen
        </button>
      }
      @if (!expanded) {
        <button mat-button color="primary" (click)="toggleText()">
          <mat-icon class="material-symbols-rounded">expand_more</mat-icon>
          Mehr anzeigen
        </button>
      }
    </div>
  }
</div>
