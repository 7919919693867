import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { School } from '../../../../types/school';
import { BucketFile } from '../../../../types/core';
import { SchoolService } from '../../../../services/school.service';
import { FormControl, Validators } from '@angular/forms';
import { FileService } from '../../../../services/files.service';
import { scaleDataURL } from '../../../../core/helpers';

export type Media = {
  title: FormControl<string | null>;
  description: FormControl<string | null>;
  media?: PreviewMedia;
};
export type PreviewMedia = {
  file?: Blob;
  displayUrl?: string | null | undefined;
  filename?: string | undefined;
  type: MediaType;
  coverImage?: CoverImage;
};
export type CoverImage = { file?: Blob; displayUrl: string | null | undefined };
export type MediaType = 'image' | 'audio';

@Component({
  selector: 'app-school-media-form',
  templateUrl: './school-media-form.component.html',
  styleUrls: ['./school-media-form.component.scss'],
})
export class SchoolMediaFormComponent implements OnInit {
  id: string;
  school$: Observable<School>;
  school?: School;
  loading = false;

  readonly MAX_HEIGHT = 1600;
  readonly MAX_WIDTH = 1600;

  readonly imageExtensions = ['.jpg', '.jpeg', '.png', '.tiff'];
  readonly audioExtensions = ['.mp3'];
  readonly acceptTypes = [...this.imageExtensions, ...this.audioExtensions];

  removedMedia: BucketFile[] = [];
  currentMediaList: Media[] = [];
  newMediaList: Media[] = [
    {
      title: new FormControl<string>('', [Validators.required]),
      description: new FormControl<string>('', []),
    },
  ];

  constructor(
    route: ActivatedRoute,
    private schoolService: SchoolService,
    private fileService: FileService,
    private router: Router
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.school$ = schoolService.getById(this.id);
  }

  ngOnInit() {
    this.school$.subscribe(school => {
      this.school = school;
      if (school.media) {
        this.currentMediaList = school.media.map(m => ({
          title: new FormControl<string>(m.title || '', [Validators.required]),
          description: new FormControl<string>(m.description || '', []),
          media: {
            displayUrl: m.url,
            coverImage: { displayUrl: m.coverImage?.url },
            filename: m.filename,
            type: m.type as MediaType,
          },
        }));
      }
    });
  }

  isFormValid() {
    const isValid = (m: Media) => {
      if (m.media?.type === 'audio' && !m.media.coverImage) return false;
      return m.description.valid && m.title.valid;
    };
    const validNewMedia = this.newMediaList.filter(m => m.media).every(isValid);
    const validCurrentMedia = this.currentMediaList.every(isValid);
    return validCurrentMedia && validNewMedia;
  }

  getExtension(filename: string) {
    const i = filename.lastIndexOf('.');
    return filename.substring(i);
  }

  getType(file?: File): MediaType | undefined {
    if (!file) return 'image';

    const extension = this.getExtension(file.name);
    if (this.imageExtensions.some(e => extension === e)) return 'image';
    if (this.audioExtensions.some(e => extension === e)) return 'audio';

    return 'image';
  }

  onSelectFile(event: Event, newMedia: Media, isAudioCover: boolean) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      const files = target.files;
      for (const file of files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          if (e.target && e.target.result) {
            const displayUrl = e.target.result as string;

            if (!isAudioCover) {
              newMedia.media = {
                displayUrl,
                file: file,
                filename: file.name,
                type: this.getType(file) || 'image',
              };
              this.addNewLine();
            } else if (newMedia.media) {
              newMedia.media.coverImage = { displayUrl, file };
            }
          }
        };
      }
    }
  }

  addNewLine() {
    const lastMedia = this.newMediaList[this.newMediaList.length - 1];
    if (lastMedia.media) {
      this.newMediaList.push({
        title: new FormControl<string>('', [Validators.required]),
        description: new FormControl<string>('', []),
      });
    }
  }

  removeNewMedia(index: number) {
    this.newMediaList.splice(index, 1);
    this.addNewLine();
  }

  removeMedia(index: number) {
    this.currentMediaList?.splice(index, 1);
    const files = this.school?.media?.splice(index, 1);
    this.removedMedia = [...this.removedMedia, ...(files || [])];
    this.addNewLine();
  }

  getFileExtension(filename: string): string {
    const i = filename.lastIndexOf('.');
    return filename.substring(i);
  }

  async uploadMediaFiles(newMedias: Media[]) {
    const uploadedFiles: BucketFile[] = [];
    for (const newMedia of newMedias) {
      if (newMedia.media?.file) {
        const path = `/schools/${this.id}/media`;

        if (newMedia.media.type !== 'audio' && newMedia.media.displayUrl) {
          newMedia.media.file = await scaleDataURL(
            newMedia.media.displayUrl,
            this.MAX_HEIGHT,
            this.MAX_WIDTH
          );
        }

        const bucketFile = await this.fileService.uploadAsync(
          path,
          newMedia.media.file,
          undefined,
          true
        );
        bucketFile.title = newMedia.title.value || '';
        bucketFile.description = newMedia.description.value || '';
        bucketFile.type = newMedia.media.type;

        if (
          newMedia.media.type === 'audio' &&
          newMedia.media.coverImage?.file
        ) {
          bucketFile.coverImage = await this.fileService.uploadAsync(
            path,
            newMedia.media.coverImage.file,
            `${bucketFile.filename}_cover`,
            true
          );
        }

        uploadedFiles.push(bucketFile);
      }
    }

    return uploadedFiles;
  }

  async save() {
    this.loading = true;
    try {
      const newMedia = await this.uploadMediaFiles(this.newMediaList);
      // delete all removed files
      await Promise.allSettled(
        this.removedMedia.map(async (file: BucketFile) => {
          if (file.coverImage) {
            this.fileService.remove(file.coverImage.path);
          }
          return this.fileService.remove(file.path);
        })
      );

      // update current media title and descriptions
      this.school?.media?.forEach((media, i) => {
        media.title = this.currentMediaList[i].title.value || '';
        media.description = this.currentMediaList[i].description.value || '';
      });

      const media = [...(this.school?.media || []), ...newMedia];

      this.schoolService.update(this.id, { media }).subscribe(_ => {
        this.router.navigate(['schools', this.id]);
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
    }
  }
}
