import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from '../../../../services/profile.service';
import { Education, Profile } from '../../../../types/profile';
import { v4 as uuid } from 'uuid';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-profile-education-form',
  templateUrl: './profile-education-form.component.html',
  styleUrls: ['./profile-education-form.component.scss'],
})
export class ProfileEducationFormComponent implements OnInit {
  id: string;
  educationId: string;
  profile$: Observable<Profile>;
  profile?: Profile;
  showDeleteButton = false;

  form = this.fb.group({
    course: ['', [Validators.required]],
    degree: ['', []],
    schoolName: ['', [Validators.required]],
    location: ['', []],
    startDate: this.fb.control<Date | string | null>(null, [
      Validators.required,
    ]),
    endDate: this.fb.control<Date | string | null>(null, [Validators.required]),
    isStudying: [false, []],
  });

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.profile$ = profileService.getMe(false);
    this.educationId = this.getId();
  }

  ngOnInit(): void {
    this.showDeleteButton =
      this.route.snapshot.paramMap.get('educationId') !== 'new';
    this.profile$.subscribe(profile => {
      this.profile = profile;
      const education = profile.educations?.find(
        e => e.id === this.educationId
      );

      this.form.setValue({
        course: education?.course || '',
        degree: education?.degree || '',
        schoolName: education?.schoolName || '',
        startDate: education?.startDate || null,
        endDate: education?.endDate || null,
        isStudying: education?.isStudying || false,
        location: education?.location || '',
      } as any);

      if (education?.isStudying) {
        this.form.controls.endDate.disable();
      }

      this.form.controls.isStudying.valueChanges.subscribe(isStudying => {
        if (isStudying) {
          this.form.controls.endDate.setValue(null);
          this.form.controls.endDate.disable();
        } else {
          this.form.controls.endDate.enable();
        }
      });
    });
  }

  getId() {
    const id = this.route.snapshot.paramMap.get('educationId') || uuid();
    if (id === 'new') {
      return uuid();
    }

    return id;
  }

  save() {
    const { startDate, endDate, ...data } = this.form.value;

    const education = {
      ...data,
      startDate: this.getDateStringOrNull(startDate),
      endDate: this.getDateStringOrNull(endDate),
      id: this.educationId,
    } as Education;

    const educations = this.profile?.educations || [];
    const index = educations.findIndex(e => e.id === this.educationId);
    if (index !== -1) {
      educations[index] = education;
    } else {
      educations.push(education);
    }

    this.profileService.update({ educations }).subscribe(_ => {
      this.router.navigate(['me', this.id]);
    });
  }

  remove() {
    const educations = this.profile?.educations || [];
    const i = educations.findIndex(e => e.id === this.educationId);
    if (i === -1) return;
    educations.splice(i, 1);
    this.profileService.update({ educations }).subscribe(_ => {
      this.router.navigate(['me', this.id]);
    });
  }

  setMonthAndYear(
    value: Date,
    datepicker: MatDatepicker<any>,
    control: FormControl
  ) {
    control.setValue(value);
    datepicker.close();
  }

  private getDateStringOrNull(d: string | Date | null | undefined) {
    if (typeof d === 'string') {
      return d;
    }

    return d ? d?.toISOString() : null;
  }
}
