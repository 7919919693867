import { Component, Input } from '@angular/core';
import { School } from '../../../../types/school';
import { BucketFile } from '../../../../types/core';
import { getPublicFileUrlByFilePath } from '../../../../core/helpers';

@Component({
  selector: 'app-school-documents-card',
  templateUrl: './school-documents-card.component.html',
  styleUrls: ['./school-documents-card.component.scss'],
})
export class SchoolDocumentsCardComponent {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) isOwner!: boolean;

  get showEmptyCard() {
    return !this.school.documents || this.school.documents?.length === 0;
  }

  getUrl(file: BucketFile) {
    return getPublicFileUrlByFilePath(file.path);
  }
}
