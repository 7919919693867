import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  doc,
  Firestore,
  getDoc,
  query,
  where,
} from '@angular/fire/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { from, map, Observable } from 'rxjs';
import {
  SchoolPermission,
  SchoolPermissionRequest,
} from '../types/permissions';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  readonly key = 'SCHOOL_PERMISSIONS';

  private addPermissionFunc: (data: {
    schoolId: string;
    userId: string;
  }) => Observable<void>;
  private revokePermissionFunc: (data: {
    permissionId: string;
    userId: string;
  }) => Observable<void>;
  private updatePermissionRequestFunc: (data: {
    permissionRequestId: string;
    accept: boolean;
  }) => Observable<void>;
  private requestSchoolPermissionsFunc: (data: {
    schoolId: string;
  }) => Observable<void>;

  constructor(
    private firestore: Firestore,
    functions: Functions
  ) {
    this.revokePermissionFunc = httpsCallableData(
      functions,
      'revokepermission',
      {}
    );
    this.addPermissionFunc = httpsCallableData(functions, 'addpermission', {});
    this.updatePermissionRequestFunc = httpsCallableData(
      functions,
      'updatepermissionrequest',
      {}
    );
    this.requestSchoolPermissionsFunc = httpsCallableData(
      functions,
      'requestpermission',
      {}
    );
  }

  loadPermissions(userId: string) {
    const ref = query(
      collection(this.firestore, 'permissions'),
      where('userIds', 'array-contains', userId)
    );

    return collectionData(ref).pipe(
      map(data => {
        const permissions = data as SchoolPermission[];
        this.setPermissions(permissions);
        return permissions;
      })
    );
  }

  setPermissions(permissions: SchoolPermission[]) {
    localStorage.setItem(this.key, JSON.stringify(permissions));
  }

  getPermissions() {
    return JSON.parse(
      localStorage.getItem(this.key) as string
    ) as SchoolPermission[];
  }

  isOwner(schoolId: string) {
    const permissions = this.getPermissions();
    return permissions?.some(p => p.schoolId === schoolId);
  }

  getById(id: string) {
    const docRef = doc(this.firestore, `permissions/${id}`);
    return from(getDoc(docRef)).pipe(
      map(d => ({ ...d.data(), id: d.id }) as SchoolPermission)
    );
  }

  getPermissionRequests(id: string) {
    const colRef = query(
      collection(this.firestore, 'permissionRequests'),
      where('schoolId', '==', id)
    );
    return collectionData(colRef, { idField: 'id' }) as Observable<
      SchoolPermissionRequest[]
    >;
  }

  revoke(permissionId: string, userId: string) {
    return this.revokePermissionFunc({ permissionId, userId });
  }

  add(userId: string, schoolId: string) {
    return this.addPermissionFunc({ userId, schoolId });
  }

  update(permissionRequestId: string, accept: boolean) {
    return this.updatePermissionRequestFunc({ permissionRequestId, accept });
  }

  request(schoolId: string) {
    return this.requestSchoolPermissionsFunc({ schoolId });
  }
}
