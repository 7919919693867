import { Injectable } from '@angular/core';
import {
  Firestore,
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  query,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Auth } from '@angular/fire/auth';
import { Analytics, logEvent } from '@angular/fire/analytics';
import {
  NotificationSubscription,
  JobSubscriptionData,
} from '../types/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationSubscriptionService {
  constructor(
    private firestore: Firestore,
    private auth: Auth,
    private analytics: Analytics
  ) {}

  getJobNotificationSubscriptions() {
    const userId = this.auth.currentUser?.uid;
    const colRef = query(
      collection(this.firestore, 'notificationSubscriptions'),
      where('userId', '==', userId),
      where('type', '==', 'job')
    );

    return collectionData(colRef, { idField: 'id' }) as Observable<
      NotificationSubscription<JobSubscriptionData>[]
    >;
  }

  upsertNotificationSubscription(subscription: NotificationSubscription) {
    if (subscription.id) {
      const docRef = doc(
        this.firestore,
        `notificationSubscriptions/${subscription.id}`
      );

      logEvent(this.analytics, 'notification_subscription_updated');
      return updateDoc(docRef, subscription);
    }

    const collectionRef = collection(
      this.firestore,
      'notificationSubscriptions'
    );

    logEvent(this.analytics, 'notification_subscription_created');
    return addDoc(collectionRef, subscription);
  }

  deleteNotificationSubscription(subscriptionId: string) {
    const docRef = doc(
      this.firestore,
      `notificationSubscriptions/${subscriptionId}`
    );

    logEvent(this.analytics, 'notification_subscription_deleted');
    return deleteDoc(docRef);
  }
}
