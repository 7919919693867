import { Component } from '@angular/core';
import { JobService } from '../../../services/job.service';
import { EMPTY, Observable } from 'rxjs';
import { Job } from '../../../types/job';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { SchoolService } from '../../../services/school.service';

@Component({
  selector: 'app-schools-jobs',
  templateUrl: './schools-jobs.component.html',
  styleUrls: ['./schools-jobs.component.scss'],
})
export class SchoolsJobsComponent {
  jobsPublic$: Observable<Job[]> = EMPTY;
  jobsNotPublic$: Observable<Job[]> = EMPTY;
  jobsArchived$: Observable<Job[]> = EMPTY;
  hasPublishedJobs = false;
  hasDraftJobs = false;

  showNavigation = true;
  showArchived = false;

  noActiveJobsDesc =
    'Sobald du deine ersten Stelleninserate veröffentlicht hast, erscheinen sie hier.';
  noDraftJobsDesc =
    'Die Entwürfe deiner Stelleninserate werden hier angezeigt.';
  noArchivedJobsDesc =
    'Hier werden deine archivierten Stelleninserate angezeigt.';

  constructor(
    route: ActivatedRoute,
    jobService: JobService,
    schoolService: SchoolService,
    breadcrumbService: BreadcrumbService
  ) {
    route.paramMap.subscribe(params => {
      const schoolRootId = params.get('id') as string;
      this.jobsPublic$ = jobService.getBySchoolRoot(schoolRootId, true, false);
      this.jobsNotPublic$ = jobService.getBySchoolRoot(
        schoolRootId,
        false,
        false
      );
      this.jobsArchived$ = jobService.getBySchoolRoot(
        schoolRootId,
        false,
        true
      );

      this.jobsPublic$.subscribe(
        j => (this.hasPublishedJobs = !!j && j.length > 0)
      );
      this.jobsNotPublic$.subscribe(
        j => (this.hasDraftJobs = !!j && j.length > 0)
      );

      schoolService.getById(schoolRootId).subscribe(school => {
        breadcrumbService.set([
          { text: 'Meine Schule', link: 'my-schools' },
          { text: `Inserate ${school.name}` },
        ]);
      });
    });
  }

  toggleArchive() {
    this.showArchived = !this.showArchived;
  }
}
