import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from '../../../../services/profile.service';
import { Profile } from '../../../../types/profile';

@Component({
  selector: 'app-profile-certifications-form',
  templateUrl: './profile-certifications-form.component.html',
  styleUrls: ['./profile-certifications-form.component.scss'],
})
export class ProfileCertificationsFormComponent implements OnInit {
  id: string;
  profile$: Observable<Profile>;

  certificationControls: FormControl<string | null>[] = [];

  constructor(
    private profileService: ProfileService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.profile$ = profileService.getMe(false);
  }

  ngOnInit(): void {
    this.profile$.subscribe(profile => {
      profile.certifications?.forEach(certification => this.add(certification));
      this.add();
    });
  }

  onChangeText(value: string | null, control: FormControl<string | null>) {
    if (value && value.length > 0) {
      const index = this.certificationControls.findIndex(c => c === control);
      if (this.certificationControls.length - 1 === index) {
        this.add();
      }
    } else if (this.certificationControls.length > 1) {
      this.remove(control);
    }
  }

  add(defaultValue = '') {
    const newControl = new FormControl<string | null>(defaultValue);
    newControl.valueChanges.subscribe(value =>
      this.onChangeText(value, newControl)
    );
    this.certificationControls.push(newControl);
  }

  remove(control: FormControl<string | null>) {
    const i = this.certificationControls.findIndex(c => c === control);
    this.certificationControls.splice(i, 1);
  }

  save() {
    const certifications = this.certificationControls
      .map(c => c.value)
      .filter(v => !!v) as string[];

    this.profileService.update({ certifications }).subscribe(_ => {
      this.router.navigate(['me', this.id]);
    });
  }
}
