<form [formGroup]="form" class="grid grid-cols-12 pt-16 min-h-screen">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Willkommen zurück!</h1>

    <div class="flex flex-col">
      <mat-form-field>
        <mat-label>E-Mail</mat-label>
        <input
          type="email"
          matInput
          formControlName="email"
          placeholder="Ex. pat@example.com" />
        @if (form.get('email')?.hasError('email')) {
          <mat-error> E-Mail Adresse ist ungültig. </mat-error>
        }
        @if (form.get('email')?.hasError('required')) {
          <mat-error> E-Mail ist erforderlich. </mat-error>
        }
      </mat-form-field>

      <mat-form-field>
        <mat-label>Passwort</mat-label>
        <input type="password" matInput formControlName="password" />
        @if (form.get('password')?.hasError('required')) {
          <mat-error> Bitte gib dein Passwort ein. </mat-error>
        }
        <mat-hint>
          <a routerLink="/password-forget">Passwort vergessen?</a>
        </mat-hint>
      </mat-form-field>

      @if (showLoginError) {
        <mat-error class="pt-8">
          Deine E-Mail Adresse oder das Passwort ist ungültig.
        </mat-error>
      }
    </div>

    <div class="flex pb-2">
      <app-action-button
        class="w-full"
        [loading]="loading"
        (onClick)="loginEmail()"
        [disabled]="!form.valid"
        text="Einloggen" />
    </div>

    <mat-divider />

    <div class="flex flex-col gap-8 items-center pt-2">
      <div class="flex flex-col lg:flex-row gap-4 items-center w-full">
        <button
          (click)="loginWithEdulog()"
          class="cursor-pointer text-white hover:bg-[#144472] bg-[#002654] rounded-full border border-solid border-black flex w-full items-center h-10 justify-center gap-4">
          <mat-icon class="scale-125" svgIcon="custom_auth_edulog"></mat-icon>
          <span class="pt-1 font-bold tracking-wide">Einloggen mit Edulog</span>
        </button>

        <button
          (click)="loginGoogle()"
          class="cursor-pointer hover:bg-[#f6f5f8] rounded-full border border-solid border-black flex w-full items-center h-10 justify-center gap-4">
          <mat-icon class="scale-125" svgIcon="custom_auth_google"></mat-icon>
          <span class="pt-1 font-bold tracking-wide">Einloggen mit Google</span>
        </button>
      </div>
    </div>
  </div>
</form>
