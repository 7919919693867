import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-secure-password-help',
  templateUrl: './secure-password-help.component.html',
  styleUrls: ['./secure-password-help.component.scss'],
})
export class SecurePasswordHelpComponent {
  @Input({ required: true }) returnPath = '';
  @Input() showTopPadding = false;
}
