<div mat-dialog-content>
  <div class="text-base text-black">
    {{ props.text }}
  </div>
</div>
<div mat-dialog-actions>
  @if (!props.onlyAcceptButton) {
    <button mat-button mat-dialog-close color="primary">
      {{ props.cancelText || 'Abbrechen' }}
    </button>
  }
  <button mat-button (click)="accept()" [color]="props.buttonColor || 'warn'">
    {{ props.confirmText || 'Ok' }}
  </button>
</div>
