@if (schools$ | async; as schools) {
  <div class="grid grid-cols-12">
    @if (page === 0) {
      <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
        <div class="flex flex-col">
          <h1 class="font-bold text-4xl">Inserat erstellen</h1>
          <span class="text-base">
            Für welchen Standort soll das Inserat erstellt werden?
          </span>
          @if (schools.length === 0) {
            <mat-error class="pt-8 text-base font-bold">
              Du hast kein öffentliches Schulprofil.
            </mat-error>
          }
        </div>
        <mat-radio-group
          color="primary"
          class="flex flex-col"
          [formControl]="schoolControl">
          @for (school of schools | sortby: '-isRoot'; track school.id) {
            <mat-radio-button [value]="school.id">
              {{ school.name }}
            </mat-radio-button>
          }
        </mat-radio-group>
        <div class="flex justify-end gap-8">
          <a mat-button color="primary" [routerLink]="['..']">Abbrechen</a>
          <app-secondary-button
            [disabled]="!schoolControl.valid"
            (onClick)="next()">
            Weiter
          </app-secondary-button>
        </div>
      </div>
    }
    @if (page === 1) {
      <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
        <div class="flex flex-col">
          <h1 class="font-bold text-4xl">Inserat erstellen</h1>
          <span class="text-base">
            Ist das Inserat für eine Stellvertretung oder eine Festanstellung?
          </span>
        </div>
        <mat-radio-group
          color="primary"
          class="flex flex-col"
          [formControl]="isFullTimeJobControl">
          <mat-radio-button [value]="true">Festanstellung</mat-radio-button>
          <mat-radio-button [value]="false">Stellvertretung</mat-radio-button>
        </mat-radio-group>
        <div class="flex justify-end gap-8">
          <button mat-button color="primary" (click)="prev()">Zurück</button>
          <app-action-button
            (onClick)="create()"
            [loading]="isLoading"
            [disabled]="isDisabled()"
            text="Speichern"></app-action-button>
        </div>
      </div>
    }
  </div>
}
