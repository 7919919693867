@if (progressPercentage < 100) {
  <div class="grid grid-cols-12 md:gap-4 w-full">
    <div
      class="lg:col-start-3 col-span-12 lg:col-span-8 border-dot rounded-[0.75rem] md:rounded-[1.35rem] p-10">
      <div class="flex flex-col">
        <div class="pb-8">
          <h2 class="font-bold text-2xl">Dein Fachkräfte-Profil</h2>
          <span class="text-sm font-very-bright-color">
            Was macht dich als Person und Fachkraft aus?
          </span>
        </div>
        <div class="flex flex-row flex-wrap justify-between gap-14">
          <div class="flex flex-row flex-wrap gap-8">
            <div class="flex flex-col text-sm gap-2">
              <strong class="text-base font-bold">Berufliches</strong>
              <app-progress-card-item
                text="Berufserfahrung"
                link="edit/experience/new"
                [success]="hasWorkExperience"></app-progress-card-item>
              <app-progress-card-item
                text="Ausbildung"
                link="edit/education/new"
                [success]="hasEducation"></app-progress-card-item>
              <app-progress-card-item
                text="Qualifikationen"
                scrollTargetId="qualifications"
                [success]="hasQualifications"></app-progress-card-item>
            </div>
            <div class="flex flex-col text-sm gap-2">
              <strong class="text-base font-bold">Persönliches</strong>
              <app-progress-card-item
                text="Werte"
                link="edit/preferences"
                [success]="hasPreferences"></app-progress-card-item>
              <app-progress-card-item
                text="Über mich"
                link="edit/about"
                [success]="hasAboutMe"></app-progress-card-item>
              <app-progress-card-item
                text="Sprachen"
                link="edit/languages"
                [success]="hasLanguages"></app-progress-card-item>
            </div>
          </div>
          <div class="grow">
            <app-progress-bar
              [progress]="progressPercentage"></app-progress-bar>
          </div>
        </div>
      </div>
    </div>
  </div>
}
