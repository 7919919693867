import { Component, OnInit } from '@angular/core';
import { NotificationSubscriptionService } from '../../services/notification-subscription.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SchoolLevelDescriptions, SchoolLevels } from '../../types/school';
import {
  JobSubscriptionData,
  NotificationSubscription,
} from '../../types/notification';
import { GeocodeResult } from '@googlemaps/google-maps-services-js';
import { JobFunctions } from '../../types/job';
import {
  Observable,
  EMPTY,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
} from 'rxjs';
import { ProfileService } from '../../services/profile.service';
import { Profile } from '../../types/profile';
import { NotificationService } from '../../services/notification.service';
import { LocationsService } from '../../services/locations.service';

type SelectedJobFunction = {
  value: string;
  jobLevelsControl: FormControl<
    null | string[] | (typeof SchoolLevels)[number][]
  >;
  jobLevelDescriptionsControl: FormControl<
    null | string[] | (typeof SchoolLevelDescriptions)[number][]
  >;
  inStudyControl: FormControl<boolean | null | undefined>;
};

@Component({
  selector: 'app-search-agent',
  templateUrl: './search-agent.component.html',
  styleUrl: './search-agent.component.scss',
})
export class SearchAgentComponent implements OnInit {
  notifcationSubscription?: NotificationSubscription<JobSubscriptionData>;
  selectedJobFunctions: SelectedJobFunction[] = [];
  jobFunctions = JobFunctions;
  schoolLevels = SchoolLevels;
  schoolLevelDescriptions = SchoolLevelDescriptions;
  selectedLocation?: GeocodeResult;
  locations$: Observable<GeocodeResult[]> = EMPTY;
  readonly possibleRadius = [20, 30, 50, 100];
  loading = false;
  profile!: Profile;

  form = this.fb.group({
    jobFunctions: ['', []],
    isEmailNotificationEnabled: [false, []],
    email: ['', [Validators.required, Validators.email]],
    location: ['', [Validators.required]],
    radius: [0, []],
    levels: [[] as (typeof SchoolLevels)[number][], [Validators.required]],
    employmentType: ['both', []],
  });

  constructor(
    private profileService: ProfileService,
    private notificationSubscriptionService: NotificationSubscriptionService,
    private locationsService: LocationsService,
    private notificationService: NotificationService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.profileService.getMe(true).subscribe(profile => {
      this.profile = profile;
      this.profileService.getContact(profile.id!).subscribe(contact => {
        this.notificationSubscriptionService
          .getJobNotificationSubscriptions()
          .subscribe(subscriptions => {
            this.notifcationSubscription = subscriptions.find(s =>
              s.targets.find(t => t.type === 'email')
            );

            if (this.notifcationSubscription) {
              this.form.controls.location.setValue(
                this.notifcationSubscription.data.location.formatted_address
              );
              this.selectedLocation =
                this.notifcationSubscription.data.location;
              this.form.controls.email.setValue(
                this.notifcationSubscription.targets.find(
                  t => t.type === 'email'
                )?.value || ''
              );
              this.form.controls.radius.setValue(
                this.notifcationSubscription.data.radius || 0
              );
              this.form.controls.levels.setValue(
                this.notifcationSubscription.data.schoolLevels || []
              );
              this.form.controls.isEmailNotificationEnabled.setValue(true);
              this.form.controls.employmentType.setValue(
                this.notifcationSubscription.data.employmentType
              );
            } else {
              this.form.controls.email.setValue(contact.email || '');
            }
            this.form.markAsUntouched();
          });
      });
    });

    this.locations$ = this.form.controls.location.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      filter(query => !!query && query.length > 2),
      switchMap(value => this.locationsService.searchAddress(value!)),
      filter(r => r.status === 'OK'),
      map(result => result.results)
    );
  }

  displayLocationFn(result: GeocodeResult | string): string {
    if (typeof result === 'string') return result;
    return result && result.formatted_address ? result.formatted_address : '';
  }

  setLocation(geocodeResult: GeocodeResult) {
    this.selectedLocation = geocodeResult;
  }

  showSchoolSubscriptionForm() {
    return !!this.form.controls.isEmailNotificationEnabled.value;
  }

  isValid() {
    const isValidActive =
      !!this.form.controls.isEmailNotificationEnabled.value &&
      this.form.valid &&
      this.form.touched &&
      !this.loading;

    const isValidInactive =
      !this.form.controls.isEmailNotificationEnabled.value &&
      !!this.notifcationSubscription?.id &&
      !this.loading;

    return isValidActive || isValidInactive;
  }

  async save() {
    this.loading = true;

    if (
      !!this.notifcationSubscription?.id &&
      !this.form.controls.isEmailNotificationEnabled.value
    ) {
      this.notificationSubscriptionService.deleteNotificationSubscription(
        this.notifcationSubscription.id
      );

      this.notificationService.success('Suchagent deaktiviert.');
      this.loading = false;
      return;
    }

    const employmentType = this.form.controls.employmentType.value as
      | 'fulltime'
      | 'substitute'
      | 'both';

    const notificationSubscription: NotificationSubscription<JobSubscriptionData> =
      {
        ...(this.notifcationSubscription || {}),
        type: 'job',
        targets: [{ type: 'email', value: this.form.controls.email.value! }],
        userId: this.profile.id!,
        userName: this.profile.name,
        data: {
          employmentType,
          location: this.selectedLocation!,
          radius: this.form.controls.radius.value || 0,
          schoolLevels: this.form.controls.levels.value || [],
        },
      };

    try {
      this.notificationSubscriptionService.upsertNotificationSubscription(
        notificationSubscription
      );
      this.notificationService.success('Suchagent erfolgreich gespeichert');
      this.form.markAsUntouched();
    } catch (e) {
      this.notificationService.success(
        'Fehler beim Speichern des Suchagenten. Versuchen Sie es später erneut.'
      );
      console.error(e);
    }

    this.loading = false;
  }
}
