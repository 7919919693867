import { Component, Input, ViewChild } from '@angular/core';
import { BitrateOptions, VgApiService } from '@videogular/ngx-videogular/core';
import { VgHlsDirective } from '@videogular/ngx-videogular/streaming';
@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent {
  @ViewChild(VgHlsDirective, { static: true }) vgHls!: VgHlsDirective;

  @Input() src?: string;
  @Input() hls?: string;
  @Input({ required: true }) type!: string;
  @Input() cover?: string | null = null;

  hlsBitrates: BitrateOptions[] = [];
  isPlaying = false;
  playedOnce = false;
  isHovering = false;
  lastHoveringTimeout?: NodeJS.Timeout;

  showOverlay() {
    return (
      (this.playedOnce && !this.isPlaying) ||
      (this.playedOnce && this.isHovering)
    );
  }

  setBitrate(option: BitrateOptions) {
    if (this.vgHls) {
      this.vgHls.setBitrate(option);
    }
  }

  onMouseEnter() {
    this.isHovering = true;
    if (this.lastHoveringTimeout) clearTimeout(this.lastHoveringTimeout);
    this.lastHoveringTimeout = setTimeout(() => {
      this.isHovering = false;
    }, 2500);
  }

  onMouseLeave() {
    this.isHovering = false;
  }

  onPlayerReady(api: VgApiService) {
    api.getDefaultMedia().subscriptions.playing.subscribe(() => {
      this.playedOnce = true;
      this.isPlaying = true;
    });

    api.getDefaultMedia().subscriptions.pause.subscribe(() => {
      this.isPlaying = false;
    });
  }
}
