import { FirebaseError } from '@angular/fire/app';

export const firebaseErrorCodes = {
  accountExistsWithDifferentCredential:
    'auth/account-exists-with-different-credential',
  emailAlreadyInUse: 'auth/email-already-in-use',
  invalidEmail: 'auth/invalid-email',
  wrongPassword: 'auth/wrong-password',
};

export function mapFirebaseErrorCodeToUserMessage(errorCode: string) {
  switch (errorCode) {
    case firebaseErrorCodes.accountExistsWithDifferentCredential:
      return '❗️Dein Account ist bereits mit einem anderen Provider verbunden.';
    case firebaseErrorCodes.emailAlreadyInUse:
      return 'Die E-Mail Adresse wird bereits verwendet.';
    case firebaseErrorCodes.invalidEmail:
      return 'Die E-Mail Adresse ist ungültig.';
    case firebaseErrorCodes.wrongPassword:
      return 'Das Passwort ist falsch.';
    default:
      return 'Es ist ein Fehler aufgetreten.';
  }
}

export function isFirebaseErrorKnown(error: unknown) {
  return (
    error instanceof FirebaseError &&
    Object.values(firebaseErrorCodes).includes(error.code)
  );
}
