import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Job, WorkdayMatrix } from '../../../../types/job';
import { JobService } from '../../../../services/job.service';
import { debounceTime } from 'rxjs';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'app-job-workload-matrix-form',
  templateUrl: './job-workload-matrix-form.component.html',
  styleUrls: ['./job-workload-matrix-form.component.scss'],
})
export class JobWorkloadMatrixFormComponent implements OnInit {
  @Input({ required: true }) job!: Job;
  @Input({ required: true }) isOwner!: boolean;
  @Output() changeWorkdayMatrix = new EventEmitter<WorkdayMatrix>();

  workdayMatrix = new FormControl<WorkdayMatrix>([], []);

  constructor(
    private jobService: JobService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    if (!this.isOwner || this.job.isClosed) {
      this.workdayMatrix.disable();
    }

    this.workdayMatrix.setValue(this.job.workdayMatrix || []);

    this.workdayMatrix.valueChanges
      .pipe(debounceTime(500))
      .subscribe(workdayMatrix => {
        if (workdayMatrix) {
          this.jobService
            .update(this.job.id!, { workdayMatrix })
            .subscribe(_ => {
              this.changeWorkdayMatrix.emit(workdayMatrix);
              this.notificationService.success('Arbeitstage aktualisiert.');
            });
        }
      });
  }
}
