import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Editor, toHTML, Toolbar } from 'ngx-editor';
import {
  ngxEditorMinimalToolbar,
  ngxEditorSanitizePastedHtmlPlugin,
} from 'src/app/config/ngx-editor.settings';

@Component({
  selector: 'app-rich-text-field',
  templateUrl: './rich-text-field.component.html',
  styleUrl: './rich-text-field.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextFieldComponent),
      multi: true,
    },
  ],
})
export class RichTextFieldComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input() placeholderText: string = '';

  editor!: Editor;
  toolbar: Toolbar = ngxEditorMinimalToolbar;

  private propagateChange: (value: any) => void = () => {};
  private propagateTouch: () => void = () => {};
  private regexForAnchorsWithoutHttp =
    /<a\s+(?:[^>]*?\s+)?href="((?!https?:\/\/)[^"]*)"/g;

  ngOnInit(): void {
    this.editor = new Editor({ history: true });
    this.editor.registerPlugin(ngxEditorSanitizePastedHtmlPlugin);

    this.editor.valueChanges.subscribe(value => {
      this.propagateChange(this.processEditorJsonToHtml(value));
      this.propagateTouch();
    });
  }

  writeValue(value: string): void {
    this.editor.setContent(value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  ngOnDestroy(): void {
    this.editor?.destroy();
  }

  /**
   * There is probably a more elegant approach to change the generation of
   * links. But this would require to overwrite the link-command from
   * ngx-editor, which is not that straight-forward.
   * So for now we have this quick and dirty solution.
   **/
  private processEditorJsonToHtml(value: Record<string, any>): string {
    const htmlString = toHTML(value);

    const modifiedHtmlString = htmlString.replace(
      this.regexForAnchorsWithoutHttp,
      (match, p1) => {
        return match.replace(p1, `https://${p1}`);
      }
    );

    return modifiedHtmlString;
  }
}
