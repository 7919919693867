import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Job, JobActivityRange } from '../../../../types/job';
import { JobService } from 'src/app/services/job.service';

@Component({
  selector: 'app-job-progress-card',
  templateUrl: './job-progress-card.component.html',
  styleUrls: ['./job-progress-card.component.scss'],
})
export class JobProgressCardComponent implements OnChanges {
  @Input({ required: true }) job!: Job;
  @Input() isOwner = false;
  @Output() publish = new EventEmitter<void>();

  hasFunction = false;
  hasSchoolLevels = false;
  hasWorkload = false;
  hasRequirements = false;
  hasApplicationStartDate = false;
  hasStartDate = false;
  hasDurationFrom = false;
  applicationEndDate = false;
  hasWorkdayMatrix = false;
  hasAbout = false;
  hasWeOffer = false;
  hasTasks = false;
  hasSkills = false;

  canPublish = false;

  constructor(private jobService: JobService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['job']) {
      this.determineProgress();
    }
  }

  determineProgress() {
    // Required
    this.hasFunction = !!this.job.function;
    this.hasSchoolLevels =
      !!this.job.schoolLevels && this.job.schoolLevels.length > 0;
    this.hasWorkload =
      this.isValidJobActivityRange(this.job.activityRangeInHours) ||
      this.isValidJobActivityRange(this.job.activityRangeInPercentage);

    this.canPublish =
      this.hasFunction && this.hasSchoolLevels && this.hasWorkload;

    // Non Required
    this.hasRequirements = !!this.job.requirements;
    this.hasDurationFrom =
      !!this.job.durationFrom || !!this.job.isDurationStartNow;
    this.applicationEndDate = !!this.job.applicationEndDate;
    this.hasApplicationStartDate = !!this.job.durationFromUnix;
    this.hasWorkdayMatrix =
      !!this.job.workdayMatrix &&
      !this.jobService.isWorkdayMatrixEmpty(this.job);
    this.hasAbout = !!this.job.about;
    this.hasWeOffer = !!this.job.weOffer;
    this.hasTasks = !!this.job.yourTasks;
    this.hasSkills = !!this.job.yourSkills;
  }

  onPublish() {
    this.publish.emit();
  }

  showPublishButton(job: Job) {
    return this.isOwner && !job.isClosed && !job.isPublic;
  }

  private isValidJobActivityRange(activityRange?: JobActivityRange) {
    return !!activityRange && !!activityRange.from && !!activityRange.to;
  }
}
