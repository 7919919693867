import { Component, Input, OnInit } from '@angular/core';
import { School } from '../../../../types/school';
import { getPublicFileUrl } from '../../../../core/helpers';

@Component({
  selector: 'app-school-contact-card',
  templateUrl: './school-contact-card.component.html',
  styleUrls: ['./school-contact-card.component.scss'],
})
export class SchoolContactCardComponent implements OnInit {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) isOwner!: boolean;

  permissionLink?: string;

  ngOnInit(): void {
    this.permissionLink = `/schools/${this.school.rootId}/edit/permissions`;
  }

  get showEmptyCard() {
    return !this.school.contacts || this.school.contacts.length <= 0;
  }

  getImageUrl(userId: string) {
    return getPublicFileUrl('profiles', userId, 'avatar');
  }

  getTitle() {
    const singular = 'Ansprechperson';
    return this.school.contacts?.length === 1 ? singular : `${singular}en`;
  }
}
