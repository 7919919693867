import { Component, Input } from '@angular/core';
import { BreadCrumb } from '../../core/types';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { EMPTY, Observable } from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  @Input() crumbs$: Observable<BreadCrumb[]> = EMPTY;
  renderElement = false;

  constructor(breadcrumbService: BreadcrumbService) {
    this.crumbs$ = breadcrumbService.breadcrumbs$;
    this.crumbs$.subscribe(crumbs => {
      this.renderElement = crumbs.length > 0;
    });
  }

  showSeperator(index: number, crumbs: BreadCrumb[]) {
    const l = crumbs.length;
    return l > 1 && (index === 0 || index < l - 1);
  }
}
