import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Application } from '../../../../types/application';
import { formatDistanceToNow } from 'date-fns';
import { getPublicFileUrl } from '../../../../core/helpers';
import { de } from 'date-fns/locale';

@Component({
  selector: 'app-application-row',
  templateUrl: './application-row.component.html',
  styleUrls: ['./application-row.component.scss'],
})
export class ApplicationRowComponent {
  @Input({ required: true }) application!: Application;
  @Input() disabled = false;
  @Input() loading = false;
  @Output() reject = new EventEmitter();

  getAvatar(application: Application) {
    return getPublicFileUrl('profiles', application.userId!, 'avatar');
  }

  getHumnizedTime(application: Application) {
    if (application.createdOn) {
      const d = Date.parse(application.createdOn!);
      const str = formatDistanceToNow(d, { addSuffix: true, locale: de });
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return '';
  }

  onReject() {
    this.reject.emit();
  }
}
