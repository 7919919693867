import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortby',
})
export class SortbyPipe implements PipeTransform {
  transform<T extends object>(array: T[], field: string): T[] {
    const asc = !field.startsWith('-');
    const f = !asc ? field.substring(1) : field;
    if (!Array.isArray(array)) {
      return [];
    }
    array.sort((a: any, b: any) => {
      if (a[f] < b[f]) {
        return asc ? -1 : 1;
      } else if (a[f] > b[f]) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    });
    return array as T[];
  }
}
