import { Component, OnDestroy, OnInit } from '@angular/core';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subscription } from 'rxjs';
import { Auth, signInWithCustomToken } from '@angular/fire/auth';
import { Router } from '@angular/router';
@Component({
  selector: 'app-edulog-callback',
  templateUrl: './edulog-callback.component.html',
  styleUrls: ['./edulog-callback.component.scss'],
})
export class EdulogCallbackComponent implements OnInit, OnDestroy {
  private edulogAuthHandlerFunc: (data: {
    accessToken: string;
  }) => Observable<{ token: string }>;
  private authSubscription?: Subscription;

  constructor(
    private oidcService: OidcSecurityService,
    private auth: Auth,
    private router: Router,
    functions: Functions
  ) {
    this.edulogAuthHandlerFunc = httpsCallableData(
      functions,
      'edulogAuthorizer',
      {}
    );
  }

  ngOnInit() {
    this.authSubscription = this.oidcService
      .checkAuth()
      .subscribe((loginResponse: LoginResponse) => {
        if (loginResponse.idToken) {
          this.edulogAuthHandlerFunc({
            accessToken: loginResponse.idToken,
          }).subscribe(async result => {
            await signInWithCustomToken(this.auth, result.token);
            if (this.authSubscription) {
              this.authSubscription.unsubscribe();
            }
            if (loginResponse.configId) {
              // remove the configId from sessionStorage, because we are using firebase auth
              sessionStorage.removeItem(loginResponse.configId);
            }
            setTimeout(() => {
              this.router.navigate(['home']);
            }, 500);
          });
        } else {
          // redirect if user accidentally navigates to this page
          this.router.navigate(['home']);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
