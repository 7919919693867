import { Component } from '@angular/core';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { FormBuilder } from '@angular/forms';
import { ProfileService } from '../../../services/profile.service';
import { Profile, ProfilePreferences } from '../../../types/profile';
import { NotificationService } from '../../../services/notification.service';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import {
  Analytics,
  setAnalyticsCollectionEnabled,
} from '@angular/fire/analytics';

@Component({
  selector: 'app-settings-privacy',
  templateUrl: './settings-privacy.component.html',
  styleUrls: ['./settings-privacy.component.scss'],
})
export class SettingsPrivacyComponent {
  profile$: Observable<Profile> = EMPTY;

  form = this.fb.group({
    publicContactInformation: [false, []],
    analyticTrackingEnabled: [false, []],
  });

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private notificationService: NotificationService,
    private router: Router,
    private analytics: Analytics,
    breadcrumbService: BreadcrumbService
  ) {
    this.profile$ = profileService.getMe(true);

    breadcrumbService.set([
      { text: 'Deine Einstellungen', link: 'settings' },
      { text: 'Privatsphäre' },
    ]);

    this.profileService.getPreferences().subscribe(settings => {
      this.form.setValue({
        publicContactInformation: settings.publicContactInformation,
        analyticTrackingEnabled: settings.analyticTrackingEnabled,
      });
    });
  }

  save() {
    const data = this.form.value as ProfilePreferences;
    this.profileService.updatePreferences(data).subscribe(_ => {
      this.notificationService.success('Privatsphäre aktualisiert');
      setAnalyticsCollectionEnabled(
        this.analytics,
        data.analyticTrackingEnabled
      );
      this.router.navigate(['/settings']);
    });
  }
}
