<div class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col gap-8">
      <h1 class="font-bold text-4xl">Wichtige Hinweise zum Namen</h1>
      <div class="flex flex-col gap-2">
        <strong class="text-base font-bold">
          Für deinen Namen gibt's zwei Regeln:
        </strong>
        <span class="text-base">
          1. Gib deinen Namen komplett ein, z. B. "Max Meier" (nicht "M.
          Meier").
        </span>
        <span class="text-base">
          2. Gib deinen echten Namen ein (nicht "Bibi Blocksberg" oder "James
          Bond").
        </span>
      </div>
    </div>

    <div class="flex justify-end gap-8">
      <app-secondary-button routerLink="/settings/change-name">
        Namen ändern
      </app-secondary-button>
    </div>
  </div>
</div>
