import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-password-forget-success-dialog',
  templateUrl: './password-forget-success-dialog.component.html',
  styleUrls: ['./password-forget-success-dialog.component.scss'],
})
export class PasswordForgetSuccessDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PasswordForgetSuccessDialogComponent>
  ) {}
}
