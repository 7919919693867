<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="font-bold text-4xl">E-Mail Adresse ändern</h1>
      <span class="text-base">
        Hier kannst du die E-Mail-Adresse ändern, mit der du dich einloggst und
        an die wir dir Benachrichtigungen senden.
      </span>
    </div>

    <div class="flex flex-col gap-8">
      <div class="flex flex-col">
        <strong class="font-bold text-base"
          >Deine aktuelle E-Mail Adresse</strong
        >
        <span class="text-base">{{ (contact$ | async)?.email }}</span>
      </div>

      <div class="flex flex-col col-span-12">
        <mat-form-field>
          <mat-label>Neue E-Mail Adresse</mat-label>
          <input type="email" matInput formControlName="email" />
          <mat-error> Bitte gib eine gültige E-Mail Adresse ein. </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Aktuelles Passwort</mat-label>
          <input type="password" matInput formControlName="currentPw" />
          <mat-hint (click)="resetPassword()" class="cursor-pointer">
            Passwort vergessen?
          </mat-hint>
        </mat-form-field>
      </div>

      @if (changeEmailError) {
        <mat-error>
          {{ changeEmailError }}
        </mat-error>
      }
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" routerLink="/settings/change-contact"
        >Zurück</a
      >
      <app-action-button
        [loading]="loading"
        [disabled]="!form.valid"
        (onClick)="change()"
        text="Ändern"></app-action-button>
    </div>
  </div>
</form>
