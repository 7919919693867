@if (!showEmptyCard) {
  <div class="grid grid-cols-12 md:gap-4">
    <div class="flex flex-col col-span-12 lg:col-span-8 lg:col-start-3">
      <div>
        <strong class="font-bold text-4xl md:text-5xl"> Werte </strong>
        @if (isOwner) {
          <app-edit-button
            class="pl-4"
            routerLink="edit/preferences"></app-edit-button>
        }
      </div>
      <div class="flex flex-col pt-4 gap-8">
        <div class="flex gap-4 flex-wrap">
          @for (preference of profile.preferences; track preference) {
            <mat-chip class="font-normal">
              <span class="pl-1 pr-1">{{ preference }}</span>
            </mat-chip>
          }
        </div>
      </div>
    </div>
  </div>
}

@if (showEmptyCard && isOwner) {
  <app-empty-card
    title="Werte"
    subtitle="Mach dich interessanter!"
    description="Zeig was dir im täglichen Miteinander besonders wichtig ist."
    link="edit/preferences"></app-empty-card>
}
