<div class="pt-10">
  <strong class="block font-bold text-2xl md:text-4xl mb-6">
    {{
      subscriptionType === 'SCHOOL_CUSTOM' ? 'Kontaktdaten' : 'Rechnungsadresse'
    }}</strong
  >

  <form [formGroup]="form">
    <div class="grid grid-cols-12 gap-x-4">
      <mat-form-field class="col-span-12">
        <mat-label>Organisation</mat-label>
        <input matInput formControlName="organization" />
        <mat-error>Gib eine Organisation an.</mat-error>
      </mat-form-field>

      <mat-form-field class="col-span-12">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
        <mat-error>Gib einen Namen ein.</mat-error>
      </mat-form-field>

      <mat-form-field class="col-span-12 md:col-span-9 2xl:col-span-8">
        <mat-label>Strasse</mat-label>
        <input matInput formControlName="street" />
        <mat-error>Gib eine Strasse ein.</mat-error>
      </mat-form-field>

      <mat-form-field class="col-span-12 md:col-span-3 2xl:col-span-4">
        <mat-label>Nummer</mat-label>
        <input matInput formControlName="streetNumber" />
        <mat-error>Gib eine Hausnummer ein.</mat-error>
      </mat-form-field>

      <mat-form-field class="col-span-12 md:col-span-4">
        <mat-label>PLZ</mat-label>
        <input matInput formControlName="zipcode" />
        <mat-error>Gib eine Postleitzahl ein.</mat-error>
      </mat-form-field>

      <mat-form-field class="col-span-12 md:col-span-8">
        <mat-label>Ort</mat-label>
        <input matInput formControlName="city" />
        <mat-error>Gib einen Ort ein.</mat-error>
      </mat-form-field>

      @if (subscriptionType !== 'SCHOOL_CUSTOM') {
        <div class="col-span-12 py-4">
          <span class="text-base text-[#B5B6C1]">
            Trage deine E-Mail Adresse ein, die du in der Schule verwendest!
            Anschließend bekommst du auf diese E-Mail Adresse eine Nachricht mit
            einem Link zur Verifizierung deiner Schule.
          </span>
        </div>
      }

      <mat-form-field class="col-span-12">
        <mat-label>E-Mail</mat-label>
        <input matInput formControlName="email" />
        <mat-error>Gib eine E-Mail Adresse ein.</mat-error>
      </mat-form-field>
    </div>

    <app-secondary-button
      class="block w-fit ml-auto mr-0 mt-12"
      (onClick)="onNext()"
      [disabled]="form.invalid"
      >Weiter</app-secondary-button
    >
  </form>
</div>
