import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { JobService } from '../../../../services/job.service';
import { Job } from '../../../../types/job';
import { getUnixTime } from 'date-fns';

@Component({
  selector: 'app-job-date-form',
  templateUrl: './job-date-form.component.html',
  styleUrls: ['./job-date-form.component.scss'],
})
export class JobDateFormComponent implements OnInit {
  id: string;
  job?: Job;
  job$: Observable<Job>;
  minDate = new Date();
  loading = false;

  form = this.fb.group({
    durationFrom: ['', []],
  });

  constructor(
    private fb: FormBuilder,
    private jobService: JobService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.job$ = jobService.getById(this.id);
  }

  ngOnInit(): void {
    this.job$.subscribe(job => {
      this.job = job;
      this.form.setValue({
        durationFrom: job.durationFrom || '',
      } as any);
    });
  }

  save() {
    this.loading = true;
    const data = this.form.value;
    const durationFromUnix = data.durationFrom
      ? getUnixTime(Date.parse(data.durationFrom))
      : getUnixTime(new Date());
    this.jobService
      .update(this.id, { ...data, durationFromUnix } as Partial<Job>)
      .subscribe(_ => {
        this.loading = false;
        this.router.navigate(['jobs', this.id]);
      });
  }
}
