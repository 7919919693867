import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  constructor(private auth: Auth) {}

  userHasEmailAuthProvider() {
    const providers = this.auth.currentUser?.providerData;

    return (
      providers?.some(provider => provider.providerId === 'password') ?? false
    );
  }
}
