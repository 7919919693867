@if (!error) {
  <div class="grid grid-cols-12">
    <div class="flex flex-col gap-16 col-span-12 md:col-span-6 md:col-start-4">
      <div class="flex flex-col">
        <h1 class="font-bold text-4xl">Dein Schulprofil wird erstellt</h1>
        <span class="text-base text-[#B5B6C1]">
          Bitte habe etwas Geduld, während wir dein Schulprofil erstellen.
        </span>
        <span class="text-base text-[#B5B6C1]">
          Du wirst automatisch weitergeleitet.
        </span>
      </div>
      <div class="flex justify-center">
        <mat-spinner color="primary"></mat-spinner>
      </div>
    </div>
  </div>
}

@if (error) {
  <div class="grid grid-cols-12">
    <div class="flex flex-col gap-16 col-span-12 md:col-span-6 md:col-start-4">
      <div class="flex flex-col">
        <h1 class="font-bold text-4xl">Fehler</h1>
        <span class="text-base text-[#B5B6C1]">
          Dein Link scheint ungültig oder abgelaufen zu sein.
        </span>
      </div>
      <div class="flex justify-end">
        <a mat-button color="primary" [routerLink]="['/home']">Zurück</a>
      </div>
    </div>
  </div>
}
