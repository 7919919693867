import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  PublicToolbarConfig,
  PublicToolbarService,
} from '../../services/public-toolbar.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-public-toolbar',
  templateUrl: './public-toolbar.component.html',
  styleUrls: ['./public-toolbar.component.scss'],
})
export class PublicToolbarComponent {
  config$: Observable<PublicToolbarConfig>;

  baseUrl = environment.homepageBaseUrl;

  constructor(toolbarService: PublicToolbarService) {
    this.config$ = toolbarService.config$;
  }
}
