import { Component } from '@angular/core';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from '../../../services/profile.service';
import { NotificationService } from '../../../services/notification.service';
import { Profile } from '../../../types/profile';
import { SupportService } from '../../../services/support.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings-change-name',
  templateUrl: './settings-change-name.component.html',
  styleUrls: ['./settings-change-name.component.scss'],
})
export class SettingsChangeNameComponent {
  profile?: Profile;
  loading = false;

  form = this.fb.group({
    name: ['', [Validators.required]],
    reason: [
      '',
      [Validators.required, Validators.minLength(3), Validators.maxLength(150)],
    ],
  });

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private notificationService: NotificationService,
    private supportService: SupportService,
    private router: Router,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.set([
      { text: 'Deine Einstellungen', link: 'settings' },
      { text: 'Nutzerkonto', link: 'settings/account' },
      { text: 'Name' },
    ]);

    this.profileService.getMe().subscribe(p => {
      this.profile = p;
      this.form.setValue({
        name: p.name || '',
        reason: '',
      });
    });
  }

  change() {
    this.loading = true;
    const p = this.profile!;
    const { name, reason } = this.form.value;
    this.profileService.getContact().subscribe(contact => {
      this.supportService
        .send({
          email: contact.email,
          title: 'Namensänderung',
          name: p.name || '',
          comment: `
        Namen von <strong>${p.name}</strong> in <strong>${name}</strong> <br/>
        <strong>Begründung</strong>: <br/><br/>
        ${reason}
        `,
        })
        .subscribe(_ => {
          this.loading = false;
          this.form.reset();
          this.notificationService.success('Anfrage gesendet.');
          this.router.navigate(['/settings/account']);
        });
    });
  }
}
