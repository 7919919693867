<div mat-dialog-content>
  <div class="flex flex-col gap-4 text-base text-black">
    <span>Wir senden dir eine E-Mail an deine E-Mail Addresse:</span>
    <div class="flex items-center justify-center rounded-2xl p-4 bg-[#F1F5FB]">
      <strong>{{ email }}</strong>
    </div>
    <span>Klicke auf den Link in der E-Mail, um dein Passwort zu ändern!</span>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close color="primary">Weiter</button>
</div>
