import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { JobService } from '../../../../services/job.service';
import { Job } from '../../../../types/job';

@Component({
  selector: 'app-job-workload-form',
  templateUrl: './job-workload-form.component.html',
  styleUrls: ['./job-workload-form.component.scss'],
})
export class JobWorkloadFormComponent implements OnInit {
  id: string;
  job?: Job;
  job$: Observable<Job>;
  loading = false;

  form = this.fb.group({
    activityRangeInPercentageFrom: ['', []],
    activityRangeInPercentageTo: ['', []],
    activityRangeInHoursFrom: ['', []],
    activityRangeInHoursTo: ['', []],
  });

  constructor(
    private fb: FormBuilder,
    private jobService: JobService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.job$ = jobService.getById(this.id);
  }

  ngOnInit(): void {
    this.job$.subscribe(job => {
      this.job = job;
      this.form.setValue({
        activityRangeInPercentageFrom:
          job.activityRangeInPercentage?.from || '',
        activityRangeInPercentageTo: job.activityRangeInPercentage?.to || '',
        activityRangeInHoursFrom: job.activityRangeInHours?.from || '',
        activityRangeInHoursTo: job.activityRangeInHours?.to || '',
      } as any);
    });
  }

  save() {
    this.loading = true;
    const data = this.form.value;

    const jobPartial: Partial<Job> = {
      activityRangeInHours: {
        from: this.determineFormFieldValueForSaving(
          data.activityRangeInHoursFrom,
          data.activityRangeInHoursTo
        ),
        to: this.determineFormFieldValueForSaving(
          data.activityRangeInHoursTo,
          data.activityRangeInHoursFrom
        ),
      },
      activityRangeInPercentage: {
        from: this.determineFormFieldValueForSaving(
          data.activityRangeInPercentageFrom,
          data.activityRangeInPercentageTo
        ),
        to: this.determineFormFieldValueForSaving(
          data.activityRangeInPercentageTo,
          data.activityRangeInPercentageFrom
        ),
      },
    };

    this.jobService.update(this.id, jobPartial).subscribe(_ => {
      this.loading = false;
      this.router.navigate(['jobs', this.id]);
    });
  }

  private determineFormFieldValueForSaving(
    primary?: string | null,
    secondary?: string | null
  ) {
    if (primary) {
      return parseInt(primary);
    }

    if (secondary) {
      return parseInt(secondary);
    }

    return undefined;
  }

  isAnyFormFieldSet() {
    const data = this.form.value;
    return (
      !!data.activityRangeInHoursFrom ||
      !!data.activityRangeInHoursTo ||
      !!data.activityRangeInPercentageFrom ||
      !!data.activityRangeInPercentageTo
    );
  }
}
