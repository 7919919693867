<form [formGroup]="form" (ngSubmit)="save()" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Über mich</h1>

    <app-rich-text-field
      placeholderText="Hier kannst du dich persönlich vorstellen und erzählen, was andere auf jeden Fall von dir wissen sollten. Du kannst auf deine Berufserfahrungen, Kenntnisse oder pädagogischen Schwerpunkte eingehen. Viele Mitglieder erwähnen auch erfolgreiche Projekte oder interessante Ämter aus früheren Stellen. Ebenso finden private Interessen hier einen Platz."
      formControlName="about"></app-rich-text-field>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/me', id]">Abbrechen</a>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="!form.valid">
        Speichern
      </button>
    </div>
  </div>
</form>
