<div class="grid grid-cols-12">
  <div
    class="col-span-12 xl:col-span-8 xl:col-start-3 2xl:col-span-6 2xl:col-start-4">
    <mat-stepper
      [@.disabled]="true"
      [scrollTop]="true"
      labelPosition="bottom"
      [linear]="true"
      #stepper>
      <ng-template matStepperIcon="edit">
        <mat-icon class="material-symbols-rounded scale-125">done</mat-icon>
      </ng-template>
      <mat-step
        [stepControl]="addressForm"
        [editable]="getCurrentStep() !== 2"
        [label]="
          subscriptionType === 'SCHOOL_CUSTOM'
            ? 'Kontaktdaten'
            : 'Rechnungsadresse'
        ">
        <app-ordering-address
          [form]="addressForm"
          [subscriptionType]="subscriptionType"
          (next)="onNext()"></app-ordering-address>
      </mat-step>
      <mat-step
        [stepControl]="overviewForm"
        [editable]="getCurrentStep() !== 2"
        [label]="
          subscriptionType === 'SCHOOL_CUSTOM'
            ? 'Angebot anfordern'
            : 'Bestellübersicht'
        ">
        <app-ordering-overview
          [form]="overviewForm"
          [subscriptionType]="subscriptionType"
          [addressData]="getAddressData()"
          (next)="onNext()"
          (prev)="onPrev()"></app-ordering-overview>
      </mat-step>
      <mat-step label="Bestätigung">
        <app-ordering-confirmation
          [subscriptionType]="subscriptionType"
          [emailAddress]="getAddressData().email"
          (closeDialog)="onCloseDialog()"></app-ordering-confirmation>
      </mat-step>
    </mat-stepper>
  </div>
</div>
