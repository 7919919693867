@if (job$ | async; as job) {
  <form [formGroup]="form" class="grid grid-cols-12">
    <div class="flex flex-col gap-12 col-span-12 md:col-span-6 md:col-start-4">
      <div class="flex flex-col">
        <h1 class="font-bold text-4xl">Bewerben bei {{ job.schoolName }}</h1>
        <span class="text-base text-[#B5B6C1]">
          Interessierst du dich für diese Stelle? Du bist nur noch wenige Klicks
          davon entfernt.
        </span>
      </div>
      <div class="flex flex-col">
        <h2 class="font-bold text-2xl">Kontaktinfo</h2>
        <mat-form-field>
          <mat-label>E-Mail</mat-label>
          <input matInput formControlName="email" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Telefonnummer</mat-label>
          <input matInput formControlName="phone" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Nachricht an die Schule</mat-label>
          <textarea matInput formControlName="message" rows="8"></textarea>
        </mat-form-field>
        <div class="pb-8 pt-8">
          <h2 class="font-bold text-2xl">Anhänge</h2>
          <div class="flex items-center">
            <mat-icon class="text-[#FFB81C] material-symbols-rounded pr-8">
              info
            </mat-icon>
            <div class="text-base text-[#B5B6C1]">
              Anhänge dürfen nicht grösser als 5 MB sein und müssen als PDF
              hochgeladen werden.
            </div>
          </div>
        </div>
        <div class="pb-4">
          <app-file-upload-field
            [formControl]="cvControl"
            title="Lebenslauf"
            [required]="true"
            type="document"></app-file-upload-field>
        </div>
        <div class="pb-4">
          @for (
            control of qualificationControls;
            track control;
            let i = $index
          ) {
            <app-file-upload-field
              (onSelect)="add('qualification')"
              (onClear)="remove(i, 'qualification')"
              [formControl]="control"
              title="Diplome und Zertifikate"
              type="document"></app-file-upload-field>
          }
        </div>
        <div>
          @for (
            control of additionalDocControls;
            track control;
            let i = $index
          ) {
            <app-file-upload-field
              (onSelect)="add('additionalDoc')"
              (onClear)="remove(i, 'additionalDoc')"
              [formControl]="control"
              title="Arbeitszeugnisse und Weiteres"
              type="document"></app-file-upload-field>
          }
        </div>
      </div>
      <div class="flex justify-end gap-8">
        <a mat-button color="primary" [routerLink]="['/jobs', id]">Abbrechen</a>
        <app-action-button
          [loading]="loading"
          (onClick)="apply()"
          [disabled]="!isApplyButtonEnabled()"
          text="Bewerben"></app-action-button>
      </div>
    </div>
  </form>
}
