<div class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="font-bold text-4xl">Profil löschen</h1>
      <span class="text-base">
        Schade, dass du Bildigsnetz nicht mehr nutzen möchtest. Du bist ein
        wichtiger Teil des Schweizer Bildungssystems und wir würden dich gerne
        behalten. Beachte bitte, dass wir deine Profildaten wirklich löschen.
        Das kann also nicht rückgängig gemacht werden.
      </span>
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" routerLink="/settings/account">Zurück</a>
      <app-secondary-button (onClick)="showDialog()">
        Weiter
      </app-secondary-button>
    </div>
  </div>
</div>

@if (loading) {
  <div
    class="flex justify-center items-center absolute bg-black/10 top-0 left-0 w-full h-full">
    <div class="flex flex-col justify-center items-center gap-8">
      <mat-spinner color="primary"></mat-spinner>
      <h1 class="font-bold text-[#2880fe]">Profil wird gelöscht...</h1>
    </div>
  </div>
}
