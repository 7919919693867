import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-box',
  templateUrl: './empty-box.component.html',
  styleUrls: ['./empty-box.component.scss'],
})
export class EmptyBoxComponent {
  @Input() title = '';
  @Input() description = '';
}
