<div class="grid grid-cols-12 md:gap-4">
  <div class="flex-col col-span-12 lg:col-span-8 lg:col-start-3 gap-4">
    <div class="flex flex-col md:flex-row col-span-12 gap-4">
      <div
        class="border border-[#eaebef] h-36 w-36 rounded-3xl bg-center bg-no-repeat bg-contain"
        [ngStyle]="{
          'background-image':
            'url(' +
            getAvatarUrl(job) +
            '), url(/assets/defaults/school_avatar.svg)'
        }"></div>
      <div class="flex flex-col">
        <app-job-item-field
          class="pt-2"
          [editable]="editable"
          [hasValue]="!!job.function"
          title="Berufsbezeichnung"
          link="edit/function">
          <span class="text-2xl md:text-4xl font-bold">{{ job.function }}</span>
        </app-job-item-field>
        <app-job-item-field
          [editable]="editable"
          [hasValue]="!!job.schoolLevels"
          title="Schulstufe"
          link="edit/schoollevels">
          <span class="text:lg md:text-2xl font-bold">
            {{ getJobLevels(job) }}
          </span>
        </app-job-item-field>
        <app-job-item-field
          class="pb-2"
          [editable]="editable"
          [hasValue]="hasSubjects(job)"
          title="Unterrichtsfächer"
          link="edit/subjects">
          <span class="text-base m-0">{{ job.subjects?.join(' | ') }}</span>
        </app-job-item-field>
      </div>
    </div>
    <div class="col-span-12 pt-8">
      <div
        class="rounded-[0.75rem] md:rounded-[1.35rem] h-full w-full bg-center bg-no-repeat bg-contain object-center object-cover"
        [ngStyle]="{
          'background-image':
            'url(' +
            getBackgroundUrl(job) +
            '), url(/assets/defaults/background_school.jpeg)'
        }">
        <img
          alt="School Background"
          src="/assets/defaults/background_school.jpeg"
          style="visibility: hidden" />
      </div>
    </div>

    <div class="col-span-12 pt-16 pb-4 md:pb-8">
      <strong class="text-2xl md:text-4xl font-bold">
        {{ job.schoolName }}
      </strong>
    </div>
    <div class="flex col-span-12 gap-x-16 gap-y-2 flex-wrap items-baseline">
      @if (!!getAddress(job)) {
        <div class="flex items-center gap-2">
          <mat-icon class="material-symbols-rounded">location_on</mat-icon>
          <span>{{ getAddress(job) }}</span>
        </div>
      }
      <div class="flex items-center gap-2">
        <mat-icon class="material-symbols-rounded">work</mat-icon>
        <span>
          {{ job.isFullTimeJob ? 'Festanstellung' : 'Stellvertretung' }}
        </span>
      </div>

      <app-job-item-field
        [editable]="editable"
        [hasValue]="
          !!job.activityRangeInPercentage || !!job.activityRangeInHours
        "
        title="Pensum"
        icon="schedule"
        link="edit/workload">
        <div class="flex items-center gap-2">
          <mat-icon class="material-symbols-rounded">schedule</mat-icon>
          <span>{{ getScheduleText(job) }}</span>
        </div>
      </app-job-item-field>

      <app-job-item-field
        [editable]="editable"
        [hasValue]="!!job.requirements"
        title="Anforderung"
        icon="history_edu"
        link="edit/requirements">
        <div class="flex items-center gap-2">
          <mat-icon class="material-symbols-rounded">history_edu</mat-icon>
          <span>Anforderung: {{ getRequirements(job) }}</span>
        </div>
      </app-job-item-field>

      @if (job.isFullTimeJob) {
        <app-job-item-field
          [editable]="editable"
          [hasValue]="!!job.durationFrom"
          title="Antrittsdatum"
          icon="timer"
          link="edit/date">
          <div class="flex items-center gap-2">
            <mat-icon class="material-symbols-rounded">timer</mat-icon>
            <span>Antritt: {{ job.durationFrom | date: 'd. MMM y' }}</span>
          </div>
        </app-job-item-field>
      }

      @if (job.isFullTimeJob) {
        <app-job-item-field
          [editable]="editable"
          [hasValue]="!!job.applicationEndDate"
          title="Bewerbungsfrist"
          icon="timer"
          link="edit/deadline">
          <div class="flex items-center gap-2">
            <mat-icon class="material-symbols-rounded">timer</mat-icon>
            <span>Frist: {{ job.applicationEndDate | date: 'd. MMM y' }}</span>
          </div>
        </app-job-item-field>
      }

      @if (!job.isFullTimeJob) {
        <app-job-item-field
          [editable]="editable"
          [hasValue]="!!job.durationTo || !!job.durationFrom"
          title="Zeitraum"
          icon="event"
          link="edit/duration">
          <div class="flex items-center gap-2">
            <mat-icon class="material-symbols-rounded">event</mat-icon>
            <span>
              {{ jobService.getFormattedSubstitutionPeriodLong(job) }}
            </span>
          </div>
        </app-job-item-field>
      }
    </div>
  </div>
</div>
